import React from 'react';
import { localAdminRoute as route } from '../constants';

const RouteComponent = React.lazy(() => import('.'));

export default {
  RouteComponent,
  path: route,
  name: 'localAdmin',
};
