import { generateUnixFromNMinutesAgo } from 'utils/dates';
import { findLatestTimestamp } from 'routes/DashboardsContainer/utils';
import get from 'lodash/get';
import merge from 'lodash/merge';

// ** findLatestShadowTimeStamp
// ** takes the shadowState of an ACU
export function findLatestShadowTimeStamp(shadowState) {
  // this is the what the ACU is reporting
  const reportedShadowState = shadowState?.state?.reported;
  const subShadowKeys = reportedShadowState?.subShadowKeys;
  let lastShadowTimestamp = 0;
  if (subShadowKeys) {
    lastShadowTimestamp = reportedShadowState.publishedTimestamp;
    subShadowKeys.forEach((key) => {
      const keySplit = key.split('_');
      const publishedTimestamp = get(reportedShadowState, [
        ...keySplit,
        'publishedTimestamp',
      ]);
      if (publishedTimestamp > lastShadowTimestamp) {
        lastShadowTimestamp = publishedTimestamp;
      }
    });
  } else {
    lastShadowTimestamp = findLatestTimestamp(shadowState?.metadata?.reported);
  }
  return lastShadowTimestamp;
}

// ** getAcuStatusData
// ** takes the shadowState of an ACU
export function getAcuStatusData(acuShadowState, acuOpal = null) {
  // console.debug('getAcuStatusData ' + Date.now())
  const reportedShadowState = acuShadowState?.state?.reported;
  const latestTimestamp = findLatestShadowTimeStamp(acuShadowState);
  const reportedNetworkStatus = reportedShadowState?.status?.network;
  let vpnIpv4 = reportedNetworkStatus?.tun0?.ipv4;
  let vpnIpv6 = reportedNetworkStatus?.tun0?.ipv6;
  let enterpriseVpnIpv4 = reportedNetworkStatus?.tun1?.ipv4;
  let enterpriseVpnIpv6 = reportedNetworkStatus?.tun1?.ipv6;

  // When using the Enterprise VPN, there is a chance it may connect before Nebula
  // If that happens, tun1 will report the tunnel IP as being 10.200, 10.201, etc.
  // Switch the tunnel information around in this case
  // 10.200+ is reserved for Nebula
  if (reportedNetworkStatus?.tun1?.ipv4.startsWith('10.2')) {
    vpnIpv4 = reportedNetworkStatus?.tun1?.ipv4;
    vpnIpv6 = reportedNetworkStatus?.tun1?.ipv6;
    enterpriseVpnIpv4 = reportedNetworkStatus?.tun0?.ipv4;
    enterpriseVpnIpv6 = reportedNetworkStatus?.tun0?.ipv6;
  }

  const vpnStatusRaw = reportedShadowState?.nebula?.vpnStatus;
  const enterpriseVpnStatusRaw = reportedShadowState?.helix?.vpnStatus;
  const vpnStatus =
    vpnStatusRaw === true || vpnStatusRaw === false
      ? vpnStatusRaw
      : vpnIpv4 || vpnIpv6;
  const enterpriseVpnStatus =
    enterpriseVpnStatusRaw === true || enterpriseVpnStatusRaw === false
      ? enterpriseVpnStatusRaw
      : enterpriseVpnIpv4 || enterpriseVpnIpv6;

  // non-vpn interface value objects
  const nonTunIfaces = reportedNetworkStatus
    ? Object.entries(reportedNetworkStatus)
        .filter((iface) => !iface[0].match(/^tun/) && iface[1])
        .map((iface) => iface[1])
    : [];

  const ips = nonTunIfaces
    // IPs starting in 169.254. are self-assigned local-link IPs and should not be shown in portal
    .filter(({ ipv4 }) => ipv4?.substr(0, 8) !== '169.254.')
    .map(({ ipv4, ipv6 }) => ipv4 || ipv6)
    ?.join(', ');

  const sixtyMinutesAgo = generateUnixFromNMinutesAgo(60);
  const twentyMinutesAgo = generateUnixFromNMinutesAgo(20);
  const twelveMinutesAgo = generateUnixFromNMinutesAgo(12);

  // DEBUGGING (justin hack)
  const test = localStorage.getItem('op-debug-acuId');
  if (test && acuOpal?.includes(test)) {
    /* eslint-disable */
    console.debug(`\n\n====== DEBUGGING ACU ID ${test} ======`);
    console.debug(
      `[:: twelveMinutesAgo] - ${JSON.stringify(
        latestTimestamp < twelveMinutesAgo,
        null,
        2,
      )}`,
    );
    console.debug(
      `[:: twentyMinutesAgo] - ${JSON.stringify(
        latestTimestamp < twentyMinutesAgo,
        null,
        2,
      )}`,
    );
    console.debug(
      `[:: sixtyMinutesAgo] - ${JSON.stringify(
        latestTimestamp < sixtyMinutesAgo,
        null,
        2,
      )}`,
    );
    console.debug(
      `[:: latestTimestamp] - ${JSON.stringify(latestTimestamp, null, 2)}`,
    );
    console.debug(
      `[:: vpnStatus] - ${vpnStatus} (raw: ${vpnStatusRaw}) (vpnIpv4: ${vpnIpv4}) (vpnIpv6 ${vpnIpv6}) `,
    );
    console.debug(
      `[:: enterpriseVpnStatus] - ${enterpriseVpnStatus} (raw: ${enterpriseVpnStatusRaw}) (enterpriseVpnIpv4: ${enterpriseVpnIpv4}) (enterpriseVpnIpv6 ${enterpriseVpnIpv6}) `,
    );
    console.debug(
      `[::reportedNetworkStatus] - ${JSON.stringify(reportedNetworkStatus)}`,
    );
    console.debug(
      `[isVpnWarning: (latestTimestamp < twelveMinutesAgo) || !(vpnStatus && reportedNetworkStatus),] -----> ${JSON.stringify(
        latestTimestamp < twelveMinutesAgo ||
          !(vpnStatus && reportedNetworkStatus),
        null,
        2,
      )}`,
    );
    console.debug(
      `[isVpnError: latestTimestamp < sixtyMinutesAgo || ((latestTimestamp < twentyMinutesAgo) && !vpnStatus),] ----> ${JSON.stringify(
        latestTimestamp < sixtyMinutesAgo ||
          (latestTimestamp < twentyMinutesAgo && !vpnStatus),
        null,
        2,
      )}`,
    );
    console.debug(
      `[isEnterpriseVpnWarning: (latestTimestamp < twelveMinutesAgo) || !(enterpriseVpnStatus && reportedNetworkStatus),] -----> ${JSON.stringify(
        latestTimestamp < twelveMinutesAgo ||
          !(enterpriseVpnStatus && reportedNetworkStatus),
        null,
        2,
      )}`,
    );
    console.debug(
      `[isEnterpriseVpnError: latestTimestamp < sixtyMinutesAgo || ((latestTimestamp < twentyMinutesAgo) && !enterpriseVpnStatus),] ----> ${JSON.stringify(
        latestTimestamp < sixtyMinutesAgo ||
          (latestTimestamp < twentyMinutesAgo && !enterpriseVpnStatus),
        null,
        2,
      )}`,
    );
    console.debug(`========================\n\n`);
    /* eslint-enable */
  }

  // note - reportedNetworkStatus can be null if we have an old version of proton running
  return {
    isVpnWarning:
      latestTimestamp < twelveMinutesAgo ||
      !(vpnStatus && reportedNetworkStatus),
    isVpnError:
      latestTimestamp < sixtyMinutesAgo ||
      (latestTimestamp < twentyMinutesAgo && !vpnStatus),
    isEnterpriseVpnWarning:
      latestTimestamp < twelveMinutesAgo ||
      !(enterpriseVpnStatus && reportedNetworkStatus),
    isEnterpriseVpnError:
      latestTimestamp < sixtyMinutesAgo ||
      (latestTimestamp < twentyMinutesAgo && !enterpriseVpnStatus),
    lastShadowTimestamp: latestTimestamp,
    vpnStatus,
    enterpriseVpnStatus,
    reportedNetworkStatus,
    ips,
  };
}

export function determineAcuInventory(reportedShadowState, globalOptions) {
  // Derive HW/SW version and MAC
  let acuSoftwareVersion = null;
  let acuFirmwareVersion = null;
  let macs = null;
  let remoteDiagnosticsSupported = false;

  const inventory = reportedShadowState?.inventory;

  if (inventory) {
    if (
      Array.isArray(inventory?.os?.debs) &&
      inventory.os.debs.find((deb) => deb.name === 'op-proton')
    ) {
      acuSoftwareVersion = inventory.os.debs.find(
        (deb) => deb.name === 'op-proton',
      ).version;
    } else if (
      Array.isArray(inventory?.os?.pips) &&
      inventory.os.pips.find((pip) => pip.name === 'proton')
    ) {
      acuSoftwareVersion = inventory.os.pips.find(
        (pip) => pip.name === 'proton',
      ).version;
    }

    const acuSoftwareVersionParts = acuSoftwareVersion
      ? acuSoftwareVersion.split('.').map((x) => Number(x))
      : [0, 0, 0];

    remoteDiagnosticsSupported =
      (acuSoftwareVersionParts[0] >= 4 && acuSoftwareVersionParts[1] >= 3) ||
      acuSoftwareVersionParts[0] >= 5;

    if (remoteDiagnosticsSupported) {
      // eslint-disable-next-line no-param-reassign
      globalOptions.totalRemoteDiagnosticsSupported += 1;
    }
    macs = Object.entries((inventory.hardware && inventory.hardware.mac) || {})
      .filter((iface) => !iface[0].match(/^tun/) && iface[1])
      .map((iface) => iface[1])
      .join(', ');

    acuFirmwareVersion = inventory?.hardware?.hat?.firmwareVersion;
  }

  return {
    acuSoftwareVersion,
    acuFirmwareVersion,
    macs,
    remoteDiagnosticsSupported,
  };
}

export const createAcuShadowStateEntries = (acuShadowState = {}) => {
  return Object.values(acuShadowState).reduce((acc, opalValue) => {
    return {
      ...acc,
      ...opalValue?.state?.reported?.entries,
    };
  }, {});
};

export const createAcuShadowStateAreas = (acuShadowState = {}) =>
  Object.values(acuShadowState).reduce((acc, acu) => {
    let newAreas = {};
    const zones = acu?.state?.reported?.zones || {};
    const zonesArray = Object.entries(zones);
    if (zonesArray.length) {
      newAreas = zonesArray.reduce(
        // publishedTimestamp and mergedFromSubShadow has been added to the zones object,
        // so we must now make sure we are only using an actual zone vs one of those. To do
        // this we check to make sure the key is a number and this then means it is a zoneId
        // and that the value is the zone
        (acc2, [key, value]) => {
          return isNaN(Number(key))
            ? acc2
            : merge(
                acc2,
                Object.entries(value.areas).reduce(
                  (acc3, [areaId, { occupancyCount }]) =>
                    merge(acc3, { [areaId]: occupancyCount }),
                  {},
                ),
              );
        },
        {},
      );
    }

    return merge(acc, newAreas);
  }, {});

// IP addresses are created from the non-vpn interfaces (we don't use tun0)
export const createAcuIpAddress = (acuShadowState, opal) => {
  let latestIpAddress = '';
  let latestTimestamp = 0;
  const reportedNetworkStatus =
    acuShadowState?.[opal]?.state?.reported?.status?.network || {};
  Object.entries(reportedNetworkStatus).forEach(
    ([interfaceType, interfaceValues]) => {
      // In some cases sit0 is reported as null so we must prevent an error
      // caused by trying to do 'get' on the interfaceValues which is null
      if (interfaceValues) {
        const timestamp =
          acuShadowState?.[opal]?.metadata?.reported?.status?.network[
            interfaceType
          ]?.ipv4?.timestamp;
        const ipv4 = interfaceValues?.ipv4;
        const ipv6 = interfaceValues?.ipv6;
        if (
          !interfaceType.startsWith('tun') && // We don't use tun0
          timestamp >= latestTimestamp && // Only check if same time or newer
          ipv4.substr(0, 8) !== '169.254.' // ipv4 starting in 169.254. are self-assigned local-link IPs and should not be shown in portal
        ) {
          latestIpAddress = ipv4 || ipv6;
          latestTimestamp = timestamp;
        }
      }
    },
  );

  return latestIpAddress;
};

export const createAcuMacAddress = (acuShadowState, opal) => {
  let latestMacAddress = '';
  let latestTimestamp = 0;
  const macs =
    acuShadowState?.[opal]?.state?.reported?.inventory?.hardware?.mac || {};
  Object.entries(macs).forEach(([interfaceType, address]) => {
    const timestamp =
      acuShadowState?.[opal]?.metadata?.reported?.inventory?.hardware?.mac?.[
        interfaceType
      ]?.timestamp;

    if (!interfaceType.startsWith('tun') && timestamp > latestTimestamp) {
      latestMacAddress = address;
      latestTimestamp = timestamp;
    }
  });

  return latestMacAddress;
};

export const createShadowStateEntryUnlockAttempts = (acuShadowState = {}) => {
  return Object.values(acuShadowState).reduce((acc, shadow) => {
    // if this mqtt message doesn't have entries, we don't care.
    if (!shadow?.state?.reported?.entries) return acc;

    // if it does, we're gonna parse the data into an easier to use format
    Object.entries(shadow?.state?.reported?.entries).forEach(
      ([entryId, entryData]) => {
        if (!entryData?.last_unlock_attempt) return;
        const {
          user_opal = '',
          request_status_detail = '',
          request_id = null,
          timestamp = null,
        } = entryData?.last_unlock_attempt || {};

        if (!user_opal || !request_status_detail) {
          return acc;
        }
        const [, , , , orgId, , userId] = user_opal.split(':');
        const [success, message] = request_status_detail.split('__');

        acc.push({
          type: 'ENTRY_UNLOCK_ATTEMPT',
          timestamp,
          data: {
            requestId: request_id,
            timestamp,
            entryId,
            orgId,
            user: {
              id: userId,
              opal: user_opal,
            },
            status: {
              success: success === 'SUCCESS',
              message: message || null,
            },
          },
        });
      },
    );

    // return the new list
    return acc;
  }, []);
};
