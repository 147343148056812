import { take, call, all, takeEvery, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';

import { request } from 'utils/request';

import { selectCurrentIdentityLanguage } from 'routes/AppContainer/selectors';
import { REQUEST_VERIFY_EMAIL } from './constants';

export function* verifyEmail(action) {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const resource = `/tokens/verifyEmailTokens/${action.token}/use`;
  const options = {
    method: 'POST',
    headers: { 'Accept-Language': currentIdentityLanguage },
  };
  const response = yield call(request, resource, options);
  if (response.err) {
    if (action.cb) {
      action.cb(response.err.message);
    }
  }
}

function* rootSaga() {
  yield all([takeEvery(REQUEST_VERIFY_EMAIL, verifyEmail)]);
  yield take(LOCATION_CHANGE);
}

export default rootSaga;
