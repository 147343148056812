/*
 *
 * Login constants
 *
 */

export const LOAD_NAVIGATION = 'app/Navigation/LOAD_NAVIGATION';
export const SET_NAVIGATION = 'app/Navigation/SET_NAVIGATION';
export const ACTIVATE_BY_PATH = 'app/Navigation/ACTIVATE_BY_PATH';
export const OPEN_BY_INDEX = 'app/Navigation/OPEN_BY_INDEX';
export const CLOSE_BY_INDEX = 'app/Navigation/CLOSE_BY_INDEX';
export const ACTIVATE_BY_INDEX = 'app/Navigation/ACTIVATE_BY_INDEX';
export const ACTIVATE = 'app/Navigation/ACTIVATE';
export const SET_HIDDEN = 'app/Navigation/SET_HIDDEN';

export const UPDATE_SCOPES = 'app/Navigation/UPDATE_SCOPES';
