import { combineReducers } from 'redux-immutable';
import { createNamedWrapperReducer } from 'utils/reducers';
import formReducer from 'global/formWrapper/reducer';

import { PAGE } from './constants';

const reducers = {
  form: createNamedWrapperReducer(formReducer, PAGE),
};

export default combineReducers(reducers);
