import { ComponentProps } from 'react';
import { ExternalLinkSvg } from 'components/svgs/ExternalLinkSvg/ExternalLinkSvg';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { OpTextButton } from '../OpTextButton/OpTextButton';

interface OpExternalLinkProps
  extends Omit<ComponentProps<typeof OpTextButton>, 'title'> {
  url: string;
}

export const OpExternalLink = ({
  className,
  children,
  url,
  onClick,
  ...buttonProps
}: OpExternalLinkProps) => {
  const { t } = useTranslation();

  return (
    <OpTextButton
      className={clsx('op-external-link', className)}
      {...buttonProps}
      title={t('(Opens in new tab)')}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }

        window.open(url, '_blank');
      }}
    >
      {children}
      <ExternalLinkSvg />
    </OpTextButton>
  );
};
