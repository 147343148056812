import { REQUEST_ORG_FEATURES, SET_ORG_FEATURES } from './constants';

export function requestOrgFeatures() {
  return {
    type: REQUEST_ORG_FEATURES,
  };
}

export function setOrgFeatures(orgFeatures) {
  return {
    type: SET_ORG_FEATURES,
    orgFeatures,
  };
}
