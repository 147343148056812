import { useTranslation } from 'react-i18next';
import { LanguageSelector } from 'new-components/LanguageSelector/LanguageSelector';
import { useSelectorJs } from 'utils/customHooks';
import { selectIdentityId } from 'global/accessToken/selectors';

import './LanguageSelectorNotAuthenticated.scss';

export const LanguageSelectorNotAuthenticated = () => {
  const { i18n } = useTranslation();

  const identityId = useSelectorJs(selectIdentityId);

  if (identityId) {
    return null;
  }

  const handleLanguageChange = (language: string | string[]) => {
    i18n.changeLanguage(language as string);
  };

  return (
    <div className="language-selector-not-authenticated">
      <LanguageSelector value={i18n.language} onChange={handleLanguageChange} />
    </div>
  );
};
