import { LOCATION_CHANGE, push } from 'connected-react-router';
import { take, select, call, put, all, takeEvery } from 'redux-saga/effects';
import { setAlert } from 'routes/AppContainer/actions';
import { changeRoute } from 'routes/AuthenticatedContainer/actions';
import { setGroups, setOrgContainerReducer } from 'routes/OrgContainer/actions';
import { integrationsRoute, videoProvidersRoute } from 'routes/constants';

import { requestAndSet, sendToHelium } from 'utils/helpers';

import { SET_VIDEO_PROVIDERS } from 'routes/OrgContainer/constants';
import { selectCurrentOrgId } from 'global/accessToken/selectors';

import { requestGetInitialData } from 'routes/IdentityProvidersPage/actions';

import { request } from 'utils/request';

import { t } from 'i18next';
import { selectCurrentIdentityLanguage } from 'routes/AppContainer/selectors';
import { identityProviderTypeCodes } from 'constants/identityProvider';
import { getWindowLocation } from 'utils/window';
import { selectFeatureFlag } from 'global/openpathconfig/selectors';
import {
  REQUEST_GET_INITIAL_DATA,
  OAUTH_REQUEST_CALLBACK,
  REQUEST_GET_IDENTITY_PROVIDERS,
  REQUEST_SYNC_IDENTITY_PROVIDER,
  REQUEST_UPSERT_IDENTITY_PROVIDER,
  REQUEST_GET_GROUPS,
  REQUEST_GET_GROUP_RELATIONS,
  PAGE,
  INACTIVATE_IDP,
  REQUEST_CREATE_VIDEO_PROVIDER,
  REQUEST_UPDATE_VIDEO_PROVIDER,
  REQUEST_IDP_REAUTH,
} from './constants';
import {
  setIdentityProviders,
  setIdentityProviderTypes,
  setGroupRelations,
} from './actions';

// SUPER SAGA to collect initial data for Integrations
function* fetchGetInitialData() {
  yield all([
    call(fetchGetIdentityProviderTypes),
    call(fetchGetIdentityProviders),
    call(requestAndSetVideoProviders),
  ]);
}

function* fetchGetIdentityProviders() {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const orgId = yield select(selectCurrentOrgId());

  // Get the identity providers that are setup
  const setupResource = `/orgs/${orgId}/identityProviders`;
  const setupOption = {
    method: 'get',
    headers: { 'Accept-Language': currentIdentityLanguage },
  };
  const setupResponse = yield call(request, setupResource, setupOption);
  if (setupResponse.err) {
    if (
      setupResponse.err.message ===
      t(`Organization does not have access to this feature.`)
    ) {
      // Until we get route-level gating in Platinum this will have to do
      yield put(setIdentityProviders([]));
      return [];
    }

    yield put(setAlert('error', setupResponse.err.message));
    return null;
  }
  yield put(setIdentityProviders(setupResponse.data.data));
  return setupResponse.data.data;
}

function* fetchGetIdentityProviderTypes() {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const orgId = yield select(selectCurrentOrgId());
  const listResource = `/orgs/${orgId}/identityProviderTypes`;
  const listOptions = {
    method: 'get',
    headers: { 'Accept-Language': currentIdentityLanguage },
  };
  const listResponse = yield call(request, listResource, listOptions);
  if (listResponse.err) {
    yield put(setAlert('error', listResponse.err.message));
    return null;
  }

  const ipts = listResponse.data.data;
  yield put(setIdentityProviderTypes(ipts));
  return ipts;
}

function* fetchGetGroups() {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  // I fear the new Admin stuff will require orgId
  // to be in the action
  const orgId = yield select(selectCurrentOrgId());
  const resource = `/orgs/${orgId}/groups`;
  const options = {
    method: 'GET',
    headers: { 'Accept-Language': currentIdentityLanguage },
  };
  const response = yield call(request, resource, options);
  if (response.err) {
    yield put(
      setAlert('error', response.err.localizedMessage || response.err.message),
    );
  } else {
    yield put(setGroups(PAGE, response.data.data));
  }
}

function* fetchGetGroupRelations(action) {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const orgId = yield select(selectCurrentOrgId());
  const resource = `/orgs/${orgId}/identityProviders/${action.idpId}/groupRelations`;
  const options = {
    method: 'GET',
    headers: { 'Accept-Language': currentIdentityLanguage },
  };
  const response = yield call(request, resource, options);
  if (response.err) {
    yield put(
      setAlert('error', response.err.localizedMessage || response.err.message),
    );
    return false;
  }
  yield put(setGroupRelations(action.code, response.data.data));
  return true;
}

function* fetchPostSyncIdentityProvider(action) {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const orgId = yield select(selectCurrentOrgId());
  const resource = `/orgs/${orgId}/identityProviders/${action.id}/syncUsers`;
  const options = {
    method: 'POST',
    headers: { 'Accept-Language': currentIdentityLanguage },
  };
  const response = yield call(request, resource, options);
  if (response.err) {
    yield call(fetchGetIdentityProviders, action);
    yield put(
      setAlert('error', response.err.localizedMessage || response.err.message),
    );
    return false;
  }
  yield call(fetchGetIdentityProviders, action);
  yield put(
    setAlert(
      'success',
      t(
        `Your request has been added to the queue and users should begin syncing shortly.`,
      ),
    ),
  );
  return true;
}

// three steps:
// 1) create or update an identityProvider object of the appropriate type
// 2) update the group relations for that identityProvider
// 3) initial oauth connect for that identityProvider, if needed

// TODO possibly refactor this into separate actions/sagas that are
// tailored to the specific needs of each caller (each caller only
// uses some of the steps) - Justin
export function* fetchUpsertIdentityProvider({ idpt, idpPageItems }) {
  const orgId = yield select(selectCurrentOrgId());
  let identityProvider = idpt.identityProvider;

  if (!identityProvider?.id) {
    const {
      data: identityProviders,
      errorMessage: listIdentityProvidersErrorMessage,
    } = yield call(requestAndSet, 'listIdentityProviders', [orgId], {
      queryStringParams: { filter: `identityProviderType.code:=${idpt.code}` },
    });

    if (listIdentityProvidersErrorMessage) {
      return;
    }

    const retreivedIdp = identityProviders?.[0];

    // If an idp was found that matches the idpt code, then we set it. If not, we must create the idp
    if (retreivedIdp) {
      identityProvider = retreivedIdp;
    } else if (idpt.id) {
      // We will only have the idpt.id in the case a card is clicked
      // Create the idp
      const {
        data: createdIdentityProvider,
        errorMessage: createIdentityProviderErrorMessage,
      } = yield call(sendToHelium, 'createIdentityProvider', [orgId], {
        identityProviderTypeId: idpt.id,
        isSyncUsersEnabled: false,
        isMobileCredentialEnabled: true,
        isCloudKeyCredentialEnabled: false,
        isSsoEnabled: false,
        isMobileSsoEnabled: false,
        // don't set isIdpInitiatedSsoEnabled here, because we want it to get the appropriate default for the chosen IDP type
        isRemoveGroupsEnabled: true,
        isLimitByGroupEnabled: true,
        isSyncPersonIdEnabled: false,
        isSyncDepartmentEnabled: false,
        isSyncTitleEnabled: false,
        isSyncMobilePhoneEnabled: false,
      });

      if (createIdentityProviderErrorMessage) {
        return;
      }

      identityProvider = createdIdentityProvider;
    }
  }

  const { id: idpId } = identityProvider;

  // If we don't have the idpId by here there has been an error and no need to go on
  if (!idpId) {
    return;
  }

  if (idpPageItems) {
    const {
      siteCode,
      options: {
        // Default payload items
        isSyncUsersEnabled,
        isMobileCredentialEnabled,
        isCloudKeyCredentialEnabled,
        isSsoEnabled,
        isMobileSsoEnabled,
        isIdpInitiatedSsoEnabled,
        isRemoveGroupsEnabled,
        isLimitByGroupEnabled,
        isSyncPersonIdEnabled,
        isSyncDepartmentEnabled,
        isSyncTitleEnabled,
        isSyncMobilePhoneEnabled,

        apiKeyKey,
        apiKeyUrl,
        samlConfigSsoUrl,
        samlConfigIssuer,
        samlConfigCertificate,
        oidcClientId,
        oidcSubdomain,
        clientAuthConfigClientId,
        clientAuthConfigClientSecret,
        clientAuthConfigClientUrl,
        clientAuthConfigTenantId,
        authStrategyTypeId,
        basicAuthUrl,
        basicAuthUsername,
        basicAuthPassword,
        basicAuthGroupsUrl,
        identityProviderSyncTypeId,
        scimAttributeMapping,
      },
      groupMappings,
      skipRedirect,
    } = idpPageItems;

    const payload = {
      isSyncUsersEnabled,
      isMobileCredentialEnabled,
      isCloudKeyCredentialEnabled,
      isSsoEnabled,
      isMobileSsoEnabled,
      isIdpInitiatedSsoEnabled,
      isRemoveGroupsEnabled,
      isLimitByGroupEnabled,
      isSyncPersonIdEnabled,
      isSyncDepartmentEnabled,
      isSyncTitleEnabled,
      isSyncMobilePhoneEnabled,
      authStrategyTypeId,
      identityProviderSyncTypeId,
    };

    const isOktaScimEnabled = yield select(
      selectFeatureFlag('IS_OKTA_SCIM_ENABLED'),
    );
    const isScimSync = identityProviderSyncTypeId === 2 && isOktaScimEnabled;

    if (isScimSync) {
      payload.authStrategyTypeId = null;
      payload.scimAttributeMapping = scimAttributeMapping;
    }

    if (apiKeyKey && !apiKeyKey.match(/^\*+$/)) {
      // when we fetch data to load the form, apiKeyKey will come back
      // as '****' if the actual value is set (to mask the actual
      // value); don't send that same value back in the API
      if (!payload.apiKey) payload.apiKey = {};
      payload.apiKey.key = apiKeyKey;
    }

    if (apiKeyUrl) {
      // when we fetch data to load the form, apiKeyKey will come back
      // as '****' if the actual value is set (to mask the actual
      // value); don't send that same value back in the API
      if (!payload.apiKey) payload.apiKey = {};
      payload.apiKey.url = apiKeyUrl;
    }

    if (samlConfigSsoUrl || samlConfigIssuer || samlConfigCertificate) {
      payload.samlConfig = {
        ssoUrl: samlConfigSsoUrl || null,
        issuer: samlConfigIssuer || null,
        certificate: samlConfigCertificate || null,
      };
    } else {
      payload.samlConfig = null;
    }

    if (oidcSubdomain || oidcClientId) {
      payload.oidcConfig = {
        clientId: oidcClientId || null,
        subdomain: oidcSubdomain || null,
      };
    } else {
      payload.oidcConfig = null;
    }

    if (basicAuthUrl || basicAuthUsername || basicAuthPassword) {
      payload.basicAuth = {
        url: basicAuthUrl || null,
        username: basicAuthUsername || null,
        password: basicAuthPassword || null,
        groupsUrl: basicAuthGroupsUrl || null,
      };
    }

    if (
      clientAuthConfigClientId ||
      clientAuthConfigClientSecret ||
      clientAuthConfigTenantId ||
      clientAuthConfigClientUrl
    ) {
      payload.clientAuth = {
        clientId: clientAuthConfigClientId || null,
        clientSecret: clientAuthConfigClientSecret || null,
        ...(clientAuthConfigClientUrl
          ? { url: clientAuthConfigClientUrl }
          : {}),
      };

      /**
       * Right now this is only supported by 1 idp and is
       * sort of an outlier for other client auths
       * Specifically Service Principal based auth
       * for azure ad requires this third field
       */
      if (clientAuthConfigTenantId) {
        payload.clientAuth.tenantId = clientAuthConfigTenantId;
      }
    }

    // Safety measure to ensure we don't send auth values for SCIM. Could occur during transition to SCIM from Directory
    if (isScimSync) {
      payload.clientAuth = null;
      payload.basicAuth = null;
      payload.oidcConfig = null;
    }

    // we're patching an existing record
    const { errorMessage: updateIdentityProviderErrorMessage } = yield call(
      sendToHelium,
      'updateIdentityProvider',
      [orgId, idpId],
      payload,
    );

    if (updateIdentityProviderErrorMessage) {
      return;
    }

    if (groupMappings) {
      const { errorMessage: setIdentityProviderGroupRelationsErrorMessage } =
        yield call(
          sendToHelium,
          'setIdentityProviderGroupRelations',
          [orgId, idpId],
          groupMappings,
          {
            successMessage: t(`Identity Provider has been updated!`),
          },
        );

      if (setIdentityProviderGroupRelationsErrorMessage) {
        return;
      }

      if (!skipRedirect) {
        yield put(changeRoute(integrationsRoute));
      } else {
        // @HACK while this saga lives in IntegrationPage, we have to also refresh data for the IDP
        yield put(requestGetInitialData(siteCode));
        yield call(fetchGetInitialData);
      }
    }
  }

  // Figure out which authStrategyType we're dealing with - either
  // one has already been chosen and is set in the identityProvider
  // object, or else we pick one of the authStrategyTypes that are
  // supported by the identityProviderType.

  // NOTE: for now, all of our identity providers each only support
  // a single authStrategyType, so we just take the first (and only)
  // item from the list; in the future however, we might have
  // providers that support multiple strategies, in which case we'll
  // want to present the user with some way to choose which strategy
  // they'd like to use
  const authStrategyType = identityProvider?.authStrategyType;
  const identityProviderType = identityProvider?.identityProviderType;

  // for apikey-based auth, we don't need to go through an external
  // IDP auth redirect cycle, and can instead just jump right to the
  // settings page:
  const authStrategy = authStrategyType?.code;
  const identityProviderTypeCode = identityProviderType?.code;

  const skipAuth =
    !authStrategy ||
    (identityProvider?.status === 'A' && authStrategy === 'oauth2') ||
    ['apikey', 'clientAuth', 'basicAuth'].includes(authStrategy) ||
    /**
     * Azure AD now supports oauth2 and clientAuth. The first time azure
     * is configured it defaults to oauth2 as the authStrategy but we dont
     * want to be immediately redirected to microsoft's login flow
     */
    identityProviderTypeCode === identityProviderTypeCodes.msazuread;

  const skipToOptionsRoute = skipAuth
    ? `integrations/identityProviders/${idpt.code}`
    : null;

  if (!skipAuth) {
    const { data } = yield call(sendToHelium, 'getIdentityProviderAuthUrl', [
      orgId,
      idpId,
    ]);

    if (data?.authorizationUrl) {
      getWindowLocation().href = data.authorizationUrl;
    }
  }

  if (skipToOptionsRoute) {
    yield put(changeRoute(skipToOptionsRoute));
  }

  return identityProvider; // return so this can be used when direct-linking to IDP config page
}

function* requestIdpReauth({ orgId, idpId }) {
  const { data } = yield call(sendToHelium, 'getIdentityProviderAuthUrl', [
    orgId,
    idpId,
  ]);

  if (data?.authorizationUrl) {
    getWindowLocation().href = data.authorizationUrl;
  }
}

function* fetchOauthCallback(action) {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const resource = `/${action.siteCode}/oauth2/callback`;
  const options = {
    method: 'POST',
    body: JSON.stringify({
      state: action.authState,
      code: action.authCode,
    }),
    headers: {
      'Accept-Language': currentIdentityLanguage,
    },
  };
  const response = yield call(request, resource, options);
  if (response.err) {
    yield put(
      setAlert('error', response.err.localizedMessage || response.err.message),
    );
    yield put(push('/'));
  } else if (!response.data.data.orgId) {
    yield put(
      setAlert(
        'error',
        t(`Missing OrgId in response! Something went wrong...`),
      ),
    );
    yield put(push('/'));
  } else {
    // NOTE we definitely don't want to selectCurrentOrgId() here,
    // because the logged-in identity may have access to multiple
    // orgs, and upon return from an oauth2 callback we would default
    // to the first org in the list, whereas we actually want to
    // choose the orgId of the identityProvider that the oauth request
    // was associated with
    const orgId = response.data.data.orgId;
    const windowLocation = getWindowLocation();
    windowLocation.href = `${windowLocation.protocol}//${windowLocation.host}/o/${orgId}/integrations/identityProviders/${action.siteCode}`;
  }
}

function* requestInactivateIdp({ identityProviderId }) {
  const orgId = yield select(selectCurrentOrgId());

  yield call(
    sendToHelium,
    'inactivateIdentityProvider',
    [orgId, identityProviderId],
    null,
    {
      successMessage: t('Successfully inactivated Identity Provider.'),
    },
  );

  yield call(fetchGetInitialData, { preventLoader: true });
}

function* requestAndSetVideoProviders() {
  const orgId = yield select(selectCurrentOrgId());

  yield call(requestAndSet, 'listVideoProviders', [orgId], {
    createSetterAction: ({ data }) =>
      setOrgContainerReducer(SET_VIDEO_PROVIDERS, PAGE, data),
  });
}

function* requestCreateOrgVideoProvider({
  videoProviderTypeId,
  apiKey,
  authState,
  name,
  supportsGroups,
}) {
  const orgId = yield select(selectCurrentOrgId());

  const { data } = yield call(sendToHelium, 'createVideoProvider', [orgId], {
    videoProviderTypeId,
    apiKey,
    authState,
    name,
    supportsGroups,
  });

  if (data) {
    yield put(
      changeRoute(
        `${videoProvidersRoute.replace(/:videoProviderId/, data.id)}`,
      ),
    );
  }
}

function* requestUpdateOrgVideoProvider({
  videoProviderId,
  apiKey,
  authState,
  name,
  supportsGroups,
}) {
  const orgId = yield select(selectCurrentOrgId());

  const { data } = yield call(
    sendToHelium,
    'updateVideoProvider',
    [orgId, videoProviderId],
    {
      apiKey,
      authState,
      name,
      supportsGroups,
    },
  );

  if (data) {
    yield put(changeRoute('integrations'));
  }
}

function* rootSaga() {
  yield all([
    takeEvery(REQUEST_GET_INITIAL_DATA, fetchGetInitialData),
    takeEvery(REQUEST_GET_GROUP_RELATIONS, fetchGetGroupRelations),
    takeEvery(REQUEST_GET_GROUPS, fetchGetGroups),
    takeEvery(OAUTH_REQUEST_CALLBACK, fetchOauthCallback),
    takeEvery(REQUEST_UPSERT_IDENTITY_PROVIDER, fetchUpsertIdentityProvider),
    takeEvery(REQUEST_SYNC_IDENTITY_PROVIDER, fetchPostSyncIdentityProvider),
    takeEvery(REQUEST_GET_IDENTITY_PROVIDERS, fetchGetIdentityProviders),
    takeEvery(INACTIVATE_IDP, requestInactivateIdp),
    takeEvery(REQUEST_CREATE_VIDEO_PROVIDER, requestCreateOrgVideoProvider),
    takeEvery(REQUEST_UPDATE_VIDEO_PROVIDER, requestUpdateOrgVideoProvider),
    takeEvery(REQUEST_IDP_REAUTH, requestIdpReauth),
  ]);
  yield take(LOCATION_CHANGE);
}

export default rootSaga;
