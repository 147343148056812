import React from 'react';
import { SvgWrapper, SvgWrapperProps } from 'components/svgs/SvgWrapper';

interface ExternalLinkSvgProps extends Omit<SvgWrapperProps, 'children'> {}

export const ExternalLinkSvg = ({ ...svgProps }: ExternalLinkSvgProps) => (
  <SvgWrapper {...svgProps}>
    <path d="M8 3H3.5C2.11929 3 1 4.11929 1 5.5V14.5C1 15.8807 2.11929 17 3.5 17H12.5C13.8807 17 15 15.8807 15 14.5V10H14V14.5C14 15.3284 13.3284 16 12.5 16H3.5C2.67157 16 2 15.3284 2 14.5V5.5C2 4.67157 2.67157 4 3.5 4H8V3Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5277 1H11.251C10.9903 1 10.7787 1.2116 10.7787 1.47233C10.7787 1.73306 10.9903 1.94466 11.251 1.94466H15.3875L7.13887 10.1933C6.95371 10.3784 6.95371 10.6769 7.13887 10.8611C7.2305 10.9537 7.35141 11 7.47233 11C7.59325 11 7.71417 10.9537 7.80674 10.8611L16.0553 2.61254V6.74902C16.0553 7.00974 16.2669 7.22135 16.5277 7.22135C16.7884 7.22135 17 7.00974 17 6.74902V1.47233C17 1.40998 16.9877 1.34953 16.9641 1.2919C16.9159 1.17571 16.8243 1.08407 16.7081 1.0359C16.6505 1.01323 16.5891 1 16.5277 1Z"
    />
  </SvgWrapper>
);
