import { all, takeEvery, select, call, put } from 'redux-saga/effects';
import { selectCurrentOrgId } from 'global/accessToken/selectors';
import { getAllPackagePlans } from 'routes/OrgContainer/sagas';
import { REQUEST_ORG_PACKAGE_PLANS } from './constants';
import { setOrgPackagePlans } from './actions';

export function* requestOrgPackagePlans() {
  const orgId = yield select(selectCurrentOrgId());

  if (orgId) {
    // Don't make call in mastermode
    const packagePlans = yield call(getAllPackagePlans, {
      orgId,
    });
    if (packagePlans) {
      // If the user has very limited permissions
      // Getting package plans may fail
      yield put(setOrgPackagePlans(packagePlans));
    }
  }
}

function* rootSaga() {
  yield all([takeEvery(REQUEST_ORG_PACKAGE_PLANS, requestOrgPackagePlans)]);
}

export default rootSaga;
