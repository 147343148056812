import React, { useEffect, useRef, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { CompatRouter } from 'react-router-dom-v5-compat';
import ConfigProvider from 'antd/es/config-provider';
import { createRoute } from 'utils/createRoute';
import AppContainer from 'routes/AppContainer';
import verifyEmailRoute from 'routes/VerifyEmailPage/route';
import setupAppleWalletPageRoute from 'pages/SetupAppleWalletPage/route';
import setupMobilePageRoute from 'pages/SetupMobilePage/route';
import setupMobileSamlPageRoute from 'routes/SetupMobileSamlPage/route';
import localAdminPageRoute from 'routes/LocalAdminPage/route';
import oAuthCallbackRoute from 'routes/OAuthCallback/route';
import i18n from 'i18n/i18n';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { useLocalStorage } from 'react-use';
import { setAlert } from 'routes/AppContainer/actions';
import AlertWrapper from 'containers/AlertWrapper';
import { LanguageSelectorNotAuthenticated } from 'new-components/LanguageSelectorNotAuthenticated/LanguageSelectorNotAuthenticated';
import { selectCurrentIdentityId } from 'routes/AppContainer/selectors';
import { useSelectorJs } from 'utils/customHooks';
import { request } from 'utils/request';
import { appColorThemeManager } from 'utils/appColorThemeManager';
import { OpApp } from 'new-components/DLS/OpApp/OpApp';
import { TanstackDevtools } from 'new-components/TanstackDevtools/TanstackDevtools';
import { useAvigilonAltaDomainRedirect } from 'utils/customHooks/useAvigilonAltaDomainRedirect';
import history from './history';
import { THEME } from './op-themes';
import './importAssets';

export default () => {
  const dispatch = useDispatch();
  const observerRef = useRef(null);

  const [colorTheme, setColorTheme] = useLocalStorage(
    appColorThemeManager.localStorageKey,
  );
  const identityId = useSelectorJs(selectCurrentIdentityId());

  useEffect(() => {
    const observerOptions = {
      attributes: true, // Observe changes to attributes (e.g., class)
      attributeFilter: ['class'], // Specify the attributes to observe (class in this case)
    };
    const observer = observerRef.current;

    observer?.disconnect();

    // Create a new MutationObserver
    observerRef.current = new MutationObserver(() => {
      const newColorTheme =
        appColorThemeManager.getCurrentElColorTheme(colorTheme);
      // Update the state only if the class state has changed
      if (colorTheme !== newColorTheme) {
        setColorTheme(newColorTheme);
      }
    });

    // Start observing the target node (body element) with the specified options
    observerRef.current.observe(appColorThemeManager.baseEl, observerOptions);

    // Clean up the observer when the component unmounts
    return () => {
      observer?.disconnect();
    };
  }, [colorTheme, setColorTheme]);

  // Sync dark mode - will mostly come into play when someone logs in on a new device
  useEffect(() => {
    const getIdentityPreferences = async () => {
      /**
       * We cannot use useOpQuery here since the Provider
       * is defined in this file. We cant move the Provider
       * up a level until we dont need `dispatch(setAlert('error', error.message));`
       */
      const {
        data: { data: identityPreferences },
      } = await request(`/identities/${identityId}/preferenceSet`, {
        method: 'GET',
      });

      // Keep local storage in sync
      if (
        colorTheme !== identityPreferences?.colorTheme &&
        identityPreferences?.colorTheme
      ) {
        appColorThemeManager.handleThemeToggle(identityPreferences.colorTheme);
        setColorTheme(identityPreferences.colorTheme);
      }
    };

    if (identityId) {
      getIdentityPreferences();
    }
  }, [identityId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Add or remove "dark" class on body
  useEffect(() => {
    if (colorTheme) {
      appColorThemeManager.handleThemeToggle(colorTheme);
    }

    if (colorTheme === 'system') {
      appColorThemeManager.initSystemThemeChangeListener();
    } else {
      appColorThemeManager.removeSystemThemeChangeListener();
    }
  }, [colorTheme]);

  useAvigilonAltaDomainRedirect();

  // Create a react-query client
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          // Catch any react-query useQuery error globally and display the alert (this will not catch useMutation errors)
          onError: (error) => {
            if (!error.suppressErrorMessage) {
              dispatch(setAlert('error', error.message));
            }
          },
        }),
      }),
  );

  const theme =
    appColorThemeManager.getColorThemeForProvider(colorTheme) === 'dark'
      ? THEME.dark
      : THEME.light;

  return (
    <I18nextProvider lng="en" defaultNS="translation" i18n={i18n}>
      <ConfigProvider theme={theme}>
        {/** Need to wrap in antd App so modal confirm works with dark mode theme
         * https://github.com/ant-design/ant-design/issues/40576
         */}
        <OpApp className="op-app">
          <ConnectedRouter history={history}>
            <CompatRouter>
              <QueryClientProvider client={queryClient}>
                <Switch>
                  {/* These routes don't need app container to mount so they can be loaded here to be lighter weight */}
                  {createRoute(setupMobilePageRoute, true)}
                  {createRoute(setupAppleWalletPageRoute, true)}
                  {createRoute(setupMobileSamlPageRoute, true)}
                  {createRoute(localAdminPageRoute, true)}
                  {createRoute(oAuthCallbackRoute, true)}

                  {/* this may not be used any more */}
                  {createRoute(verifyEmailRoute, true)}
                  <Route path="/" component={AppContainer} />
                </Switch>

                <AlertWrapper />
                <TanstackDevtools />
              </QueryClientProvider>
            </CompatRouter>
          </ConnectedRouter>
          <LanguageSelectorNotAuthenticated />
        </OpApp>
      </ConfigProvider>
    </I18nextProvider>
  );
};
