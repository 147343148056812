// ** Helper/Wrapper utilities for setting/receiving local options
const openpathConfig = require('openpathConfig');

export const setLocalSetting = (root, key, value) => {
  // get out any existing options we have for this option root
  let options = localStorage.getItem(root);

  // if it doesn't exist, lets start fresh
  if (!options) {
    options = {};
  } else {
    // it DOES exist, so we'll parse the JSON out...
    try {
      options = JSON.parse(options);
    } catch {
      throw new Error(`Invalid options in ${root}`);
    }
  }

  // Now that we safely got JSON, we can set the key with options:
  // - type (see below, to help us get valid types out since everything is stringified)
  // - value (the value we want)
  // - env (which env were setting in)
  const envPrefix =
    openpathConfig.ENV === 'prod' ? '' : `${openpathConfig.ENV}-`;
  options[`${envPrefix}${key}`] = {
    v: value,
    ts: Date.now(),
  };

  // set it in local storage
  localStorage.setItem(root, JSON.stringify(options));
};

export const getLocalSetting = (
  root,
  key,
  defaultReturn = null,
  forceOldWay = false,
) => {
  // get out any existing options we have for this option root
  let options = localStorage.getItem(root);

  // if it doesn't exist, just return the default return value
  if (!options) {
    return defaultReturn;
  }

  // it DOES exist, so lets turn it JSON
  try {
    options = JSON.parse(options);
  } catch {
    return defaultReturn;
  }

  // console.debug(`[**options] - ${JSON.stringify(options, null, 2)}`)

  // NOW we store the type.. for example, if its a boolean, this will
  // allow us to return true instead of 'true' and save headaches later
  const envPrefix =
    openpathConfig.ENV === 'prod' ? '' : `${openpathConfig.ENV}-`;

  const keyCheck = forceOldWay ? key : `${envPrefix}${key}`;
  if (options[keyCheck]) {
    return options[keyCheck]?.v || defaultReturn;
  }

  // we should never get here, but if its an invalid key, we'll
  // just return default
  return defaultReturn;
};

export const removeLocalSetting = (root, key) => {
  let options = localStorage.getItem(root);
  if (!options) return false;

  // it DOES exist, so lets turn it JSON
  try {
    options = JSON.parse(options);
  } catch {
    return false;
  }

  if (options[key]) {
    delete options[key];
    localStorage.setItem(root, JSON.stringify(options));
    return true;
  }
  return false;
};
