/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Responsive from 'react-responsive-decorator';
import { Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { push } from 'connected-react-router';
import {
  requestCheckForMaintenanceMode,
  requestCheckVersionNumber,
  checkDisabledRoutes,
} from 'routes/AppContainer/actions';
import { fireAuthRedirect } from 'routes/AuthenticatedContainer/actions';
import { useInjectReducers } from 'utils/injectReducer';
import { useInjectSagas } from 'utils/injectSaga';
import globalReducer from 'routes/AppContainer/reducer';
import appSagas from 'routes/AppContainer/sagas';
import billingSagas from 'global/billing/sagas';
import lockdownPlansSagas from 'global/lockdownPlans/sagas';
import cloudKeySagas from 'global/cloudKey/sagas';
import formWrapperSagas from 'global/formWrapper/sagas';
import { createRoute } from 'utils/createRoute';
import authContainerRoute from 'routes/AuthenticatedContainer/route';
import mfaManagementRoute from 'pages/MfaManagementPage/route';
import intercomDirectoryQrRoute from 'pages/IntercomDirectoryQrPage/route';
import intercomDirectoryRoute from 'pages/IntercomDirectoryPage/route';
import loginRoute from 'pages/LoginPage/route';
import loginSSORoute from 'pages/LoginSSOPage/route';
import forgotPasswordRoute from 'pages/ForgotPasswordPage/route';
import resetPasswordRoute from 'routes/ResetPasswordPage/route';
import maintenancePageRoute from 'routes/MaintenancePage/route';
import oIdcCallbackRoute from 'routes/OidcCallback/route';
import samlCallbackRoute from 'routes/SamlCallback/route';
import { useSelectorJs } from 'utils/customHooks';
import {
  selectCurrentOrgId,
  selectCurrentUserId,
} from 'global/accessToken/selectors';
import { NavProvider } from 'routes/AppContainer/NavContext';
import { SuirRemovedUiProvider } from './SuirRemovedUiContext';

// export responsive context
export const ResponsiveContext = createContext('desktop');
export const useResponsiveContext = () => {
  const context = useContext(ResponsiveContext);

  return context;
};

const reducers = [
  {
    key: 'app',
    reducer: globalReducer,
  },
];

const sagas = [
  {
    key: 'app',
    saga: appSagas,
  },
  {
    key: 'billing',
    saga: billingSagas,
  },
  {
    key: 'lockdownPlansSagas',
    saga: lockdownPlansSagas,
  },
  {
    key: 'cloudKey',
    saga: cloudKeySagas,
  },
  {
    key: 'formWrapper',
    saga: formWrapperSagas,
  },
];

const AppContainerRoute = ({ media, location: { pathname, search } }) => {
  const dispatch = useDispatch();

  const currentOrgId = useSelectorJs(selectCurrentOrgId());
  const currentUserId = useSelectorJs(selectCurrentUserId());

  const attemptedRoute = useRef(`${pathname}${search}`);

  const [isMobile, setIsMobile] = useState(true);

  // inject the app reducer
  useInjectReducers(reducers);

  // inject the app's sagas (and global sagas)
  useInjectSagas(sagas);

  // make sure we're logged in and valid, if not, kick 'em to login page!
  // (note this has to exist here because hooks "componentDidMount" fires AFTER
  // all children like Auth and OrgContainer have finished...)
  useEffect(() => {
    dispatch(fireAuthRedirect(attemptedRoute.current));
  }, [dispatch]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      orgId: currentOrgId,
      userId: currentUserId,
      page: 'orgContainer',
    });
  }, [currentOrgId, currentUserId]);

  // version check interval that sets up on mount
  useEffect(() => {
    // store reference to interval
    const versionCheckInterval = setInterval(
      () => dispatch(requestCheckVersionNumber()),
      5 * 60 * 1000,
    ); // 5 minute interval

    return () => {
      clearInterval(versionCheckInterval);
    };
  }, [dispatch]);

  // On mount make sure we check for maintenance mode/disabled routes
  useEffect(() => {
    // responsive context setup
    media({ minWidth: 768 }, () => {
      setIsMobile(false);
    });

    media({ maxWidth: 768 }, () => {
      setIsMobile(true);
    });

    if (pathname !== '/maintenance') {
      dispatch(
        requestCheckForMaintenanceMode(true, () => {
          // this goes all the way down to the networking layer. We should revisit this to be
          // handled in the saga but I've spent too much time cleaning it up :)
          // I will revisit another day but this timeout ensures that the setDisabledRoutes is called
          // before we  check it... -justin
          setTimeout(() => {
            dispatch(
              checkDisabledRoutes(pathname, (valid) => {
                if (!valid) dispatch(push('/errorMessage'));
              }),
            );
          }, 0);
        }),
      );
    }
  }, [dispatch, media, pathname]);

  return (
    <ResponsiveContext.Provider value={isMobile ? 'mobile' : 'desktop'}>
      <Switch>
        {createRoute(oIdcCallbackRoute, true)}
        {createRoute(samlCallbackRoute, true)}
        {createRoute(maintenancePageRoute, true)}
        {createRoute(loginRoute, true)}
        {createRoute(loginSSORoute, true)}
        {createRoute(forgotPasswordRoute, true)}
        {createRoute(resetPasswordRoute, true)}
        {createRoute(mfaManagementRoute, true)}
        {createRoute(intercomDirectoryQrRoute, true)}
        {createRoute(intercomDirectoryRoute, true)}
        <SuirRemovedUiProvider>
          <NavProvider>{createRoute(authContainerRoute, false)}</NavProvider>
        </SuirRemovedUiProvider>
      </Switch>
    </ResponsiveContext.Provider>
  );
};

AppContainerRoute.propTypes = {
  media: PropTypes.func.isRequired, // from responsive-context
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default Responsive(AppContainerRoute);
