import {
  REQUEST_LIST_LOCKDOWN_PLANS,
  SET_LOCKDOWN_PLANS,
  TRIGGER_LOCKDOWN_PLAN,
  REVERT_LOCKDOWN_PLAN,
} from './constants';

export function requestListLockdownPlans() {
  return {
    type: REQUEST_LIST_LOCKDOWN_PLANS,
  };
}

export function setLockdownPlans(plans) {
  return {
    type: SET_LOCKDOWN_PLANS,
    plans,
  };
}

export function triggerLockdownPlan(lockdownPlanId) {
  return {
    type: TRIGGER_LOCKDOWN_PLAN,
    lockdownPlanId,
  };
}

export function revertLockdownPlan(lockdownPlanId) {
  return {
    type: REVERT_LOCKDOWN_PLAN,
    lockdownPlanId,
  };
}
