import {
  FETCH_INITIAL_DATA,
  FILTER_IDP_GROUPS_SEARCH_LIST,
  SET_IDP_GROUPS_SEARCH_LIST,
  SET_ALL_DATA_RECEIVED,
  REQUEST_INACTIVATE_IDENTITY_PROVIDER,
} from './constants';

export function setAllDataReceived(value) {
  return {
    type: SET_ALL_DATA_RECEIVED,
    value,
  };
}

export function filterIdpGroupsList(query) {
  return {
    type: FILTER_IDP_GROUPS_SEARCH_LIST,
    query,
  };
}

export function setIdpGroupsSearchList(data) {
  return {
    type: SET_IDP_GROUPS_SEARCH_LIST,
    data,
  };
}

export function requestGetInitialData(code, searchParams) {
  return {
    type: FETCH_INITIAL_DATA,
    code,
    searchParams,
  };
}

export function requestInactivateIdP(
  identityProviderId,
  identityProviderCode,
  searchParams,
) {
  return {
    type: REQUEST_INACTIVATE_IDENTITY_PROVIDER,
    identityProviderId,
    identityProviderCode,
    searchParams,
  };
}
