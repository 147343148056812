export const PAGE = 'videoProviderPage';

export const REQUEST_GET_INITIAL_DATA = `app/${PAGE}/REQUEST_GET_INITIAL_DATA`;
export const SET_VIDEO_PROVIDER = `app/${PAGE}/SET_VIDEO_PROVIDER`;
export const SET_VIDEO_PROVIDER_CAMERAS = `app/${PAGE}/SET_VIDEO_PROVIDER_CAMERAS`;
export const REQUEST_GET_VIDEO_PROVIDER_CAMERAS = `app/${PAGE}/REQUEST_GET_VIDEO_PROVIDER_CAMERAS`;
export const REQUEST_SET_CAMERA_ENTRIES = `app/${PAGE}/REQUEST_SET_CAMERA_ENTRIES`;
export const REQUEST_REMOVE_VIDEO_PROVIDER = `app/${PAGE}/REQUEST_REMOVE_VIDEO_PROVIDER`;
export const SET_VIDEO_PROVIDER_TYPES =
  'app/OrgContainer/SET_VIDEO_PROVIDER_TYPES';
export const SET_VIDEO_PROVIDERS = 'app/OrgContainer/SET_VIDEO_PROVIDERS';

export const VIDEO_PROVIDER_IDS = {
  op: 0,
  meraki: 1,
  avigilon: 4, // this one is technically not supported
  ava: 5,
};
