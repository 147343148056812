import { REQUEST_ORG_PACKAGE_PLANS, SET_ORG_PACKAGE_PLANS } from './constants';

export function requestOrgPackagePlans() {
  return {
    type: REQUEST_ORG_PACKAGE_PLANS,
  };
}

export function setOrgPackagePlans(orgPackagePlans) {
  return {
    type: SET_ORG_PACKAGE_PLANS,
    orgPackagePlans,
  };
}
