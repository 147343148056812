import React from 'react';
import { ReactReduxContext } from 'react-redux';
import after from 'lodash/after';
import getInjectors from './sagaInjectors';

const useInjectSagas = (sagas = [], readyCallback = () => null) => {
  const context = React.useContext(ReactReduxContext);
  React.useEffect(() => {
    const readyCallbackAfter = after(sagas.length, readyCallback);
    const injectors = getInjectors(context.store, readyCallbackAfter);
    if (!sagas.length) readyCallback();
    sagas.forEach((s) => {
      const { key, saga, mode } = s;
      injectors.injectSaga(key, { saga, mode });
    });

    return () => {
      sagas.forEach((s) => {
        const { key } = s;
        injectors.ejectSaga(key);
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export { useInjectSagas };
