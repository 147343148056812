import {
  ADD_BANNER_DATA,
  REMOVE_BANNER_BY_KEY,
  REMOVE_ALL_BANNERS,
} from './constants';

export function addBannerData(data) {
  return {
    type: ADD_BANNER_DATA,
    data,
  };
}

export function removeBannerByKey(key) {
  return {
    type: REMOVE_BANNER_BY_KEY,
    key,
  };
}

export const removeAllBanners = () => ({
  type: REMOVE_ALL_BANNERS,
});
