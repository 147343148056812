/*
 *
 * Navigation actions
 *
 */

import {
  SET_NAVIGATION,
  CLOSE_BY_INDEX,
  OPEN_BY_INDEX,
  ACTIVATE,
  ACTIVATE_BY_INDEX,
  SET_HIDDEN,
  LOAD_NAVIGATION,
  ACTIVATE_BY_PATH,
} from './constants';

export function setNavigation(nav) {
  return {
    type: SET_NAVIGATION,
    nav,
  };
}

export function openNavMenuItemByIndex(index, closeFirst = false) {
  return {
    type: OPEN_BY_INDEX,
    index,
    closeFirst,
  };
}

export function closeNavMenuItemByIndex(index) {
  return {
    type: CLOSE_BY_INDEX,
    index,
  };
}

export function activateNavMenuItemByPath(path, closeFirst = false) {
  return {
    type: ACTIVATE_BY_PATH,
    path,
    closeFirst,
  };
}

export function activateNavMenu(index, closeFirst = false) {
  return {
    type: ACTIVATE,
    index,
    closeFirst,
  };
}

export function activateNavMenuItemByIndex(index, closeFirst = false) {
  return {
    type: ACTIVATE_BY_INDEX,
    index,
    closeFirst,
  };
}

export function setHidden(toBeHidden) {
  return {
    type: SET_HIDDEN,
    toBeHidden,
  };
}

export function loadNavigation(opconfig) {
  return {
    type: LOAD_NAVIGATION,
    opconfig,
  };
}
