import {
  SET_ORG,
  SET_SAVING_BILLING_SUBSCRIPTION_INFO,
  REQUEST_LOAD_BILLING_FORM_VALUES,
  REQUEST_EDIT_BILLING_SUBSCRIPTION,
  REQUEST_SIGN_TERMS_OF_SERVICE,
  SET_TOS_INFORMATION,
  REQUEST_GET_BILLING_INFORMATION,
  REQUEST_UPDATE_BILLABLE_FEATURES,
  REQUEST_SAML_APP_LOGIN,
  REQUEST_ADD_USER_LICENSES,
} from './constants';

export function requestGetBillingInformation() {
  return {
    type: REQUEST_GET_BILLING_INFORMATION,
  };
}
export function setOrg(org) {
  return {
    type: SET_ORG,
    org,
  };
}

export function signTermsOfService(termsVersionId, page) {
  return {
    type: REQUEST_SIGN_TERMS_OF_SERVICE,
    termsVersionId,
    page,
  };
}

export function setTOSInformation(info) {
  return {
    type: SET_TOS_INFORMATION,
    info,
  };
}

export function setSavingBillingSubscriptionInfo(saving) {
  return {
    type: SET_SAVING_BILLING_SUBSCRIPTION_INFO,
    saving,
  };
}

export function requestLoadBillingFormValues(orgId) {
  return {
    type: REQUEST_LOAD_BILLING_FORM_VALUES,
    orgId,
  };
}

export function requestEditBillingSubscription(
  org,
  packagePlanIds,
  page,
  form = null,
) {
  return {
    type: REQUEST_EDIT_BILLING_SUBSCRIPTION,
    org,
    packagePlanIds,
    page,
    form,
  };
}

export function requestUpdateBillableFeatures(limits, orgId, page, form) {
  return {
    type: REQUEST_UPDATE_BILLABLE_FEATURES,
    limits,
    orgId,
    page,
    form,
  };
}

export function requestSamlAppLogin(samlApp, orgId, email) {
  return {
    type: REQUEST_SAML_APP_LOGIN,
    samlApp,
    orgId,
    email,
  };
}
export function requestAddUserLicenses(orgId, formValues) {
  return {
    type: REQUEST_ADD_USER_LICENSES,
    orgId,
    formValues,
  };
}
