// Root Route
import React from 'react';

const RouteComponent = React.lazy(() => import('.'));

export default {
  RouteComponent,
  RouteImportPath: 'AuthenticatedContainer',
  path: '/',
  exact: false,
  name: 'authenticated',
  reducers: [
    {
      reducer: require('containers/NavMenu/reducer').default,
      key: 'navigation',
    },
  ],
  sagas: [
    {
      key: 'navmenu',
      saga: require('containers/NavMenu/sagas').default,
    },

    {
      key: 'authenticated',
      saga: require('routes/AuthenticatedContainer/sagas').default,
    },
  ],
};
