import { put, all, select, takeEvery } from 'redux-saga/effects';
import { Map, fromJS } from 'immutable';
import { getRawMenuItems, getItemConfig } from 'config/utils';
import { verifyScope } from 'utils/redirects';
import uniqueId from 'lodash/uniqueId';

import {
  LOAD_NAVIGATION,
  ACTIVATE_BY_PATH,
  ACTIVATE_BY_INDEX,
} from 'containers/NavMenu/constants';
import {
  selectCurrentOrgId,
  selectActiveScopes,
} from 'global/accessToken/selectors';
import { selectItems } from 'containers/NavMenu/selectors';
import {
  setNavigation,
  activateNavMenuItemByIndex,
  activateNavMenuItemByPath,
  openNavMenuItemByIndex,
  activateNavMenu,
} from 'containers/NavMenu/actions';
import { selectMergedConfig } from 'global/openpathconfig/selectors';
import { selectOrgIsLicenseBased } from 'routes/AppContainer/selectors';
import { selectOrgHasFeatureCode } from 'global/orgFeatures/selectors';
import { selectBillingMode } from 'global/billing/selectors';
import { getWindowLocation } from 'utils/window';

// damnit cameron, you may have been right
let loadedMenuItems = null;

const processMenuItem = (item, parentIndex, currentOrgId, activeScope) => {
  const children = item.items ? item.items : fromJS([]);
  const index = Number(uniqueId());

  const newItem = fromJS({
    _id: uniqueId('nav-store-'),
    index,
    title: item.title,
    route: item.route,
    requiredFeatureCode: item.requiredFeatureCode,
    icon: item.faIcon,
    badge: item.badge,
    counter: item.counter,
    external: item.external,
    action: item.action,
    parent: parentIndex,
    admin: item.admin || false,
    style: item.style,
    highlightStyle: item.highlightStyle,
    hidden: item.hidden
      ? item.hidden
      : !verifyScope(activeScope, item.scope, currentOrgId, item.title),
    hasVisibleChildren: !!children.filter((c) => !c.hidden).length,
    items: children.map((c) =>
      processMenuItem(c, index, currentOrgId, activeScope),
    ),
    alertCount: item.alertCount,
  });
  loadedMenuItems.push(newItem);
  return newItem;
};

function getActiveItem(items) {
  for (let i = items.length - 1; i >= 0; i--) {
    const item = Map.isMap(items[i]) ? items[i].toJS() : items[i];
    if (item.items) {
      // const subItems = item.items.map(index => loadedMenuItems[index]) // eslint-disable-line
      const result = getActiveItem(item.items);
      if (result) {
        return result;
      }
    }

    const windowLocation = getWindowLocation();
    if (
      item.route &&
      windowLocation.pathname.endsWith(item.route.split('?')[0])
    ) {
      return item;
    }
    if (
      item.route &&
      windowLocation.pathname.includes(item.route.split('?')[0])
    ) {
      return item;
    }
  }
  return null;
}

function* loadNavigation() {
  loadedMenuItems = [];
  // get the raw JSON of the menu
  const opconfig = yield select(selectMergedConfig());
  const isLicenseBased = yield select(selectOrgIsLicenseBased());
  const hasAppMarketplace = yield select(
    selectOrgHasFeatureCode('appMarketplace'),
  );
  const billingMode = yield select(selectBillingMode());
  const rawMenuItems = getRawMenuItems(opconfig, {
    isLicenseBased,
    hasAppMarketplace,
    billingMode,
  });

  const currentOrgId = yield select(selectCurrentOrgId());
  const activeScope = yield select(selectActiveScopes());

  rawMenuItems.map((i) => processMenuItem(i, null, currentOrgId, activeScope));
  yield put(setNavigation(loadedMenuItems));

  const item = getActiveItem(loadedMenuItems);
  if (item) {
    yield put(activateNavMenuItemByPath(item.route));
  }
}

function* activateByPath(action) {
  try {
    const navItemsImmutable = yield select(selectItems());
    const navItems = navItemsImmutable.toJS();
    if (!navItems.length) {
      return;
    }
    let found = null;
    const findPath = (p, n) => {
      // navigate through n backwards using a for loop
      for (let i = n.length - 1; i >= 0; i--) {
        if (found) return;
        const ni = n[i];
        const path = p.split('?')[0];
        const cleaned = path.split('/');
        cleaned.pop();
        if (
          !ni.hidden &&
          (ni.route === path || ni.route === cleaned.join('/'))
        ) {
          found = ni;
        } else if (ni.items && ni.items.length) {
          findPath(path, ni.items);
        }
      }
      return found;
    };

    findPath(action.path, navItems);
    if (found) {
      yield put(activateNavMenuItemByIndex(found.index));
    }
  } catch (ex) {
    console.error(ex);
  }
}

function* activateByIndex(action) {
  const navItemsImmutable = yield select(selectItems());
  const navItems = navItemsImmutable.toJS();
  let item = navItems.find((n) => n.index === action.index);
  const scope = getItemConfig(getRawMenuItems(), item.route).scope;
  if (scope) {
    window.sessionStorage.setItem('op-scopes', scope);
  }

  while (item.parent) {
    const parent = navItems.find((n) => n.index === item.parent); // eslint-disable-line no-loop-func
    if (parent.hasVisibleChildren) {
      yield put(openNavMenuItemByIndex(item.parent));
    }
    item = parent;
  }
  yield put(activateNavMenu(action.index));
}

function* rootSaga() {
  yield all([
    takeEvery(LOAD_NAVIGATION, loadNavigation),
    takeEvery(ACTIVATE_BY_PATH, activateByPath),
    takeEvery(ACTIVATE_BY_INDEX, activateByIndex),
  ]);
}

export default rootSaga;
