export const PAGE = 'identityProvidersPage';

export const FETCH_INITIAL_DATA = `app/${PAGE}/FETCH_INITIAL_DATA`;
export const FILTER_IDP_GROUPS_SEARCH_LIST = `app/${PAGE}/FILTER_IDP_GROUPS_SEARCH_LIST`;
export const SET_IDP_GROUPS_SEARCH_LIST = `app/${PAGE}/SET_IDP_GROUPS_SEARCH_LIST`;
export const SET_ALL_DATA_RECEIVED = `app/${PAGE}/SET_ALL_DATA_RECEIVED`;
export const REQUEST_INACTIVATE_IDENTITY_PROVIDER = `app/${PAGE}/REQUEST_INACTIVATE_IDENTITY_PROVIDER`;

export const defaultAttributeMappingJson = {
  rules: [
    {
      type: 'specific',
      mappings: [
        {
          conditions: [],
          destinations: [
            {
              roleIds: [],
              accessGroupIds: [],
            },
          ],
        },
      ],
    },
  ],
};
