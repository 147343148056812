import { PAGE } from 'routes/IntegrationsPage/constants';
import React from 'react';

const RouteComponent = React.lazy(() => import('.'));

// no scopes here since this page is a non-menu-related interstitial
// page that catches a redirect from Helium and then proceeds with a
// login flow
export const scope = [];

export default {
  RouteComponent,
  path: '/saml/callback',
  name: PAGE,
  reducers: [
    {
      key: PAGE,
      reducer: require('routes/IntegrationsPage/reducer').default,
    },
  ],
  scope,
  sagas: [
    {
      key: 'integrationsPageSagas',
      saga: require('routes/IntegrationsPage/sagas').default,
    },
  ],
};
