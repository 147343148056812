import { all, takeEvery, select, call } from 'redux-saga/effects';
import { selectCurrentOrgId } from 'global/accessToken/selectors';
import { requestAndSet } from 'utils/helpers';
import { REQUEST_ALL_FEATURES } from './constants';
import { setAllFeatures } from './actions';

export function* requestAllFeatures() {
  const orgId = yield select(selectCurrentOrgId());

  if (!orgId) {
    // In mastermode
    yield call(requestAndSet, 'listFeatures', [], {
      createSetterAction: ({ data }) => setAllFeatures(data),
    });
  } else {
    // Not in mastermode
    yield call(requestAndSet, 'listOrgAllFeatures', [orgId], {
      createSetterAction: ({ data }) => setAllFeatures(data),
    });
  }
}

function* rootSaga() {
  yield all([takeEvery(REQUEST_ALL_FEATURES, requestAllFeatures)]);
}

export default rootSaga;
