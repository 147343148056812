import { setLocalSetting, getLocalSetting } from 'utils/localUserSettings';
import { getAcuStatusData } from 'global/mqtt/utils';

// @DEPRECATED DO NOT USE
export const setHardwareDashboardOption = (key, value) =>
  setLocalSetting('op-hardwareDashboard', key, value);
export const getHardwareDashboardOption = (key, defaultReturn = null) =>
  getLocalSetting('op-hardwareDashboard', key, defaultReturn, true);

export const setDashboardOption = (key, value, orgId = null) => {
  const current = getLocalSetting('op-dashboardOptions', key, {});
  const v = {
    ...current,
    [orgId]: value,
  };
  setLocalSetting('op-dashboardOptions', key, v);
};
export const getDashboardOption = (key, defaultReturn = null, orgId) => {
  // get the local settings
  const ls = getLocalSetting('op-dashboardOptions', key, defaultReturn);
  return ls[orgId] || defaultReturn;
};

export const findLatestTimestamp = (metadata) => {
  if (!metadata) return null;
  if (!Number.isNaN(Number(metadata.timestamp))) return metadata.timestamp;
  return Object.entries(metadata).reduce((ts, [, v]) => {
    const vts = findLatestTimestamp(v);
    return vts > ts ? vts : ts;
  }, 0);
};

export const findLatestPublishedTimestamp = (data) => {
  // If the data is null, undefined, or not an object, return 0
  if (data === 'undefined' || data === null || typeof data !== 'object') {
    return 0;
  }

  // If data contains a publishedTimestamp that is a number, return it
  if (Number(data?.publishedTimestamp)) {
    return data.publishedTimestamp;
  }

  /** Otherwise traverse the values of the object and through recursion
   * return back the largest publishedTimestamp */
  return Object.values(data).reduce((timestamp, value) => {
    const valueTimestamp = findLatestPublishedTimestamp(value);
    return Math.max(valueTimestamp, timestamp);
  }, 0);
};

// ** This function gets the connected device counts for the stats widgets
export const getConnectedDeviceCount = ({
  acuShadowState = {},
  readers,
  mutedControllerIds = [],
  mutedReaderIds = [],
  videoControllerAcuIds = [],
}) => {
  let totalConnectedReaders = 0;
  let totalDisconnectedReaders = 0;
  let acusOnline = 0;
  let acusOffline = 0;
  let videoAcusOnline = 0;
  let videoAcusOffline = 0;

  Object.keys(acuShadowState).forEach((acuOpal) => {
    if (acuShadowState[acuOpal].state) {
      const reportedShadowState = acuShadowState[acuOpal].state.reported;

      const acuId = parseInt(
        acuOpal.split(':')[acuOpal.split(':').length - 1],
        10,
      );
      const isMuted = mutedControllerIds.includes(parseInt(acuId, 10));

      const heliumReadersForAcu = readers
        .filter((r) => r.acuPort && r.acuPort.acu.id === Number(acuId))
        .sort(
          (a, b) => a.acuPort.virtualPortNumber - b.acuPort.virtualPortNumber,
        );

      heliumReadersForAcu.forEach((heliumReader) => {
        const { portNumber } = heliumReader.acuPort;
        const reportedReader = reportedShadowState.reader;
        const shadowData = reportedReader?.portNumber?.[portNumber] || {};

        const readerConnected = shadowData?.readerConnected;

        const isReaderMuted = mutedReaderIds.includes(
          parseInt(heliumReader.id, 10),
        );

        if (!isReaderMuted) {
          if (readerConnected) {
            totalConnectedReaders += 1;
          } else {
            totalDisconnectedReaders += 1;
          }
        }
      });

      const { isVpnError } = getAcuStatusData(
        acuShadowState?.[acuOpal],
        acuOpal,
      );

      // return early if it is a Wireless Lock Gateway - do not add these to controller count
      if (acuShadowState[acuOpal].state.reported.wirelessLockGateway) {
        return;
      }

      if (!isMuted) {
        if (isVpnError) {
          if (videoControllerAcuIds.includes(acuId)) {
            videoAcusOffline += 1;
          } else {
            acusOffline += 1;
          }
        } else if (videoControllerAcuIds.includes(acuId)) {
          videoAcusOnline += 1;
        } else {
          acusOnline += 1;
        }
      }
    }
  });

  return {
    totalConnectedReaders,
    totalDisconnectedReaders,
    acusOnline,
    acusOffline,
    videoAcusOffline,
    videoAcusOnline,
  };
};
