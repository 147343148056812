import { fromJS } from 'immutable';

import {
  SET_NAVIGATION,
  OPEN_BY_INDEX,
  CLOSE_BY_INDEX,
  ACTIVATE,
} from './constants';

// const loadedMenuItems = [] // @TODO Gross global

const initialState = fromJS({
  items: [],
  activeMenuItem: null,
  openMenuItems: fromJS([]),
});

/**
 * Reducer for Navigation container.
 *
 * @param  {object} state       The current state
 * @param  {string} action       The action to apply to current state
 *
 * @returns {object}           An object containing the new state
 */
function navigationReducer(state = initialState, action) {
  switch (action.type) {
    case CLOSE_BY_INDEX:
      return state.set(
        'openMenuItems',
        state.get('openMenuItems').filter((i) => i !== action.index),
      );
    case OPEN_BY_INDEX: {
      // if we are starting with a new root, close them
      if (action.closeFirst) {
        return state.set(
          'openMenuItems',
          initialState.get('openMenuItems').push(action.index),
        );
      }
      // ...otherwise we want to open it as well
      return state.set(
        'openMenuItems',
        state.get('openMenuItems').concat(action.index),
      );
    }
    case ACTIVATE: {
      // if we are starting with a new root, close them
      return state
        .set(
          'openMenuItems',
          action.closeFirst
            ? initialState.get('openMenuItems')
            : state.get('openMenuItems'),
        )
        .set('activeMenuItem', action.index);
    }
    case SET_NAVIGATION:
      return state.set('items', fromJS(action.nav));
    default:
      return state;
  }
}

export default navigationReducer;
