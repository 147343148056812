import * as Sentry from '@sentry/react';

const openpathConfig = require('openpathConfig');

const sentryEnabled =
  openpathConfig?.SENTRY?.enabled && openpathConfig.SENTRY?.dsn;
const isDevServer = !!process.env.WEBPACK_DEV_SERVER; // don't run in the dev server because hot reloading breaks source mapping

const init = () => {
  if (sentryEnabled && (process.env.FORCE_ENABLE_SENTRY || !isDevServer)) {
    const {
      SENTRY: sentryConfig,
      __GIT_VERSION__: version,
      ENV: env,
    } = openpathConfig;
    const release = `${env}-${version}`;
    Sentry.init({
      dsn: sentryConfig.dsn,
      release,
      environment: env,
      ignoreErrors: [/ChunkLoadError(.)+/], // This error creates a lot of noise and is 99.9% of the time due to a new release happening during a session
    });
  }
};

export { init };
