import {
  takeEvery,
  takeLatest,
  call,
  put,
  all,
  select,
  delay,
} from 'redux-saga/effects';

import { request } from 'utils/request';
import { describeOrg } from 'heliumApi';

import { setOrg, setNetsuitePackagePlans } from 'routes/OrgContainer/actions';
import { addBannerData, removeBannerByKey } from 'global/banner/actions';
// import { requestPackagePlans } from 'global/packagePlans/actions'
import { setAlert } from 'routes/AppContainer/actions';
import { selectCurrentOrgId } from 'global/accessToken/selectors';
import {
  selectBillingBarData,
  selectBillingIsHidden,
} from 'global/billing/selectors';
import { requestOrgFeatures } from 'global/orgFeatures/sagas';
import { setIsEditing, setIsLoading } from 'global/formWrapper/actions';
import { sendToHelium } from 'utils/helpers';
import { getAllPackagePlans } from 'routes/OrgContainer/sagas';
import { selectCurrentIdentityLanguage } from 'routes/AppContainer/selectors';
import {
  setOrg as billingSetOrg,
  setSavingBillingSubscriptionInfo,
  setTOSInformation,
} from './actions';
import {
  REQUEST_ADD_USER_LICENSES,
  REQUEST_GET_BILLING_INFORMATION,
  REQUEST_LOAD_BILLING_FORM_VALUES,
  REQUEST_EDIT_BILLING_SUBSCRIPTION,
  REQUEST_SIGN_TERMS_OF_SERVICE,
  REQUEST_UPDATE_BILLABLE_FEATURES,
  REQUEST_SAML_APP_LOGIN,
  REQUEST_ERP_SAML_APP_LOGIN,
} from './constants';

function* requestGetOrgInformation() {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const orgId = yield select(selectCurrentOrgId());
  if (!orgId) return;
  const resource = `/orgs/${orgId}`;
  const options = {
    method: 'GET',
    headers: {
      'Accept-Language': currentIdentityLanguage,
    },
  };
  const response = yield call(request, resource, options);
  if (response.err) {
    yield put(
      setAlert('error', response.err.localizedMessage || response.err.message),
    );
    return;
  }
  yield put(billingSetOrg(response.data.data));
}

export function* requestGetBillingInformation() {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const orgId = yield select(selectCurrentOrgId());
  if (!orgId) return;
  yield call(requestGetOrgInformation);

  const options = {
    method: 'GET',
    headers: {
      'Accept-Language': currentIdentityLanguage,
    },
  };

  // terms of service stuff
  const resource = '/termsVersion/latest';
  const response = yield call(request, resource, options);
  if (response.err) {
    yield put(
      setAlert('error', response.err.localizedMessage || response.err.message),
    );
    return;
  }
  yield put(setTOSInformation(response.data.data));

  // @TODO  we don't really need this selector so it should be refactored to
  // just set the banner here. Also this can fire multiple times so we should
  // refactor it to fire on orgContainer mount only
  const x = yield select(selectBillingBarData());
  const billingIsHidden = yield select(selectBillingIsHidden());
  if (!billingIsHidden && x.get('showBanner')) {
    yield put(addBannerData(x));
  } else {
    yield put(removeBannerByKey(x.get('key')));
  }
}

function* requestLoadBillingFormValues(action) {
  yield call(requestGetBillingInformation, action);
}

function* requestEditBillingSubscription(action) {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const { org, packagePlanIds, page } = action;
  yield put(setSavingBillingSubscriptionInfo(true));
  if (!org?.id) return;
  const resource = `/orgs/${org.id}/orgPackagePlans/packagePlanIds`;
  const options = {
    method: 'PUT',
    body: JSON.stringify({ packagePlanIds }),
    headers: {
      'Accept-Language': currentIdentityLanguage,
    },
  };

  const response = yield call(request, resource, options);
  if (response.err) {
    yield put(
      setAlert('error', response.err.localizedMessage || response.err.message),
    );
    yield put(setSavingBillingSubscriptionInfo(false));
    return;
  }

  const packagePlans = yield call(getAllPackagePlans, {
    orgId: org.id,
    forceAll: true,
  });
  yield put(setNetsuitePackagePlans(page, packagePlans));

  yield put(
    setAlert('success', 'Your subscription plan was successfully changed'),
  );
  yield put(setSavingBillingSubscriptionInfo(false));

  // reload billing form values so that both the top section on the
  // billing page, as well as the global billing banner (if any) will
  // refresh appropriately - first wait briefly to give stripe a
  // chance to process, and then for helium to receive and handle
  // corresponding webhooks
  yield delay(2000);
  yield call(requestLoadBillingFormValues);

  // Reload org features as a package change can result in a different feature set
  yield call(requestOrgFeatures);
  // turn off editing mode since we're a success!
  yield put(setIsEditing(action.page, action.form, false));
}

function* requestSignTermsOfService(action) {
  const { termsVersionId, page } = action;
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const orgId = yield select(selectCurrentOrgId());
  const resource = `/orgs/${orgId}/termsAgreements`;
  const options = {
    method: 'POST',
    body: JSON.stringify({ termsVersionId }),
    headers: {
      'Accept-Language': currentIdentityLanguage,
    },
  };

  const response = yield call(request, resource, options);
  if (response.err) {
    yield put(
      setAlert('error', response.err.localizedMessage || response.err.message),
    );
    return;
  }

  const describeOrgResponse = yield call(describeOrg, orgId);
  if (describeOrgResponse.err) {
    yield put(setAlert('error', describeOrgResponse.err.message));
  } else {
    const org = describeOrgResponse.data.data;
    yield put(setOrg(page, org));
  }

  // reload billing form values so that both the top section on the
  // billing page, as well as the global billing banner (if any) will
  // refresh appropriately - no need to delay first here, because any
  // effects of signing ToS will be immediate (no round-trip through
  // stripe)
  yield call(requestLoadBillingFormValues);
}

function* requestUpdateBillableFeatures(action) {
  yield put(setIsLoading(action.page, action.form, true));
  const payload = [];
  Object.keys(action.limits).forEach((k) => {
    const featureId = parseInt(k.split('_')[k.split('_').length - 1], 10);
    const index = payload.findIndex((bf) => bf.featureId === featureId);

    if (k.indexOf('hardLimit') > -1) {
      if (index > -1) {
        payload[index].hardLimit = parseInt(action.limits[k], 10);
      } else {
        payload.push({
          orgId: action.orgId,
          featureId,
          hardLimit: parseInt(action.limits[k], 10),
        });
      }
    } else if (index > -1) {
      payload[index].softLimit = parseInt(action.limits[k], 10);
    } else {
      payload.push({
        orgId: action.orgId,
        featureId,
        softLimit: parseInt(action.limits[k], 10),
      });
    }
  });

  yield call(sendToHelium, 'updateBillableFeatures', [], payload, {
    successMessage: 'Successfully updated quantity limits!',
  });
  yield put(setIsLoading(action.page, action.form, false));
}

export function* samlAppLogin(action) {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const resource = `/orgs/${action.orgId}/samlApps/${action.samlApp}/login`;
  const options = {
    method: 'GET',
    headers: {
      'Accept-Language': currentIdentityLanguage,
    },
  };
  const response = yield call(request, resource, options);
  if (response.err) {
    yield put(
      setAlert('error', response.err.localizedMessage || response.err.message),
    );
    return;
  }
  try {
    const f = document.createElement('form');
    f.setAttribute('method', 'post');
    f.setAttribute('action', response.data.data.acsUrl);
    f.setAttribute('target', '_blank');

    const i1 = document.createElement('input');
    i1.setAttribute('type', 'hidden');
    i1.setAttribute('name', 'SAMLResponse');
    i1.setAttribute('value', response.data.data.samlResponse);

    const i2 = document.createElement('input');
    i2.setAttribute('type', 'hidden');
    i2.setAttribute('name', 'RelayState');
    i2.setAttribute('value', response.data.data.relayState);

    f.appendChild(i1);
    f.appendChild(i2);

    document.getElementsByTagName('body')[0].appendChild(f);
    f.submit();
    // NOTE: this timeout probably isn't necessary but I wanted
    // to err on the side of caution
    setTimeout(() => f.parentNode.removeChild(f), 100);
  } catch (err) {
    console.error(err);
    yield put(
      setAlert(
        'error',
        'Failed to open a new tab/window - please make sure your browser allows pop-ups for this site.',
      ),
    );
  }
}

function* samlErpLogin() {
  const orgId = yield select(selectCurrentOrgId());

  yield put({
    type: REQUEST_SAML_APP_LOGIN,
    samlApp: 'erp',
    orgId,
  });
}

function* requestAddUserLicenses({ orgId, formValues }) {
  // yield put(setOrgContainerReducer(QUANTITY_REQUEST_SAVING, PAGE, true))
  yield call(
    sendToHelium,
    'createRequest',
    [orgId],
    {
      requestedQuantity: formValues.quantityRequested,
      requestType: 'Q', // Quantity request
      packageId: 14, // Additional Users
    },
    { successMessage: 'Successfully created request!' },
  );
  // yield put(setOrgContainerReducer(QUANTITY_REQUEST_SAVING, PAGE, false))
}

function* rootSaga() {
  yield all([
    takeEvery(REQUEST_GET_BILLING_INFORMATION, requestGetBillingInformation),
    takeEvery(REQUEST_LOAD_BILLING_FORM_VALUES, requestLoadBillingFormValues),
    takeEvery(
      REQUEST_EDIT_BILLING_SUBSCRIPTION,
      requestEditBillingSubscription,
    ),
    takeEvery(REQUEST_UPDATE_BILLABLE_FEATURES, requestUpdateBillableFeatures),
    takeEvery(REQUEST_SIGN_TERMS_OF_SERVICE, requestSignTermsOfService),
    takeEvery(REQUEST_SAML_APP_LOGIN, samlAppLogin),
    takeEvery(REQUEST_ERP_SAML_APP_LOGIN, samlErpLogin),
    takeLatest(REQUEST_ADD_USER_LICENSES, requestAddUserLicenses),
  ]);
}

export default rootSaga;
