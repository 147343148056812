export const PAGE = 'entryDashboard';
export const TABLE = 'entryDashboardTable';

export const SET_PAGE_DATA = `app/${PAGE}/SET_PAGE_DATA`;
export const REQUEST_LOAD_USER_IMAGE = `app/${PAGE}/REQUEST_LOAD_USER_IMAGE`;
export const SET_UI_LOCK_STATUS = `app/${PAGE}/SET_UI_LOCK_STATUS`;
export const SET_ACTIVITY_LOADING = `app/${PAGE}/SET_ACTIVITY_LOADING`;
export const SET_ENTRY_STATUS_LOADING = `app/${PAGE}/SET_ENTRY_STATUS_LOADING`;
export const SET_SHARED_USERS_LOADING = `app/${PAGE}/SET_SHARED_USERS_LOADING`;
export const SET_DASHBOARD_PROPS_LOADED = `app/${PAGE}/SET_DASHBOARD_PROPS_LOADED`;

export const UI_LOCK_STATUS = {
  UI_LOCK_NONE: 'UI_LOCK_NONE',
  UI_LOCK_SENDING: 'UI_LOCK_SENDING',
  UI_LOCK_SUCCESS: 'UI_LOCK_SUCCESS',
  UI_LOCK_FAILURE: 'UI_LOCK_FAILURE',
};
