import { take, call, put, all, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import Joi from 'joi';
import cloneDeep from 'lodash/cloneDeep';

import { sendToHelium } from 'utils/helpers';

import { changeRoute } from 'routes/AuthenticatedContainer/actions';
import { CREATE_EDIT_ITEM } from './constants';

const isValidConfig = (config) => {
  // Exit if no config
  if (!config) {
    return 'Config is missing from options!';
  }

  // Validator schema
  const schema = Joi.object({
    isEdit: Joi.bool().required(),
    create: Joi.object({
      endpointName: Joi.string().required(),
      endpointRequisites: Joi.array(),
      successMessage: Joi.string().required(),
      valuesForPayload: Joi.function().required(),
      additionalValuesForPayload: [Joi.object(), Joi.function()],
      successRoute: Joi.string(),
      successRouteParams: Joi.string(),
      parseFormValuesForSubmit: Joi.function(),
      afterHook: Joi.function(),
      beforeHook: Joi.object({
        saga: Joi.function().required(),
        formValuesAddendumCallback: Joi.function().required(),
        shouldRun: Joi.function(),
      }),
    }),
    edit: Joi.object({
      endpointName: Joi.string().required(),
      endpointRequisites: Joi.array(),
      successMessage: Joi.string().required(),
      valuesForPayload: Joi.function().required(),
      additionalValuesForPayload: [Joi.object(), Joi.function()],
      successRoute: Joi.string(),
      successRouteParams: Joi.string(),
      parseFormValuesForSubmit: Joi.function(),
      afterHook: Joi.function(),
      beforeHook: Joi.object({
        saga: Joi.function().required(),
        formValuesAddendumCallback: Joi.function().required(),
        shouldRun: Joi.function(),
      }),
    }),
  }).or('create', 'edit');

  const { error } = schema.validate(config);

  return error;
};

// This replaces submitForm
function* requestCreateEditItem({
  formValues,
  initialFormValues,
  submitFormConfig: config,
}) {
  // Validate config and exit if not valid
  const validationError = isValidConfig(config);
  if (validationError) {
    console.error(validationError);
    return;
  }

  // Get values of specified config
  const {
    valuesForPayload,
    additionalValuesForPayload,
    endpointName,
    endpointRequisites,
    successMessage,
    successRoute,
    successRouteParams = '',
    parseFormValuesForSubmit,
    beforeHook,
    afterHook,
  } = config[config.isEdit ? 'edit' : 'create'];

  // Make a copy of the form values so they can be manipulated
  let adjustedFormValues = cloneDeep(formValues);

  // Parse form values if needed
  try {
    if (parseFormValuesForSubmit) {
      adjustedFormValues = parseFormValuesForSubmit(adjustedFormValues);
    }
  } catch (ex) {
    console.error(
      `::ERROR PROCESSING FORMVALUES WITH config.parseFormvaluesForSubmit ${ex}`,
    );
  }

  // Run the before hook saga if there is one and shouldRun is true
  let beforeHookData = null;
  if (beforeHook && beforeHook.shouldRun(formValues)) {
    const { saga, formValuesAddendumCallback } = beforeHook;
    const { data, isError } = yield call(
      saga,
      adjustedFormValues,
      config.isEdit,
    );
    if (isError) return;
    beforeHookData = data;
    adjustedFormValues = {
      ...adjustedFormValues,
      ...formValuesAddendumCallback(data),
    };
  }

  // Create payload by using the adjustedFormValues that match the values array in the config
  const payload = valuesForPayload(formValues, initialFormValues).reduce(
    (acc, value) => ({
      ...acc,
      [value]:
        adjustedFormValues[value] === undefined ||
        adjustedFormValues[value] === '' ||
        adjustedFormValues[value] === 'op-null'
          ? null
          : adjustedFormValues[value],
    }),
    {
      ...(typeof additionalValuesForPayload === 'function'
        ? additionalValuesForPayload(formValues, initialFormValues)
        : additionalValuesForPayload),
    },
  );

  // Make the request
  const { data, errorMessage } = yield call(
    sendToHelium,
    endpointName,
    endpointRequisites,
    payload,
    successMessage ? { successMessage } : {},
  );

  // Return if the request errors
  if (errorMessage) return;

  // Run the after hook if there is one
  if (afterHook) {
    afterHook({
      data,
      beforeHookData,
      errorMessage,
      formValues: adjustedFormValues,
    });
  }

  // Change the route if a successRoute is passed
  if (successRoute) {
    yield put(changeRoute(`${successRoute}${successRouteParams}`));
  }
}

function* rootSaga() {
  yield all([takeEvery(CREATE_EDIT_ITEM, requestCreateEditItem)]);
  yield take(LOCATION_CHANGE);
}

export default rootSaga;
