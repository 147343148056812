import {
  SET_PAGE_DATA,
  SET_UI_LOCK_STATUS,
  SET_ACTIVITY_LOADING,
  SET_SHARED_USERS_LOADING,
  SET_DASHBOARD_PROPS_LOADED,
} from './constants';

export function setPageData(currentAcuShadowState) {
  return {
    type: SET_PAGE_DATA,
    currentAcuShadowState,
  };
}

export function setActivityLoading(activityLoading) {
  return {
    type: SET_ACTIVITY_LOADING,
    activityLoading,
  };
}

export function setSharedUsersLoading(sharedUsersLoading) {
  return {
    type: SET_SHARED_USERS_LOADING,
    sharedUsersLoading,
  };
}

export function setDashboardPropsLoaded(dashboardPropsLoaded) {
  return {
    type: SET_DASHBOARD_PROPS_LOADED,
    dashboardPropsLoaded,
  };
}

export function setUiLockStatus(lock, status) {
  return {
    type: SET_UI_LOCK_STATUS,
    lock,
    status,
  };
}
