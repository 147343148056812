import {
  SET_ACCESS_TOKEN,
  SET_MFA_CREDENTIALS,
  SET_PROCESSED_SCOPES,
  SET_REMOTE_FEATURE_FLAGS,
} from './constants';

export function setRemoteFeatureFlags(orgId, flags) {
  return {
    type: SET_REMOTE_FEATURE_FLAGS,
    orgId,
    flags,
  };
}

export function setAccessToken(token) {
  return {
    type: SET_ACCESS_TOKEN,
    data: token,
  };
}

export function setProcessedScopes(scopes) {
  return {
    type: SET_PROCESSED_SCOPES,
    scopes,
  };
}

export function setMfaCredentials(mfaCredentials) {
  return {
    type: SET_MFA_CREDENTIALS,
    mfaCredentials,
  };
}
