// @TODO - I don't understand this function, so I'm not refactoring it yet. -justin
const faviconsContext = require.context(
  '!!file-loader?name=assets/favicons/[name].[ext]!.',
  true,
  new RegExp(
    `assets/favicons-${OP_ENV}/(.*).(svg|png|ico|xml|json|webmanifest)$`, // eslint-disable-line no-undef
  ),
);

const favKeys = faviconsContext.keys();
favKeys.forEach(faviconsContext);
