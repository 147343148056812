import { t } from 'i18next';

export const stateOptions = () => [
  {
    label: t('Alabama'),
    value: 'AL',
  },
  {
    label: t('Alaska'),
    value: 'AK',
  },
  {
    label: t('American Samoa'),
    value: 'AS',
  },
  {
    label: t('Arizona'),
    value: 'AZ',
  },
  {
    label: t('Arkansas'),
    value: 'AR',
  },
  {
    label: t('California'),
    value: 'CA',
  },
  {
    label: t('Colorado'),
    value: 'CO',
  },
  {
    label: t('Connecticut'),
    value: 'CT',
  },
  {
    label: t('Delaware'),
    value: 'DE',
  },
  {
    label: t('District Of Columbia'),
    value: 'DC',
  },
  {
    label: t('Federated States Of Micronesia'),
    value: 'FM',
  },
  {
    label: t('Florida'),
    value: 'FL',
  },
  {
    label: t('Georgia'),
    value: 'GA',
  },
  {
    label: t('Guam'),
    value: 'GU',
  },
  {
    label: t('Hawaii'),
    value: 'HI',
  },
  {
    label: t('Idaho'),
    value: 'ID',
  },
  {
    label: t('Illinois'),
    value: 'IL',
  },
  {
    label: t('Indiana'),
    value: 'IN',
  },
  {
    label: t('Iowa'),
    value: 'IA',
  },
  {
    label: t('Kansas'),
    value: 'KS',
  },
  {
    label: t('Kentucky'),
    value: 'KY',
  },
  {
    label: t('Louisiana'),
    value: 'LA',
  },
  {
    label: t('Maine'),
    value: 'ME',
  },
  {
    label: t('Marshall Islands'),
    value: 'MH',
  },
  {
    label: t('Maryland'),
    value: 'MD',
  },
  {
    label: t('Massachusetts'),
    value: 'MA',
  },
  {
    label: t('Michigan'),
    value: 'MI',
  },
  {
    label: t('Minnesota'),
    value: 'MN',
  },
  {
    label: t('Mississippi'),
    value: 'MS',
  },
  {
    label: t('Missouri'),
    value: 'MO',
  },
  {
    label: t('Montana'),
    value: 'MT',
  },
  {
    label: t('Nebraska'),
    value: 'NE',
  },
  {
    label: t('Nevada'),
    value: 'NV',
  },
  {
    label: t('New Hampshire'),
    value: 'NH',
  },
  {
    label: t('New Jersey'),
    value: 'NJ',
  },
  {
    label: t('New Mexico'),
    value: 'NM',
  },
  {
    label: t('New York'),
    value: 'NY',
  },
  {
    label: t('North Carolina'),
    value: 'NC',
  },
  {
    label: t('North Dakota'),
    value: 'ND',
  },
  {
    label: t('Northern Mariana Islands'),
    value: 'MP',
  },
  {
    label: t('Ohio'),
    value: 'OH',
  },
  {
    label: t('Oklahoma'),
    value: 'OK',
  },
  {
    label: t('Oregon'),
    value: 'OR',
  },
  {
    label: t('Palau'),
    value: 'PW',
  },
  {
    label: t('Pennsylvania'),
    value: 'PA',
  },
  {
    label: t('Puerto Rico'),
    value: 'PR',
  },
  {
    label: t('Rhode Island'),
    value: 'RI',
  },
  {
    label: t('South Carolina'),
    value: 'SC',
  },
  {
    label: t('South Dakota'),
    value: 'SD',
  },
  {
    label: t('Tennessee'),
    value: 'TN',
  },
  {
    label: t('Texas'),
    value: 'TX',
  },
  {
    label: t('Utah'),
    value: 'UT',
  },
  {
    label: t('Vermont'),
    value: 'VT',
  },
  {
    label: t('Virgin Islands'),
    value: 'VI',
  },
  {
    label: t('Virginia'),
    value: 'VA',
  },
  {
    label: t('Washington'),
    value: 'WA',
  },
  {
    label: t('West Virginia'),
    value: 'WV',
  },
  {
    label: t('Wisconsin'),
    value: 'WI',
  },
  {
    label: t('Wyoming'),
    value: 'WY',
  },
];

export const countryOptions = () => [
  { label: t('United States'), value: 'US' },
  { label: t('Afghanistan'), value: 'AF' },
  { label: t('Albania'), value: 'AL' },
  { label: t('Algeria'), value: 'DZ' },
  { label: t('Andorra'), value: 'AD' },
  { label: t('Angola'), value: 'AO' },
  { label: t('Antigua and Barbuda'), value: 'AG' },
  { label: t('Argentina'), value: 'AR' },
  { label: t('Armenia'), value: 'AM' },
  { label: t('Australia'), value: 'AU' },
  { label: t('Austria'), value: 'AT' },
  { label: t('Azerbaijan'), value: 'AZ' },
  { label: t('Bahamas'), value: 'BS' },
  { label: t('Bahrain'), value: 'BH' },
  { label: t('Bangladesh'), value: 'BD' },
  { label: t('Barbados'), value: 'BB' },
  { label: t('Belarus'), value: 'BY' },
  { label: t('Belgium'), value: 'BE' },
  { label: t('Belize'), value: 'BZ' },
  { label: t('Benin'), value: 'BJ' },
  { label: t('Bhutan'), value: 'BT' },
  { label: t('Bolivia'), value: 'BO' },
  { label: t('Bonaire, Saba'), value: 'BQ' },
  { label: t('Bosnia and Herzegovina'), value: 'BA' },
  { label: t('Botswana'), value: 'BW' },
  { label: t('Brazil'), value: 'BR' },
  { label: t('Brunei'), value: 'BN' },
  { label: t('Bulgaria'), value: 'BG' },
  { label: t('Burkina Faso'), value: 'BF' },
  { label: t('Burundi'), value: 'BI' },
  { label: t('Cabo Verde'), value: 'CV' },
  { label: t('Cambodia'), value: 'KH' },
  { label: t('Cameroon'), value: 'CM' },
  { label: t('Canada'), value: 'CA' },
  { label: t('Cayman Islands (the)'), value: 'KY' },
  { label: t('Central African Republic'), value: 'CF' },
  { label: t('Chad'), value: 'TD' },
  { label: t('Chile'), value: 'CL' },
  { label: t('China'), value: 'CN' },
  { label: t('Colombia'), value: 'CO' },
  { label: t('Comoros'), value: 'KM' },
  { label: t('Congo (Congo-Brazzaville)'), value: 'CG' },
  { label: t('Costa Rica'), value: 'CR' },
  { label: t('Croatia'), value: 'HR' },
  { label: t('Cuba'), value: 'CU' },
  { label: t('Curaçao'), value: 'CW' },
  { label: t('Cyprus'), value: 'CY' },
  { label: t('Czechia (Czech Republic)'), value: 'CZ' },
  { label: t('Democratic Republic of the Congo'), value: 'CD' },
  { label: t('Denmark'), value: 'DK' },
  { label: t('Djibouti'), value: 'DJ' },
  { label: t('Dominica'), value: 'DM' },
  { label: t('Dominican Republic'), value: 'DO' },
  { label: t('Ecuador'), value: 'EC' },
  { label: t('Egypt'), value: 'EG' },
  { label: t('El Salvador'), value: 'SV' },
  { label: t('Equatorial Guinea'), value: 'GQ' },
  { label: t('Eritrea'), value: 'ER' },
  { label: t('Estonia'), value: 'EE' },
  { label: t('Ethiopia'), value: 'ET' },
  { label: t('Faroe Islands'), value: 'FO' },
  { label: t('Fiji'), value: 'FJ' },
  { label: t('Finland'), value: 'FI' },
  { label: t('France'), value: 'FR' },
  { label: t('Gabon'), value: 'GA' },
  { label: t('Gambia'), value: 'GM' },
  { label: t('Georgia'), value: 'GE' },
  { label: t('Germany'), value: 'DE' },
  { label: t('Ghana'), value: 'GH' },
  { label: t('Greece'), value: 'GR' },
  { label: t('Grenada'), value: 'GD' },
  { label: t('Guadeloupe'), value: 'GP' },
  { label: t('Guam'), value: 'GU' },
  { label: t('Guatemala'), value: 'GT' },
  { label: t('Guinea'), value: 'GN' },
  { label: t('Guinea-Bissau'), value: 'GW' },
  { label: t('Guyana'), value: 'GY' },
  { label: t('Haiti'), value: 'HT' },
  { label: t('Holy See'), value: 'VA' },
  { label: t('Honduras'), value: 'HN' },
  { label: t('Hong Kong'), value: 'HK' },
  { label: t('Hungary'), value: 'HU' },
  { label: t('Iceland'), value: 'IS' },
  { label: t('India'), value: 'IN' },
  { label: t('Indonesia'), value: 'ID' },
  { label: t('Iran'), value: 'IR' },
  { label: t('Iraq'), value: 'IQ' },
  { label: t('Ireland'), value: 'IE' },
  { label: t('Israel'), value: 'IL' },
  { label: t('Italy'), value: 'IT' },
  { label: t('Ivory Coast'), value: 'CI' },
  { label: t('Jamaica'), value: 'JM' },
  { label: t('Japan'), value: 'JP' },
  { label: t('Jersey'), value: 'JE' },
  { label: t('Jordan'), value: 'JO' },
  { label: t('Kazakhstan'), value: 'KZ' },
  { label: t('Kenya'), value: 'KE' },
  { label: t('Kiribati'), value: 'KI' },
  { label: t('Kosovo'), value: 'XK' },
  { label: t('Kuwait'), value: 'KW' },
  { label: t('Kyrgyzstan'), value: 'KG' },
  { label: t('Laos'), value: 'LA' },
  { label: t('Latvia'), value: 'LV' },
  { label: t('Lebanon'), value: 'LB' },
  { label: t('Lesotho'), value: 'LS' },
  { label: t('Liberia'), value: 'LR' },
  { label: t('Libya'), value: 'LY' },
  { label: t('Liechtenstein'), value: 'LI' },
  { label: t('Lithuania'), value: 'LT' },
  { label: t('Luxembourg'), value: 'LU' },
  { label: t('Madagascar'), value: 'MG' },
  { label: t('Malawi'), value: 'MW' },
  { label: t('Malaysia'), value: 'MY' },
  { label: t('Maldives'), value: 'MV' },
  { label: t('Mali'), value: 'ML' },
  { label: t('Malta'), value: 'MT' },
  { label: t('Marshall Islands'), value: 'MH' },
  { label: t('Mauritania'), value: 'MR' },
  { label: t('Mauritius'), value: 'MU' },
  { label: t('Mexico'), value: 'MX' },
  { label: t('Micronesia'), value: 'FM' },
  { label: t('Moldova'), value: 'MD' },
  { label: t('Monaco'), value: 'MC' },
  { label: t('Mongolia'), value: 'MN' },
  { label: t('Montenegro'), value: 'ME' },
  { label: t('Morocco'), value: 'MA' },
  { label: t('Mozambique'), value: 'MZ' },
  { label: t('Myanmar (formerly Burma)'), value: 'MM' },
  { label: t('Namibia'), value: 'NA' },
  { label: t('Nauru'), value: 'NR' },
  { label: t('Nepal'), value: 'NP' },
  { label: t('Netherlands'), value: 'NL' },
  { label: t('New Caledonia'), value: 'NC' },
  { label: t('New Zealand'), value: 'NZ' },
  { label: t('Nicaragua'), value: 'NI' },
  { label: t('Niger'), value: 'NE' },
  { label: t('Nigeria'), value: 'NG' },
  { label: t('North Korea'), value: 'KP' },
  { label: t('North Macedonia'), value: 'MK' },
  { label: t('Norway'), value: 'NO' },
  { label: t('Oman'), value: 'OM' },
  { label: t('Pakistan'), value: 'PK' },
  { label: t('Palau'), value: 'PW' },
  { label: t('Palestine State'), value: 'PS' },
  { label: t('Panama'), value: 'PA' },
  { label: t('Papua New Guinea'), value: 'PG' },
  { label: t('Paraguay'), value: 'PY' },
  { label: t('Peru'), value: 'PE' },
  { label: t('Philippines'), value: 'PH' },
  { label: t('Poland'), value: 'PL' },
  { label: t('Portugal'), value: 'PT' },
  { label: t('Puerto Rico'), value: 'PR' },
  { label: t('Qatar'), value: 'QA' },
  { label: t('Romania'), value: 'RO' },
  { label: t('Russia'), value: 'RU' },
  { label: t('Rwanda'), value: 'RW' },
  { label: t('Saint Kitts and Nevis'), value: 'KN' },
  { label: t('Saint Lucia'), value: 'LC' },
  { label: t('Saint Vincent and the Grenadines'), value: 'VC' },
  { label: t('Samoa'), value: 'WS' },
  { label: t('San Marino'), value: 'SM' },
  { label: t('Sao Tome and Principe'), value: 'ST' },
  { label: t('Saudi Arabia'), value: 'SA' },
  { label: t('Senegal'), value: 'SN' },
  { label: t('Serbia'), value: 'RS' },
  { label: t('Seychelles'), value: 'SC' },
  { label: t('Sierra Leone'), value: 'SL' },
  { label: t('Singapore'), value: 'SG' },
  { label: t('Slovakia'), value: 'SK' },
  { label: t('Slovenia'), value: 'SI' },
  { label: t('Solomon Islands'), value: 'SB' },
  { label: t('Somalia'), value: 'SO' },
  { label: t('South Africa'), value: 'ZA' },
  { label: t('South Korea'), value: 'KR' },
  { label: t('South Sudan'), value: 'SS' },
  { label: t('Spain'), value: 'ES' },
  { label: t('Sri Lanka'), value: 'LK' },
  { label: t('Sudan'), value: 'SD' },
  { label: t('Suriname'), value: 'SR' },
  { label: t('Swaziland'), value: 'SZ' },
  { label: t('Sweden'), value: 'SE' },
  { label: t('Switzerland'), value: 'CH' },
  { label: t('Syria'), value: 'SY' },
  { label: t('Taiwan'), value: 'TW' },
  { label: t('Tajikistan'), value: 'TJ' },
  { label: t('Tanzania'), value: 'TZ' },
  { label: t('Timor-Leste'), value: 'TL' },
  { label: t('Thailand'), value: 'TH' },
  { label: t('Togo'), value: 'TG' },
  { label: t('Tonga'), value: 'TO' },
  { label: t('Trinidad and Tobago'), value: 'TT' },
  { label: t('Tunisia'), value: 'TN' },
  { label: t('Turkey'), value: 'TR' },
  { label: t('Turkmenistan'), value: 'TM' },
  { label: t('Tuvalu'), value: 'TV' },
  { label: t('Uganda'), value: 'UG' },
  { label: t('Ukraine'), value: 'UA' },
  { label: t('United Arab Emirates'), value: 'AE' },
  { label: t('United Kingdom'), value: 'GB' },
  { label: t('Uruguay'), value: 'UY' },
  { label: t('Uzbekistan'), value: 'UZ' },
  { label: t('Vanuatu'), value: 'VU' },
  { label: t('Venezuela'), value: 'VE' },
  { label: t('Vietnam'), value: 'VN' },
  { label: t('Yemen'), value: 'YE' },
  { label: t('Zambia'), value: 'ZM' },
  { label: t('Zimbabwe'), value: 'ZW' },
];

export const currencyOptions = () => [
  {
    label: t('US Dollar'),
    value: 'USD',
  },
  {
    label: t('Canadian Dollar'),
    value: 'CAD',
  },
  {
    label: t('Euro'),
    value: 'EUR',
  },
  {
    label: t('British Pound'),
    value: 'GBP',
  },
  {
    label: t('Australian Dollars'),
    value: 'AUD',
  },
];

export const monthOptions = () => [
  {
    value: 1,
    label: t('January'),
    days: 31,
  },
  {
    value: 2,
    label: t('February'),
    days: 28,
  },
  {
    value: 3,
    label: t('March'),
    days: 31,
  },
  {
    value: 4,
    label: t('April'),
    days: 30,
  },
  {
    value: 5,
    label: t('May'),
    days: 31,
  },
  {
    value: 6,
    label: t('June'),
    days: 30,
  },
  {
    value: 7,
    label: t('July'),
    days: 31,
  },
  {
    value: 8,
    label: t('August'),
    days: 31,
  },
  {
    value: 9,
    label: t('September'),
    days: 30,
  },
  {
    value: 10,
    label: t('October'),
    days: 31,
  },
  {
    value: 11,
    label: t('November'),
    days: 30,
  },
  {
    value: 12,
    label: t('December'),
    days: 31,
  },
];

export const weekdayOptions = () => [
  {
    value: 'SU',
    label: t('Sunday'),
  },
  {
    value: 'MO',
    label: t('Monday'),
  },
  {
    value: 'TU',
    label: t('Tuesday'),
  },
  {
    value: 'WE',
    label: t('Wednesday'),
  },
  {
    value: 'TH',
    label: t('Thursday'),
  },
  {
    value: 'FR',
    label: t('Friday'),
  },
  {
    value: 'SA',
    label: t('Saturday'),
  },
  {
    value: 'SU,MO,TU,WE,TH,FR,SA',
    label: t('Day'),
  },
  {
    value: 'MO,TU,WE,TH,FR',
    label: t('Week Day'),
  },
  {
    value: 'SA,SU',
    label: t('Weekend Day'),
  },
];

export const monthDayOptions = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 6,
    value: 6,
  },
  {
    label: 7,
    value: 7,
  },
  {
    label: 8,
    value: 8,
  },
  {
    label: 9,
    value: 9,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 11,
    value: 11,
  },
  {
    label: 12,
    value: 12,
  },
  {
    label: 13,
    value: 13,
  },
  {
    label: 14,
    value: 14,
  },
  {
    label: 15,
    value: 15,
  },
  {
    label: 16,
    value: 16,
  },
  {
    label: 17,
    value: 17,
  },
  {
    label: 18,
    value: 18,
  },
  {
    label: 19,
    value: 19,
  },
  {
    label: 20,
    value: 20,
  },
  {
    label: 21,
    value: 21,
  },
  {
    label: 22,
    value: 22,
  },
  {
    label: 23,
    value: 23,
  },
  {
    label: 24,
    value: 24,
  },
  {
    label: 25,
    value: 25,
  },
  {
    label: 26,
    value: 26,
  },
  {
    label: 27,
    value: 27,
  },
  {
    label: 28,
    value: 28,
  },
  {
    label: 29,
    value: 29,
  },
  {
    label: 30,
    value: 30,
  },
  {
    label: 31,
    value: 31,
  },
];

export const monthWeekOptions = () => [
  {
    label: t('First'),
    value: 1,
  },
  {
    label: t('Second'),
    value: 2,
  },
  {
    label: t('Third'),
    value: 3,
  },
  {
    label: t('Fourth'),
    value: 4,
  },
  {
    label: t('Last'),
    value: -1,
  },
];

export const numToReadableString = (num) => {
  if (typeof num === 'undefined') return '';
  if (num === -1) return t('Last');
  if (num % 100 >= 11 && num % 100 <= 13) {
    return num + 'th';
  }

  switch (num % 10) {
    case 1:
      return num + 'st';
    case 2:
      return num + 'nd';
    case 3:
      return num + 'rd';
  }
  return num + 'th';
};
