import { all, takeEvery, select, call } from 'redux-saga/effects';
import { selectCurrentOrgId } from 'global/accessToken/selectors';
import { requestAndSet } from 'utils/helpers';
import { REQUEST_ORG_FEATURES } from './constants';
import { setOrgFeatures } from './actions';

export function* requestOrgFeatures() {
  const orgId = yield select(selectCurrentOrgId());

  if (orgId) {
    // Don't make call in mastermode
    yield call(requestAndSet, 'listOrgFeatures', [orgId], {
      createSetterAction: ({ data }) => setOrgFeatures(data),
    });
  }
}

function* rootSaga() {
  yield all([takeEvery(REQUEST_ORG_FEATURES, requestOrgFeatures)]);
}

export default rootSaga;
