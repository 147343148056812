import React from 'react';
import { setupAppleWalletRoute as route } from 'routes/constants';

const RouteComponent = React.lazy(() => import('./SetupAppleWalletPage'));

export default {
  RouteComponent,
  path: route,
  name: 'setupAppleWallet',
};
