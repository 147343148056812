import { PAGE } from 'routes/IntegrationsPage/constants';
import React from 'react';

const RouteComponent = React.lazy(() => import('.'));

export default {
  RouteComponent,
  path: '*/:code/oauth2/callback',
  name: PAGE,
  reducers: [
    {
      key: PAGE,
      reducer: require('routes/IntegrationsPage/reducer').default,
    },
  ],
  sagas: [
    {
      key: 'integrationsPageSagas',
      saga: require('routes/IntegrationsPage/sagas').default,
    },
  ],
};
