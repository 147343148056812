import React from 'react';
import { verifyEmailRoute as route } from '../constants';

const RouteComponent = React.lazy(() => import('.'));

export default {
  RouteComponent,
  path: route,
  name: 'verifyEmail',
  sagas: [
    {
      key: 'verifyEmail',
      saga: require('./sagas').default,
    },
  ],
};
