export const identityProviderTypeCodes = {
  msazuread: 'msazuread',
  gsuite: 'gsuite',
  okta: 'okta',
  onelogin: 'onelogin',
  workday: 'workday',
  samlJit: 'samlJit',
} as const;

export const authStrategyTypeCodes = {
  oauth2: 'oauth2',
  apikey: 'apikey',
  clientAuth: 'clientAuth',
  basicAuth: 'basicAuth',
} as const;

export const identityProviderTypeIds = {
  google: 1,
  azureAd: 2,
  okta: 3,
  oneLogin: 4,
  workday: 5,
  samlJit: 6,
};

export const identityProviderSyncTypeIds = {
  directory: 1,
  scim: 2,
} as const;
