import {
  REQUEST_GET_INITIAL_DATA,
  SET_VIDEO_PROVIDER,
  SET_VIDEO_PROVIDER_CAMERAS,
  REQUEST_GET_VIDEO_PROVIDER_CAMERAS,
  REQUEST_SET_CAMERA_ENTRIES,
  REQUEST_REMOVE_VIDEO_PROVIDER,
  SET_VIDEO_PROVIDER_TYPES,
  SET_VIDEO_PROVIDERS,
} from './constants';

export function requestGetInitialData({ videoProviderId }) {
  return {
    type: REQUEST_GET_INITIAL_DATA,
    videoProviderId,
  };
}

export function setVideoProvider(videoProviderId) {
  return {
    type: SET_VIDEO_PROVIDER,
    data: videoProviderId,
  };
}

export function setVideoProviderCameras(videoProviderId, cameras) {
  return {
    type: SET_VIDEO_PROVIDER_CAMERAS,
    data: {
      videoProviderId,
      cameras,
    },
  };
}

export function requestGetVideoProviderCameras({ videoProviderId }) {
  return {
    type: REQUEST_GET_VIDEO_PROVIDER_CAMERAS,
    videoProviderId,
  };
}

// Outbound
export function requestSetCameraEntries({ videoProviderId, idExt, entryIds }) {
  return {
    type: REQUEST_SET_CAMERA_ENTRIES,
    videoProviderId,
    entryIds,
    idExt,
  };
}

export function requestRemoveVideoProvider({ videoProviderId }) {
  return {
    type: REQUEST_REMOVE_VIDEO_PROVIDER,
    videoProviderId,
  };
}

export function setVideoProviderTypes(orgId, videoProviderTypes) {
  return {
    type: SET_VIDEO_PROVIDER_TYPES,
    orgId,
    data: videoProviderTypes,
  };
}

export function setVideoProviders(orgId, videoProviders) {
  return {
    type: SET_VIDEO_PROVIDERS,
    orgId,
    data: videoProviders,
  };
}
