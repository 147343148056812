import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { SET_VIDEO_PROVIDER_TYPES } from 'routes/VideoProviderPage/constants';
import {
  groupsReducer,
  videoProvidersReducer,
  identityProviderGroupsReducer,
} from 'routes/OrgContainer/reducer';
import { createNamedWrapperReducer } from 'utils/reducers';
import formReducer from 'global/formWrapper/reducer';
import {
  PAGE,
  SET_IDENTITY_PROVIDER_TYPES,
  SET_IDENTITY_PROVIDERS,
  OAUTH_SET_AUTH_URL,
  SET_GROUP_RELATIONS,
  SET_IDENTITY_PROVIDER_GROUPS /* SET_AVAILABLE_INTEGRATIONS, SET_INTEGRATION_AUTH_URL, SET_ACTIVE_INTEGRATIONS, SET_INITIAL_GROUP_MAPPING */,
} from './constants';

const initialIdentityProviderTypesState = fromJS([]);
function identityProviderTypesReducer(
  state = initialIdentityProviderTypesState,
  action,
) {
  switch (action.type) {
    case SET_IDENTITY_PROVIDER_TYPES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialIdentityProvidersState = fromJS([]);
function identityProvidersReducer(
  state = initialIdentityProvidersState,
  action,
) {
  switch (action.type) {
    case SET_IDENTITY_PROVIDERS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialOptionsState = fromJS({
  oAuthRedirectUrl: null,
});
function identityProviderOptionsReducer(state = initialOptionsState, action) {
  switch (action.type) {
    case OAUTH_SET_AUTH_URL:
      return fromJS({
        oAuthRedirectUrl: action.url,
      });
    default:
      return state;
  }
}

const initialGroupRelationsState = fromJS({});
function globalGroupRelationsReducer(
  state = initialGroupRelationsState,
  action,
) {
  switch (action.type) {
    case SET_GROUP_RELATIONS:
      return state.setIn([action.code], fromJS(action.data));
    default:
      return state;
  }
}

const initialIdentityProviderGroupsState = fromJS({});
function identityProviderGroupsReducer2(
  state = initialIdentityProviderGroupsState,
  action,
) {
  switch (action.type) {
    case SET_IDENTITY_PROVIDER_GROUPS:
      return state.setIn([action.code], fromJS(action.data));
    default:
      return state;
  }
}

const initialVideoProviderTypesState = fromJS([]);
function videoProviderTypesReducer(
  state = initialVideoProviderTypesState,
  action,
) {
  switch (action.type) {
    case SET_VIDEO_PROVIDER_TYPES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const reducers = {
  identityProviderTypes: identityProviderTypesReducer,
  identityProviders: identityProvidersReducer,
  groups: createNamedWrapperReducer(groupsReducer, PAGE),
  form: createNamedWrapperReducer(formReducer, PAGE),
  groupRelations: globalGroupRelationsReducer,
  options: identityProviderOptionsReducer,
  identityProviderGroups: identityProviderGroupsReducer2,
  videoProviders: createNamedWrapperReducer(videoProvidersReducer, PAGE),
  identityProviderGroupsSearchResults: createNamedWrapperReducer(
    identityProviderGroupsReducer,
    PAGE,
  ),
  videoProviderTypes: videoProviderTypesReducer,
};

export default combineReducers(reducers);
