import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { heliumReceive } from 'utils/helpers';

const defaultSupportedLanguages = [
  { code: 'en', name: 'English', nativeName: 'English' },
];

export function useSupportedLanguages() {
  const { t } = useTranslation();
  const [supportedLanguages, setSupportedLanguages] = useState(
    defaultSupportedLanguages,
  );

  useEffect(() => {
    const fetchSupportedLanguages = async () => {
      try {
        const { data } = await heliumReceive(
          'globalListSupportedLanguages',
          [],
        );
        if (data.supportedLanguages) {
          setSupportedLanguages(
            data.supportedLanguages.map((language) => ({
              code: language.code,
              name: t(language.name),
              nativeName: language.nativeName,
            })),
          );
        }
      } catch {
        setSupportedLanguages(defaultSupportedLanguages);
      }
    };

    fetchSupportedLanguages();
  }, [t]);

  return supportedLanguages;
}
