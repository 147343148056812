import React from 'react';
import { setupMobileRoute as route } from 'routes/constants';

const RouteComponent = React.lazy(() => import('./SetupMobilePage'));

export default {
  RouteComponent,
  path: route,
  name: 'setupMobile',
};
