import { PAGE } from 'routes/IntegrationsPage/constants';
import React from 'react';
import { getRawMenuItems, getItemConfig } from 'config/utils';
import { lockdownEditConfigRoute as route } from '../constants';

const RouteComponent = React.lazy(() => import('.'));
export const scope = getItemConfig(getRawMenuItems(), route).scope;

export default {
  RouteComponent,
  path: '/:code/oidc/callback',
  name: PAGE,
  reducers: [
    {
      key: PAGE,
      reducer: require('routes/IntegrationsPage/reducer').default,
    },
  ],
  scope,
  sagas: [
    {
      key: 'integrationsPageSagas',
      saga: require('routes/IntegrationsPage/sagas').default,
    },
  ],
};
