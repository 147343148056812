import React from 'react';
import { maintenanceRoute as route } from '../constants';

const RouteComponent = React.lazy(() => import('.'));

export default {
  RouteComponent,
  path: route,
  name: 'maintenancePage',
};
