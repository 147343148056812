import { all, takeEvery, select, put, call } from 'redux-saga/effects';
import { listCredentials } from 'heliumApi';
import {
  selectCurrentOrgId,
  selectCurrentUserId,
} from 'global/accessToken/selectors';
import { selectFirstValidCloudKeyId } from 'global/cloudKey/selectors';
import { setAlert } from 'routes/AppContainer/actions';
import { setUiLockStatus } from 'routes/CustomDashboardsPage/components/EntryLogWidget/EntryTablePage/actions';
import { UI_LOCK_STATUS } from 'routes/CustomDashboardsPage/components/EntryLogWidget/EntryTablePage/constants';
import { sendToHelium } from 'utils/helpers';
import { setCloudKeys } from './actions';
import {
  REQUEST_LIST_CLOUD_KEYS,
  REQUEST_UNLOCK_WITH_CLOUD_KEY,
} from './constants';

export function* requestListCloudKeyCredentials() {
  const currentOrgId = yield select(selectCurrentOrgId());
  const currentUserId = yield select(selectCurrentUserId());
  if (currentOrgId && currentUserId) {
    const cloudKeyCredentials = yield call(
      listCredentials,
      currentOrgId,
      currentUserId,
      { filter: 'credentialType.modelName:(cloudKey)' },
    );
    if (cloudKeyCredentials.err) {
      yield put(setAlert('error', cloudKeyCredentials.err.message));
    } else {
      yield put(setCloudKeys(cloudKeyCredentials.data.data));
    }
  }
}

export function* requestUnlockWithCloudKey(action) {
  yield put(setUiLockStatus(action.entryId, UI_LOCK_STATUS.UI_LOCK_SENDING));
  const currentOrgId = yield select(selectCurrentOrgId());
  const currentUserId = yield select(selectCurrentUserId());
  const credentialId = yield select(selectFirstValidCloudKeyId());
  const { errorMessage } = yield call(
    sendToHelium,
    'cloudKeyEntryUnlock',
    [currentOrgId, currentUserId, credentialId],
    { entryId: `${action.entryId}`, description: 'Unlock via Dashboard' },
    { successMessage: 'Your unlock request was sent successfully!' },
  );
  yield put(
    setUiLockStatus(
      action.entryId,
      errorMessage
        ? UI_LOCK_STATUS.UI_LOCK_FAILURE
        : UI_LOCK_STATUS.UI_LOCK_SUCCESS,
    ),
  );
}

function* rootSaga() {
  yield all([
    takeEvery(REQUEST_LIST_CLOUD_KEYS, requestListCloudKeyCredentials),
    takeEvery(REQUEST_UNLOCK_WITH_CLOUD_KEY, requestUnlockWithCloudKey),
  ]);
}

export default rootSaga;
