import { REQUEST_ALL_FEATURES, SET_ALL_FEATURES } from './constants';

export function requestAllFeatures() {
  return {
    type: REQUEST_ALL_FEATURES,
  };
}

export function setAllFeatures(features) {
  return {
    type: SET_ALL_FEATURES,
    features,
  };
}
