import { call, select } from 'redux-saga/effects';
import { selectCurrentIdentityLanguage } from 'routes/AppContainer/selectors';

import { request } from 'utils/request';

function generateQueryString(queryParams) {
  let queryString = '?';
  if (queryParams) {
    Object.keys(queryParams).forEach((key) => {
      const value = encodeURIComponent(queryParams[key]);
      const pair = `${key}=${value}&`;
      queryString += pair;
    });
  }

  queryString = queryString.slice(0, -1); // removes the last &, or removes ? if no params

  return queryString;
}

function* _PUT(urlpath, payload) {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const options = {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      'Accept-Language': currentIdentityLanguage,
    },
  };

  return yield call(request, urlpath, options);
}

function* _PATCH(urlpath, payload) {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const options = {
    method: 'PATCH',
    body: JSON.stringify(payload),
    headers: {
      'Accept-Language': currentIdentityLanguage,
    },
  };

  return yield call(request, urlpath, options);
}

function* _POST(urlpath, payload, queryParams) {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const formattedQueryParams = generateQueryString(queryParams);
  const pathAndParams = `${urlpath}${formattedQueryParams}`;
  const options = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Accept-Language': currentIdentityLanguage,
    },
  };

  return yield call(request, pathAndParams, options);
}

function* _GET(urlpath, queryParams) {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const formattedQueryParams = generateQueryString(queryParams);
  const pathAndParams = `${urlpath}${formattedQueryParams}`;
  const options = {
    method: 'GET',
    headers: {
      'Accept-Language': currentIdentityLanguage,
    },
  };

  return yield call(request, pathAndParams, options);
}

function* _DELETE(urlpath) {
  const currentIdentityLanguage = yield select(selectCurrentIdentityLanguage());
  const options = {
    method: 'DELETE',
    headers: {
      'Accept-Language': currentIdentityLanguage,
    },
  };

  return yield call(request, urlpath, options);
}

/// ////////

/*
 * Auth
 */

export function login(payload) {
  const data = _POST('/auth/login', payload);
  this.token = data.token;
  return data;
}

export function logout() {
  _POST('/auth/logout', {});
  this.token = null;
}

export function refreshLogin() {
  const response = _POST('/auth/accessTokens/refresh');
  this.token = response.token;
  return response;
}

export function describeAccessToken(token) {
  return _GET(`/auth/accessTokens/${token}`);
}

export function validateAccessToken(token) {
  return _POST(`/auth/accessTokens/${token}/validate`);
}

export function resetPassword(payload) {
  return _POST('/auth/resetPassword', payload);
}

export function setupMobile(payload) {
  return _POST('/auth/setupMobile', payload);
}

export function loginAll(payload) {
  return _POST('/auth/loginAll', payload);
}

export function getOidcAuthUrl(payload) {
  return _POST('/auth/oidc/authorize', payload);
}

/*
 * Documentation
 */

export function describeSwagger(queryParams) {
  return _GET('/swagger.json', queryParams);
}

export function getAwsCredentials() {
  return _GET('/awsCredentials');
}

/*
 * Identities
 */

export function createIdentity(payload) {
  return _POST('/identities', payload);
}

export function listIdentities(queryParams) {
  return _GET('/identities', queryParams);
}

export function describeIdentity(identityId) {
  return _GET(`/identities/${identityId}`);
}

export function updateIdentity(identityId, payload) {
  return _PATCH(`/identities/${identityId}`, payload);
}

export function deleteIdentity(identityId) {
  return _DELETE(`/identities/${identityId}`);
}

export function listIdentityUsers(identityId, queryParams) {
  return _GET(`/identities/${identityId}/users`, queryParams);
}

export function listIdentityIdentityRoles(identityId, queryParams) {
  return _GET(`/identities/${identityId}/roles`, queryParams);
}

export function setIdentityIdentityRoleIds(identityId, payload) {
  return _PUT(`/identities/${identityId}/roleIds`, payload);
}

export function identitySendFeedback(identityId, payload) {
  return _POST(`/identities/${identityId}/sendFeedback`, payload);
}

/*
 * Identities / MFA
 */

export function createMfaCredential(identityId, payload) {
  return _POST(`/identities/${identityId}/mfaCredentials`, payload);
}

export function listMfaCredentials(identityId, queryParams) {
  return _GET(`/identities/${identityId}/mfaCredentials`, queryParams);
}

export function describeMfaCredential(identityId, mfaCredentialId) {
  return _GET(`/identities/${identityId}/mfaCredentials/${mfaCredentialId}`);
}

export function activateMfaCredential(identityId, mfaCredentialId, payload) {
  return _POST(
    `/identities/${identityId}/mfaCredentials/${mfaCredentialId}/activate`,
    payload,
  );
}

export function updateMfaCredential(identityId, mfaCredentialId, payload) {
  return _PATCH(
    `/identities/${identityId}/mfaCredentials/${mfaCredentialId}`,
    payload,
  );
}

export function deleteMfaCredential(identityId, mfaCredentialId) {
  return _DELETE(`/identities/${identityId}/mfaCredentials/${mfaCredentialId}`);
}

/*
 * Terms of Service
 */

export function describeLatestTermsVersion() {
  return _GET('/termsVersion/latest');
}

/*
 * Globals
 */

export function globalListAcus(queryParams = {}) {
  // add prefilter to hide virtualAcus on all list queries
  return _GET('/acus', {
    ...queryParams,
    preFilter: 'isVirtual:(false)',
  });
}

/*
 * Orgs
 */

/*
 * Lockdown plans
 */

// export function createHeliumCoder(payload) {
//   return _POST('/heliumCoders', payload)
// }

export function listLockdownPlans(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/lockdownPlans`, queryParams);
}

export function describeLockdownPlan(orgId, lockdownPlanId, queryParams) {
  return _GET(`/orgs/${orgId}/lockdownPlans/${lockdownPlanId}`, queryParams);
}

export function createLockdownPlan(orgId, payload) {
  return _POST(`/orgs/${orgId}/lockdownPlans`, payload);
}

export function updateLockdownPlan(orgId, lockdownPlanId, payload) {
  return _PATCH(`/orgs/${orgId}/lockdownPlans/${lockdownPlanId}`, payload);
}

export function updateLockdownPlanZoneStates(orgId, lockdownPlanId, payload) {
  return _PUT(
    `/orgs/${orgId}/lockdownPlans/${lockdownPlanId}/zoneStates`,
    payload,
  );
}

export function updateLockdownPlanPermissions(orgId, lockdownPlanId, payload) {
  return _PUT(
    `/orgs/${orgId}/lockdownPlans/${lockdownPlanId}/permissions`,
    payload,
  );
}

export function deleteLockdownPlan(orgId, lockdownPlanId) {
  return _DELETE(`/orgs/${orgId}/lockdownPlans/${lockdownPlanId}`);
}

// /

export function recalculateOrgStatuses() {
  return _POST('/orgs/recalculateStatus');
}

export function listOrgs(queryParams) {
  return _GET('/orgs', queryParams);
}

export function signupOrg(payload) {
  return _POST('/orgs/signup', payload);
}

export function updateOrg(orgId, payload) {
  return _PATCH(`/orgs/${orgId}`, payload);
}

export function setOrgStatus(orgId, payload) {
  return _PUT(`/orgs/${orgId}/status`, payload);
}

export function deleteOrg(orgId) {
  return _DELETE(`/orgs/${orgId}`);
}

export function describeOrg(orgId) {
  return _GET(`/orgs/${orgId}`);
}

export function describeOrgLevelDefault(orgId) {
  return _GET(`/orgs/${orgId}/orgLevelDefault`);
}

export function listOrgPackagePlans(orgId, payload) {
  // DEPRECATED, use 'listOrgNetsuitePackagePlans'
  return _GET(`/orgs/${orgId}/packagePlans`, payload);
}

export function listOrgNetsuitePackagePlans(orgId, payload) {
  return _GET(`/orgs/${orgId}/netsuitePlans`, payload);
}

export function getHardwarePricingCsv(orgId, newVersion) {
  if (newVersion) {
    return _GET(`/orgs/${orgId}/hardwarePricingCsv?newVersion=true`);
  }

  return _GET(`/orgs/${orgId}/hardwarePricingCsv`);
}

export function getSoftwarePricingCsv(orgId, newVersion) {
  if (newVersion) {
    return _GET(`/orgs/${orgId}/softwarePricingCsv?newVersion=true`);
  }

  return _GET(`/orgs/${orgId}/softwarePricingCsv`);
}

export function describeOrgPackagePlan(orgId, packagePlanId) {
  return _GET(`/orgs/${orgId}/packagePlans/${packagePlanId}`);
}

export function syncOrgPackagePlans(orgId, payload) {
  return _POST(`/orgs/${orgId}/packagePlans/sync`, payload);
}

export function listOrgPackagePlansSelected(orgId, payload) {
  return this._GET(`/orgs/${orgId}/orgPackagePlans`, payload);
}

export function setOrgPackagePlansSelectedPackagePlanIds(orgId, payload) {
  return this._PUT(`/orgs/${orgId}/orgPackagePlans/packagePlanIds`, payload);
}

export function listTermsAgreements(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/termsAgreements`, queryParams);
}

export function createTermsAgreement(orgId, payload) {
  return _POST(`/orgs/${orgId}/termsAgreements`, payload);
}

export function getOrgCaChain(orgId) {
  return _GET(`/orgs/${orgId}/caChain`);
}

export function getMqttCredentials(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/mqttCredentials`, queryParams);
}

export function createSubscription(orgId, payload) {
  return _POST(`/orgs/${orgId}/subscriptions`, payload);
}

export function deleteSubscription(orgId, subscriptionId) {
  return _DELETE(`/orgs/${orgId}/subscriptions/${subscriptionId}`);
}

export function listSubscriptions(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/subscriptions`, queryParams);
}

export function describeSubscription(orgId, subscriptionId) {
  return _GET(`/orgs/${orgId}/subscriptions/${subscriptionId}`);
}

export function updateSubscription(orgId, subscriptionId, payload) {
  return _PATCH(`/orgs/${orgId}/subscriptions/${subscriptionId}`, payload);
}

export function createHookAction(orgId, payload) {
  return _POST(`/orgs/${orgId}/hookActions`, payload);
}

export function deleteHookAction(orgId, hookActionId) {
  return _DELETE(`/orgs/${orgId}/hookActions/${hookActionId}`);
}

export function listHookActions(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/hookActions`, queryParams);
}

export function describeHookAction(orgId, hookActionId) {
  return _GET(`/orgs/${orgId}/hookActions/${hookActionId}`);
}

export function updateHookAction(orgId, hookActionId, payload) {
  return _PATCH(`/orgs/${orgId}/hookActions/${hookActionId}`, payload);
}

export function listHookEvents(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/hookEvents`, queryParams);
}

export function describeHookEvent(orgId, hookEventId) {
  return _GET(`/orgs/${orgId}/hookEvents/${hookEventId}`);
}

export function createUser(orgId, payload) {
  return _POST(`/orgs/${orgId}/users`, payload);
}

export function deleteUser(orgId, userId) {
  return _DELETE(`/orgs/${orgId}/users/${userId}`);
}

export function listUsers(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/users`, queryParams);
}

export function describeUser(orgId, userId) {
  return _GET(`/orgs/${orgId}/users/${userId}`);
}

export function updateUser(orgId, userId, payload) {
  return _PATCH(`/orgs/${orgId}/users/${userId}`, payload);
}

export function createUserPicture(orgId, userId, payload) {
  return _POST(`/orgs/${orgId}/users/${userId}/userPictures`, payload);
}

export function deleteUserPicture(orgId, userId, userPictureId) {
  return _DELETE(
    `/orgs/${orgId}/users/${userId}/userPictures/${userPictureId}`,
  );
}

export function listUserPictures(orgId, userId, queryParams) {
  return _GET(`/orgs/${orgId}/users/${userId}/userPictures`, queryParams);
}

export function describeUserPicture(orgId, userId, userPictureId) {
  return _GET(`/orgs/${orgId}/users/${userId}/userPictures/${userPictureId}`);
}

export function updateUserPicture(orgId, userId, userPictureId, payload) {
  return _PATCH(
    `/orgs/${orgId}/users/${userId}/userPictures/${userPictureId}`,
    payload,
  );
}

export function listUserRoles(orgId, userId, queryParams) {
  return _GET(`/orgs/${orgId}/users/${userId}/roles`, queryParams);
}

export function listUserGroups(orgId, userId, queryParams) {
  return _GET(`/orgs/${orgId}/users/${userId}/groups`, queryParams);
}

export function listUserSites(orgId, userId, queryParams) {
  return _GET(`/orgs/${orgId}/users/${userId}/sites`, queryParams);
}

export function listUserEntries(orgId, userId, queryParams) {
  return _GET(`/orgs/${orgId}/users/${userId}/entries`, queryParams);
}

export function listUserZones(orgId, userId, queryParams) {
  return _GET(`/orgs/${orgId}/users/${userId}/zones`, queryParams);
}

export function listUserZoneUsers(orgId, userId, queryParams) {
  return _GET(`/orgs/${orgId}/users/${userId}/zoneUsers`, queryParams);
}

export function listUserAcus(orgId, userId, queryParams) {
  return _GET(`/orgs/${orgId}/users/${userId}/acus`, {
    ...queryParams,
    preFilter: 'isVirtual:(false)',
  });
}

export function setUserGroupIds(orgId, userId, payload) {
  return _PUT(`/orgs/${orgId}/users/${userId}/groupIds`, payload);
}

export function setUserZoneIds(orgId, userId, payload) {
  return _PUT(`/orgs/${orgId}/users/${userId}/zoneIds`, payload);
}

export function setUserZoneUsers(orgId, userId, payload) {
  return _PUT(`/orgs/${orgId}/users/${userId}/zoneUsers`, payload);
}

export function setUserRoleIds(orgId, userId, payload) {
  return _PUT(`/orgs/${orgId}/users/${userId}/roleIds`, payload);
}

export function userSendFeedback(orgId, userId, payload) {
  return _POST(`/orgs/${orgId}/users/${userId}/sendFeedback`, payload);
}

export function setUserStatus(orgId, userId, payload) {
  return _PUT(`/orgs/${orgId}/users/${userId}/status`, payload);
}

export function verifyEmail(orgId, userId) {
  return _POST(`/orgs/${orgId}/users/${userId}/verifyEmail`, {});
}

export function getUserActivity(orgId, userId, queryParams) {
  return _GET(`/orgs/${orgId}/users/${userId}/reports/activity`, queryParams);
}

export function createAcu(orgId, payload) {
  return _POST(`/orgs/${orgId}/acus`, payload);
}

export function generateSetupAcuToken(orgId, acuId, payload) {
  return _POST(`/orgs/${orgId}/acus/${acuId}/generateSetupAcuToken`, payload);
}

export function generateAcuSsmActivation(orgId, acuId, payload) {
  return _POST(`/orgs/${orgId}/acus/${acuId}/generateSsmActivation`, payload);
}

export function deleteAcu(orgId, acuId) {
  return _DELETE(`/orgs/${orgId}/acus/${acuId}`);
}

export function listAcus(orgId, queryParams) {
  // add preFilter to hide virtualAcus
  return _GET(`/orgs/${orgId}/acus`, {
    ...queryParams,
    preFilter: 'isVirtual:(false)',
  });
}

export function describeAcu(orgId, acuId) {
  return _GET(`/orgs/${orgId}/acus/${acuId}`);
}

export function updateAcu(orgId, acuId, payload) {
  return _PATCH(`/orgs/${orgId}/acus/${acuId}`, payload);
}

export function setAcuStatus(orgId, acuId, payload) {
  return _PUT(`/orgs/${orgId}/acus/${acuId}/status`, payload);
}

export function assignAcu(orgId, acuId, payload) {
  return _POST(`/orgs/${orgId}/acus/${acuId}/assign`, payload);
}

export function unassignAcu(orgId, acuId, payload) {
  return _POST(`/orgs/${orgId}/acus/${acuId}/unassign`, payload);
}

export function getAcuConfig(orgId, acuId) {
  return _GET(`/orgs/${orgId}/acus/${acuId}/config`);
}

export function getAuditLogs(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/reports/auditLogs`, queryParams);
}

export function remoteAcuIdentify(orgId, acuId) {
  return _POST(`/orgs/${orgId}/acus/${acuId}/identify`);
}

export function remoteAcuRefreshShadow(orgId, acuId) {
  return _POST(`/orgs/${orgId}/acus/${acuId}/refreshShadow`);
}

export function remoteAcuRestartApi(orgId, acuId) {
  return _POST(`/orgs/${orgId}/acus/${acuId}/restartApi`);
}

export function remoteAcuRestartCloud(orgId, acuId) {
  return _POST(`/orgs/${orgId}/acus/${acuId}/restartCloud`);
}

export function remoteAcuRestartHardware(orgId, acuId) {
  return _POST(`/orgs/${orgId}/acus/${acuId}/restartHardware`);
}

export function remoteAcuReboot(orgId, acuId) {
  return _POST(`/orgs/${orgId}/acus/${acuId}/reboot`);
}

export function listAcuExpansionBoards(orgId, acuId) {
  return _GET(`/orgs/${orgId}/acus/${acuId}/expansionBoards`);
}

export function createAcuExpansionBoard(orgId, acuId, payload) {
  return _POST(`/orgs/${orgId}/acus/${acuId}/expansionBoards`, payload);
}

export function describeAcuExpansionBoard(orgId, acuId, expansionBoardId) {
  return _GET(
    `/orgs/${orgId}/acus/${acuId}/expansionBoards/${expansionBoardId}`,
  );
}

export function deleteAcuExpansionBoard(orgId, acuId, expansionBoardId) {
  return _DELETE(
    `/orgs/${orgId}/acus/${acuId}/expansionBoards/${expansionBoardId}`,
  );
}

export function createGroup(orgId, payload) {
  return _POST(`/orgs/${orgId}/groups`, payload);
}

export function deleteGroup(orgId, groupId) {
  return _DELETE(`/orgs/${orgId}/groups/${groupId}`);
}

export function listGroups(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/groups`, queryParams);
}

export function describeGroup(orgId, groupId) {
  return _GET(`/orgs/${orgId}/groups/${groupId}`);
}

export function updateGroup(orgId, groupId, payload) {
  return _PATCH(`/orgs/${orgId}/groups/${groupId}`, payload);
}

export function setUserToGroup(orgId, groupId, userId) {
  return _PUT(`/orgs/${orgId}/groups/${groupId}/users/${userId}`);
}

export function removeUserFromGroup(orgId, groupId, userId) {
  return _DELETE(`/orgs/${orgId}/groups/${groupId}/users/${userId}`);
}

export function listGroupUsers(orgId, groupId, queryParams) {
  return _GET(`/orgs/${orgId}/groups/${groupId}/users`, queryParams);
}

export function listGroupZones(orgId, groupId, queryParams) {
  return _GET(`/orgs/${orgId}/groups/${groupId}/zones`, queryParams);
}

export function listGroupZoneGroups(orgId, groupId, queryParams) {
  return _GET(`/orgs/${orgId}/groups/${groupId}/zoneGroups`, queryParams);
}

export function setGroupUserIds(orgId, groupId, payload) {
  return _PUT(`/orgs/${orgId}/groups/${groupId}/userIds`, payload);
}

export function setGroupZoneIds(orgId, groupId, payload) {
  return _PUT(`/orgs/${orgId}/groups/${groupId}/zoneIds`, payload);
}

export function setGroupZoneGroups(orgId, groupId, payload) {
  return _PUT(`/orgs/${orgId}/groups/${groupId}/zoneGroups`, payload);
}

export function createSite(orgId, payload) {
  return _POST(`/orgs/${orgId}/sites`, payload);
}

export function deleteSite(orgId, siteId) {
  return _DELETE(`/orgs/${orgId}/sites/${siteId}`);
}

export function listSharedUsers(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/sharedUsers`, queryParams);
}

export function listSites(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/sites`, queryParams);
}

export function describeSharedUser(orgId, userId, queryParams) {
  return _GET(`/orgs/${orgId}/sharedUsers/${userId}`, queryParams);
}

export function describeSite(orgId, siteId) {
  return _GET(`/orgs/${orgId}/sites/${siteId}`);
}

export function updateSite(orgId, siteId, payload) {
  return _PATCH(`/orgs/${orgId}/sites/${siteId}`, payload);
}

export function setUserToSite(orgId, siteId, userId) {
  return _PUT(`/orgs/${orgId}/sites/${siteId}/users/${userId}`);
}

export function createZone(orgId, payload) {
  return _POST(`/orgs/${orgId}/zones`, payload);
}

export function deleteZone(orgId, zoneId) {
  return _DELETE(`/orgs/${orgId}/zones/${zoneId}`);
}

export function listZones(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/zones`, queryParams);
}

export function describeZone(orgId, zoneId) {
  return _GET(`/orgs/${orgId}/zones/${zoneId}`);
}

export function updateZone(orgId, zoneId, payload) {
  return _PATCH(`/orgs/${orgId}/zones/${zoneId}`, payload);
}

export function listZoneShares(orgId, zoneId) {
  return _GET(`/orgs/${orgId}/zones/${zoneId}/shares`);
}

export function setZoneShareOrgIds(orgId, zoneId, payload) {
  return _PUT(`/orgs/${orgId}/zones/${zoneId}/shareOrgIds`, payload);
}

export function shareZone(orgId, zoneId, payload) {
  return _POST(`/orgs/${orgId}/zones/${zoneId}/share`, payload);
}

export function unshareZone(orgId, zoneId, payload) {
  return _POST(`/orgs/${orgId}/zones/${zoneId}/unshare`, payload);
}

export function relinquishZoneShare(orgId, zoneId) {
  return _POST(`/orgs/${orgId}/zones/${zoneId}/relinquishShare`);
}

export function setUserToZone(orgId, zoneId, userId, payload) {
  return _PUT(`/orgs/${orgId}/zones/${zoneId}/users/${userId}`, payload);
}

export function removeUserFromZone(orgId, zoneId, userId) {
  return _DELETE(`/orgs/${orgId}/zones/${zoneId}/users/${userId}`);
}

export function listZoneUsers(orgId, zoneId, queryParams) {
  return _GET(`/orgs/${orgId}/zones/${zoneId}/users`, queryParams);
}

export function listZoneZoneUsers(orgId, zoneId, queryParams) {
  return _GET(`/orgs/${orgId}/zones/${zoneId}/zoneUsers`, queryParams);
}

export function setZoneUserIds(orgId, zoneId, payload) {
  return _PUT(`/orgs/${orgId}/zones/${zoneId}/userIds`, payload);
}

export function setZoneZoneUsers(orgId, zoneId, payload) {
  return _PUT(`/orgs/${orgId}/zones/${zoneId}/zoneUsers`, payload);
}

export function setZoneGroupIds(orgId, zoneId, payload) {
  return _PUT(`/orgs/${orgId}/zones/${zoneId}/groupIds`, payload);
}

export function setZoneZoneGroups(orgId, zoneId, payload) {
  return _PUT(`/orgs/${orgId}/zones/${zoneId}/zoneGroups`, payload);
}

export function setGroupToZone(orgId, zoneId, groupId, payload) {
  return _PUT(`/orgs/${orgId}/zones/${zoneId}/groups/${groupId}`, payload);
}

export function removeGroupFromZone(orgId, zoneId, groupId) {
  return _DELETE(`/orgs/${orgId}/zones/${zoneId}/groups/${groupId}`);
}

export function listZoneGroups(orgId, zoneId, queryParams) {
  return _GET(`/orgs/${orgId}/zones/${zoneId}/groups`, queryParams);
}

export function listZoneZoneGroups(orgId, zoneId, queryParams) {
  return _GET(`/orgs/${orgId}/zones/${zoneId}/zoneGroups`, queryParams);
}

export function createZoneApbArea(orgId, zoneId, payload) {
  return _POST(`/orgs/${orgId}/zones/${zoneId}/apbAreas`, payload);
}

export function deleteZoneApbArea(orgId, zoneId, apbAreaId) {
  return _DELETE(`/orgs/${orgId}/zones/${zoneId}/apbAreas/${apbAreaId}`);
}

export function listZoneApbAreas(orgId, zoneId, queryParams) {
  return _GET(`/orgs/${orgId}/zones/${zoneId}/apbAreas`, queryParams);
}

export function describeZoneApbArea(orgId, zoneId, apbAreaId) {
  return _GET(`/orgs/${orgId}/zones/${zoneId}/apbAreas/${apbAreaId}`);
}

export function updateZoneApbArea(orgId, zoneId, apbAreaId, payload) {
  return _PATCH(
    `/orgs/${orgId}/zones/${zoneId}/apbAreas/${apbAreaId}`,
    payload,
  );
}

export function resetApb(orgId, payload) {
  return _POST(`/orgs/${orgId}/zones/apbReset`, payload);
}

export function createEntry(orgId, payload) {
  return _POST(`/orgs/${orgId}/entries`, payload);
}

export function listEntries(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/entries`, queryParams);
}

export function describeEntry(orgId, entryId) {
  return _GET(`/orgs/${orgId}/entries/${entryId}`);
}

export function updateEntry(orgId, entryId, payload) {
  return _PATCH(`/orgs/${orgId}/entries/${entryId}`, payload);
}

export function deleteEntry(orgId, entryId) {
  return _DELETE(`/orgs/${orgId}/entries/${entryId}`);
}

export function listEntryUsers(orgId, entryId, queryParams) {
  return _GET(`/orgs/${orgId}/entries/${entryId}/users`, queryParams);
}

export function listEntryUserSchedules(orgId, entryId, queryParams) {
  return _GET(`/orgs/${orgId}/entries/${entryId}/userSchedules`, queryParams);
}

export function listEntryReaders(orgId, entryId, queryParams) {
  return _GET(`/orgs/${orgId}/entries/${entryId}/readers`, queryParams);
}

export function setReaderToEntry(orgId, entryId, readerId) {
  return _PUT(`/orgs/${orgId}/entries/${entryId}/readers/${readerId}`);
}

export function removeReaderFromEntry(orgId, entryId, readerId) {
  return _DELETE(`/orgs/${orgId}/entries/${entryId}/readers/${readerId}`);
}

export function listEntryRelays(orgId, entryId, queryParams) {
  return _GET(`/orgs/${orgId}/entries/${entryId}/relays`, queryParams);
}

export function setRelayToEntry(orgId, entryId, relayId) {
  return _PUT(`/orgs/${orgId}/entries/${entryId}/relays/${relayId}`);
}

export function removeRelayFromEntry(orgId, entryId, relayId) {
  return _DELETE(`/orgs/${orgId}/entries/${entryId}/relays/${relayId}`);
}

export function listEntryContactSensors(orgId, entryId, queryParams) {
  return _GET(`/orgs/${orgId}/entries/${entryId}/contactSensors`, queryParams);
}

export function setContactSensorToEntry(orgId, entryId, contactSensorId) {
  return _PUT(
    `/orgs/${orgId}/entries/${entryId}/contactSensors/${contactSensorId}`,
  );
}

export function removeContactSensorFromEntry(orgId, entryId, contactSensorId) {
  return _DELETE(
    `/orgs/${orgId}/entries/${entryId}/contactSensors/${contactSensorId}`,
  );
}

export function listEntryRexs(orgId, entryId, queryParams) {
  return _GET(`/orgs/${orgId}/entries/${entryId}/rexs`, queryParams);
}

export function setRexToEntry(orgId, entryId, rexId) {
  return _PUT(`/orgs/${orgId}/entries/${entryId}/rexs/${rexId}`);
}

export function removeRexFromEntry(orgId, entryId, rexId) {
  return _DELETE(`/orgs/${orgId}/entries/${entryId}/rexs/${rexId}`);
}

export function listEntryWiegands(orgId, entryId, queryParams) {
  return _GET(`/orgs/${orgId}/entries/${entryId}/wiegands`, queryParams);
}

export function setWiegandToEntry(orgId, entryId, wiegandId) {
  return _PUT(`/orgs/${orgId}/entries/${entryId}/wiegands/${wiegandId}`);
}

export function removeWiegandFromEntry(orgId, entryId, wiegandId) {
  return _DELETE(`/orgs/${orgId}/entries/${entryId}/wiegands/${wiegandId}`);
}

export function listEntryControls(orgId, entryId, queryParams) {
  return _GET(`/orgs/${orgId}/entries/${entryId}/entryControls`, queryParams);
}

export function remoteEntryIdentify(orgId, entryId) {
  return _POST(`/orgs/${orgId}/entries/${entryId}/identify`);
}

export function listAllAcuEntriesUsers(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/acus/entriesUsers`, queryParams);
}

export function getEnterpriseVpnBootstrapConfig(orgId) {
  return _GET(`/orgs/${orgId}/acus/enterpriseVpnBootstrapConfig`);
}

export function listAcuEntriesPermissions(orgId, acuId, queryParams) {
  return _GET(`/orgs/${orgId}/acus/${acuId}/entriesPermissions`, queryParams);
}

export function createAcuPort(orgId, acuId, payload) {
  return _POST(`/orgs/${orgId}/acus/${acuId}/acuPorts`, payload);
}

export function listAcuPorts(orgId, acuId, queryParams) {
  return _GET(`/orgs/${orgId}/acus/${acuId}/acuPorts`, queryParams);
}

export function describeAcuPort(orgId, acuId, acuPortId) {
  return _GET(`/orgs/${orgId}/acus/${acuId}/acuPorts/${acuPortId}`);
}

export function updateAcuPort(orgId, acuId, acuPortId, payload) {
  return _PATCH(`/orgs/${orgId}/acus/${acuId}/acuPorts/${acuPortId}`, payload);
}

export function deleteAcuPort(orgId, acuId, acuPortId) {
  return _DELETE(`/orgs/${orgId}/acus/${acuId}/acuPorts/${acuPortId}`);
}

export function createReader(orgId, payload) {
  return _POST(`/orgs/${orgId}/readers`, payload);
}

export function deleteReader(orgId, readerId) {
  return _DELETE(`/orgs/${orgId}/readers/${readerId}`);
}

export function listReaders(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/readers`, queryParams);
}

export function describeReader(orgId, readerId) {
  return _GET(`/orgs/${orgId}/readers/${readerId}`);
}

export function updateReader(orgId, readerId, payload) {
  return _PATCH(`/orgs/${orgId}/readers/${readerId}`, payload);
}

export function assignReader(orgId, readerId, payload) {
  return _POST(`/orgs/${orgId}/readers/${readerId}/assign`, payload);
}

export function unassignReader(orgId, readerId, payload) {
  return _POST(`/orgs/${orgId}/readers/${readerId}/unassign`, payload);
}

export function remoteReaderIdentify(orgId, readerId) {
  return _POST(`/orgs/${orgId}/readers/${readerId}/identify`);
}

export function remoteReaderRestart(orgId, readerId) {
  return _POST(`/orgs/${orgId}/readers/${readerId}/restart`);
}

export function listRelays(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/relays`, queryParams);
}

export function describeRelay(orgId, relayId) {
  return _GET(`/orgs/${orgId}/relays/${relayId}`);
}

export function updateRelay(orgId, relayId, payload) {
  return _PATCH(`/orgs/${orgId}/relays/${relayId}`, payload);
}

export function listRexs(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/rexs`, queryParams);
}

export function describeRex(orgId, rexId) {
  return _GET(`/orgs/${orgId}/rexs/${rexId}`);
}

export function updateRex(orgId, rexId, payload) {
  return _PATCH(`/orgs/${orgId}/rexs/${rexId}`, payload);
}

export function listContactSensors(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/contactSensors`, queryParams);
}

export function describeContactSensor(orgId, contactSensorId) {
  return _GET(`/orgs/${orgId}/contactSensors/${contactSensorId}`);
}

export function updateContactSensor(orgId, contactSensorId, payload) {
  return _PATCH(`/orgs/${orgId}/contactSensors/${contactSensorId}`, payload);
}

export function listWiegands(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/wiegands`, queryParams);
}

export function describeWiegand(orgId, wiegandId) {
  return _GET(`/orgs/${orgId}/wiegands/${wiegandId}`);
}

export function updateWiegand(orgId, wiegandId, payload) {
  return _PATCH(`/orgs/${orgId}/wiegands/${wiegandId}`, payload);
}

export function createRole(orgId, payload) {
  return _POST(`/orgs/${orgId}/roles`, payload);
}

export function listRoles(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/roles`, queryParams);
}

export function describeRole(orgId, roleId) {
  return _GET(`/orgs/${orgId}/roles/${roleId}`);
}

export function deleteRole(orgId, roleId) {
  return _DELETE(`/orgs/${orgId}/roles/${roleId}`);
}

export function updateRole(orgId, roleId, payload) {
  return _PATCH(`/orgs/${orgId}/roles/${roleId}`, payload);
}

export function listRoleScopeIds(orgId, roleId, queryParams) {
  return _GET(`/orgs/${orgId}/roles/${roleId}/scopeIds`, queryParams);
}

export function setRoleScopeIds(orgId, roleId, payload) {
  return _PUT(`/orgs/${orgId}/roles/${roleId}/scopeIds`, payload);
}

export function setRoleUserIds(orgId, roleId, payload) {
  return _PUT(`/orgs/${orgId}/roles/${roleId}/userIds`, payload);
}

export function listRoleUsers(orgId, roleId, queryParams) {
  return _GET(`/orgs/${orgId}/roles/${roleId}/users`, queryParams);
}

export function setUserToRole(orgId, roleId, userId) {
  return _PUT(`/orgs/${orgId}/roles/${roleId}/users/${userId}`);
}

export function removeUserFromRole(orgId, roleId, userId) {
  return _DELETE(`/orgs/${orgId}/roles/${roleId}/users/${userId}`);
}

export function listScopeResources(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/scopeResources`, queryParams);
}

export function describeScopeResource(orgId, scopeResourceId) {
  return _GET(`/orgs/${orgId}/scopeResources/${scopeResourceId}`);
}

export function listAcuPortTypes(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/acuPortTypes`, queryParams);
}

export function describeAcuPortType(orgId, acuPortTypeId) {
  return _GET(`/orgs/${orgId}/acuPortTypes/${acuPortTypeId}`);
}

export function listAcuModels(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/acuModels`, queryParams);
}

export function describeAcuModel(orgId, acuModelId) {
  return _GET(`/orgs/${orgId}/acuModels/${acuModelId}`);
}

export function listIos(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/ios`, queryParams);
}

export function describeIo(orgId, ioId) {
  return _GET(`/orgs/${orgId}/ios/${ioId}`);
}

export function listEmailAlertTypes(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/emailAlertTypes`, queryParams);
}

export function describeEmailAlertType(orgId, emailAlertTypeId) {
  return _GET(`/orgs/${orgId}/emailAlertTypes/${emailAlertTypeId}`);
}

export function getEmailAlerts(orgId) {
  return _GET(`/orgs/${orgId}/emailAlerts`);
}

export function updateEmailAlerts(orgId, payload) {
  return _PATCH(`/orgs/${orgId}/emailAlerts`, payload);
}

export function syncEmailAlertSubscriptions(orgId, payload) {
  return _POST(`/orgs/${orgId}/emailAlerts/syncSubscriptions`, payload);
}

export function listTriggerMethods(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/triggerMethods`, queryParams);
}

export function describeTriggerMethod(orgId, triggerMethodId) {
  return _GET(`/orgs/${orgId}/triggerMethods/${triggerMethodId}`);
}

export function createEntryState(orgId, payload) {
  return _POST(`/orgs/${orgId}/entryStates`, payload);
}

export function listEntryStates(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/entryStates`, queryParams);
}

export function describeEntryState(orgId, entryStateId) {
  return _GET(`/orgs/${orgId}/entryStates/${entryStateId}`);
}

export function deleteEntryState(orgId, entryStateId) {
  return _DELETE(`/orgs/${orgId}/entryStates/${entryStateId}`);
}

export function updateEntryState(orgId, entryStateId, payload) {
  return _PATCH(`/orgs/${orgId}/entryStates/${entryStateId}`, payload);
}

export function setEntryStateTriggerMethodIds(orgId, entryStateId, payload) {
  return _PUT(
    `/orgs/${orgId}/entryStates/${entryStateId}/triggerMethodIds`,
    payload,
  );
}

export function listRelayHardwareTypes(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/relayHardwareTypes`, queryParams);
}

export function describeRelayHardwareType(orgId, relayHardwareTypeId) {
  return _GET(`/orgs/${orgId}/relayHardwareTypes/${relayHardwareTypeId}`);
}

export function listCardFormats(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/cardFormats`, queryParams);
}

export function describeCardFormat(orgId, cardFormatId) {
  return _GET(`/orgs/${orgId}/cardFormats/${cardFormatId}`);
}

export function listCredentialTypes(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/credentialTypes`, queryParams);
}

export function describeCredentialType(orgId, credentialTypeId) {
  return _GET(`/orgs/${orgId}/credentialTypes/${credentialTypeId}`);
}

export function listOrgCredentials(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/credentials`, queryParams);
}

export function createCredential(orgId, userId, payload) {
  return _POST(`/orgs/${orgId}/users/${userId}/credentials`, payload);
}

export function deleteCredential(orgId, userId, credentialId) {
  return _DELETE(`/orgs/${orgId}/users/${userId}/credentials/${credentialId}`);
}

export function listCredentials(orgId, userId, queryParams) {
  return _GET(`/orgs/${orgId}/users/${userId}/credentials`, queryParams);
}

export function describeCredential(orgId, userId, credentialId) {
  return _GET(`/orgs/${orgId}/users/${userId}/credentials/${credentialId}`);
}

export function updateCredential(orgId, userId, credentialId, payload) {
  return _PATCH(
    `/orgs/${orgId}/users/${userId}/credentials/${credentialId}`,
    payload,
  );
}

export function setupMobileCredential(orgId, userId, credentialId) {
  return _POST(
    `/orgs/${orgId}/users/${userId}/credentials/${credentialId}/setupMobile`,
  );
}

export function generateSetupMobileToken(orgId, userId, credentialId) {
  return _POST(
    `/orgs/${orgId}/users/${userId}/credentials/${credentialId}/generateSetupMobileToken`,
  );
}

export function refreshMobileCredential(orgId, userId, credentialId) {
  return _GET(
    `/orgs/${orgId}/users/${userId}/credentials/${credentialId}/refreshMobile`,
  );
}

export function getUserAwsCredentials(
  orgId,
  userId,
  credentialId,
  queryParams,
) {
  return _GET(
    `/orgs/${orgId}/users/${userId}/credentials/${credentialId}/awsCredentials`,
    queryParams,
  );
}

export function syncVersion(orgId, userId, credentialId, payload) {
  return _POST(
    `/orgs/${orgId}/users/${userId}/credentials/${credentialId}/syncVersion`,
    payload,
  );
}

export function syncMobile(orgId, userId, credentialId, payload, queryParams) {
  return _POST(
    `/orgs/${orgId}/users/${userId}/credentials/${credentialId}/syncMobile`,
    payload,
    queryParams,
  );
}

export function cloudKeyEntryUnlock(orgId, userId, credentialId, payload) {
  return _POST(
    `/orgs/${orgId}/users/${userId}/credentials/${credentialId}/cloudKeyEntryUnlock`,
    payload,
  );
}

export function cloudKeyTriggerLockdownPlan(
  orgId,
  userId,
  credentialId,
  payload,
) {
  return _POST(
    `/orgs/${orgId}/users/${userId}/credentials/${credentialId}/cloudKeyTriggerLockdownPlan`,
    payload,
  );
}

export function cloudKeyRevertLockdownPlan(
  orgId,
  userId,
  credentialId,
  payload,
) {
  return _POST(
    `/orgs/${orgId}/users/${userId}/credentials/${credentialId}/cloudKeyRevertLockdownPlan`,
    payload,
  );
}

export function generateCloudKeyUnlockToken(
  orgId,
  userId,
  credentialId,
  payload,
) {
  return _POST(
    `/orgs/${orgId}/users/${userId}/credentials/${credentialId}/generateCloudKeyUnlockToken`,
    payload,
  );
}

export function generateCloudKeyUnlockTokenByModelId(
  orgId,
  userId,
  cloudKeyId,
  payload,
) {
  return _POST(
    `/orgs/${orgId}/users/${userId}/credentials/cloudKey/${cloudKeyId}/generateUnlockToken`,
    payload,
  );
}

export function listCustomFieldTypes(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/customFieldTypes`, queryParams);
}

export function describeCustomFieldType(orgId, customFieldTypeId) {
  return _GET(`/orgs/${orgId}/customFieldTypes/${customFieldTypeId}`);
}

export function listCustomFieldOptions(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/customFieldOptions`, queryParams);
}

export function describeCustomFieldOption(orgId, customFieldOptionId) {
  return _GET(`/orgs/${orgId}/customFieldOptions/${customFieldOptionId}`);
}

export function createCustomField(orgId, payload) {
  return _POST(`/orgs/${orgId}/customFields`, payload);
}

export function deleteCustomField(orgId, customFieldId) {
  return _DELETE(`/orgs/${orgId}/customFields/${customFieldId}`);
}

export function listCustomFields(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/customFields`, queryParams);
}

export function describeCustomField(orgId, customFieldId) {
  return _GET(`/orgs/${orgId}/customFields/${customFieldId}`);
}

export function updateCustomField(orgId, customFieldId, payload) {
  return _PATCH(`/orgs/${orgId}/customFields/${customFieldId}`, payload);
}

export function listUserCustomFields(orgId, userId, queryParams) {
  return _GET(`/orgs/${orgId}/users/${userId}/customFields`, queryParams);
}

export function describeUserCustomField(orgId, userId, customFieldId) {
  return _GET(`/orgs/${orgId}/users/${userId}/customFields/${customFieldId}`);
}

export function updateUserCustomField(orgId, userId, customFieldId, payload) {
  return _PATCH(
    `/orgs/${orgId}/users/${userId}/customFields/${customFieldId}`,
    payload,
  );
}

export function createCustomFieldDropdownItem(orgId, customFieldId, payload) {
  return _POST(
    `/orgs/${orgId}/customFields/${customFieldId}/dropdownItems`,
    payload,
  );
}

export function deleteCustomFieldDropdownItem(
  orgId,
  customFieldId,
  dropdownItemId,
) {
  return _DELETE(
    `/orgs/${orgId}/customFields/${customFieldId}/dropdownItems/${dropdownItemId}`,
  );
}

export function listCustomFieldDropdownItems(
  orgId,
  customFieldId,
  queryParams,
) {
  return _GET(
    `/orgs/${orgId}/customFields/${customFieldId}/dropdownItems`,
    queryParams,
  );
}

export function describeCustomFieldDropdownItem(
  orgId,
  customFieldId,
  dropdownItemId,
) {
  return _GET(
    `/orgs/${orgId}/customFields/${customFieldId}/dropdownItems/${dropdownItemId}`,
  );
}

export function updateCustomFieldDropdownItem(
  orgId,
  customFieldId,
  dropdownItemId,
  payload,
) {
  return _PATCH(
    `/orgs/${orgId}/customFields/${customFieldId}/dropdownItems/${dropdownItemId}`,
    payload,
  );
}

export function createSchedule(orgId, payload) {
  return _POST(`/orgs/${orgId}/schedules`, payload);
}

export function deleteSchedule(orgId, scheduleId) {
  return _DELETE(`/orgs/${orgId}/schedules/${scheduleId}`);
}

export function listSchedules(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/schedules`, queryParams);
}

export function describeSchedule(orgId, scheduleId) {
  return _GET(`/orgs/${orgId}/schedules/${scheduleId}`);
}

export function updateSchedule(orgId, scheduleId, payload) {
  return _PATCH(`/orgs/${orgId}/schedules/${scheduleId}`, payload);
}

export function createEvent(orgId, scheduleId, payload) {
  return _POST(`/orgs/${orgId}/schedules/${scheduleId}/events`, payload);
}

export function deleteEvent(orgId, scheduleId, eventId) {
  return _DELETE(`/orgs/${orgId}/schedules/${scheduleId}/events/${eventId}`);
}

export function listEvents(orgId, scheduleId, queryParams) {
  return _GET(`/orgs/${orgId}/schedules/${scheduleId}/events`, queryParams);
}

export function describeEvent(orgId, scheduleId, eventId) {
  return _GET(`/orgs/${orgId}/schedules/${scheduleId}/events/${eventId}`);
}

export function updateEvent(orgId, scheduleId, eventId, payload) {
  return _PATCH(
    `/orgs/${orgId}/schedules/${scheduleId}/events/${eventId}`,
    payload,
  );
}

export function updateEvents(orgId, scheduleId, payload) {
  return _PATCH(`/orgs/${orgId}/schedules/${scheduleId}/events`, payload);
}

export function listScheduleTypes(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/scheduleTypes`, queryParams);
}

export function describeScheduleType(orgId, scheduleTypeId) {
  return _GET(`/orgs/${orgId}/scheduleTypes/${scheduleTypeId}`);
}

export function getActivity(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/reports/activity`, queryParams);
}

export function listIdentityProviderTypes(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/identityProviderTypes`, queryParams);
}

export function describeIdentityProviderType(orgId, identityProviderTypeId) {
  return _GET(`/orgs/${orgId}/identityProviderTypes/${identityProviderTypeId}`);
}

export function listIdentityProviders(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/identityProviders`, queryParams);
}

export function createIdentityProvider(orgId, payload) {
  return _POST(`/orgs/${orgId}/identityProviders`, payload);
}

export function describeIdentityProvider(orgId, identityProviderId) {
  return _GET(`/orgs/${orgId}/identityProviders/${identityProviderId}`);
}

export function updateIdentityProvider(orgId, identityProviderId, payload) {
  return _PATCH(
    `/orgs/${orgId}/identityProviders/${identityProviderId}`,
    payload,
  );
}

export function listIdentityProviderGroups(
  orgId,
  identityProviderId,
  queryParams,
) {
  return _GET(
    `/orgs/${orgId}/identityProviders/${identityProviderId}/groups`,
    queryParams,
  );
}

export function syncUsersIdentityProvider(orgId, identityProviderId, payload) {
  return _POST(
    `/orgs/${orgId}/identityProviders/${identityProviderId}/syncUsers`,
    payload,
  );
}

export function getIdentityProviderGroupRelations(orgId, identityProviderId) {
  return _GET(
    `/orgs/${orgId}/identityProviders/${identityProviderId}/groupRelations`,
  );
}

export function setIdentityProviderGroupRelations(
  orgId,
  identityProviderId,
  payload,
) {
  return _PUT(
    `/orgs/${orgId}/identityProviders/${identityProviderId}/groupRelations`,
    payload,
  );
}

export function identityProviderSyncUsers(payload) {
  return _POST('/identityProviders/syncUsers', payload);
}

export function getIdentityProviderAuthUrl(orgId, identityProviderId) {
  return _POST(
    `/orgs/${orgId}/identityProviders/${identityProviderId}/oauth2/authorize`,
    null,
  );
}

export function gsuiteOauth2Callback(payload) {
  return _POST('/gsuite/oauth2/callback', payload);
}

export function gsuiteOidcCallback(payload) {
  return _POST('/gsuite/oidc/callback', payload);
}

export function msazureadOauth2Callback(payload) {
  return _POST('/msazuread/oauth2/callback', payload);
}

export function msazureadOidcCallback(payload) {
  return _POST('/msazuread/oidc/callback', payload);
}

export function createOrgPicture(orgId, payload) {
  return _POST(`/orgs/${orgId}/orgPictures`, payload);
}

export function deleteOrgPicture(orgId, orgPictureId) {
  return _DELETE(`/orgs/${orgId}/orgPictures/${orgPictureId}`);
}

export function listOrgPictures(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/orgPictures`, queryParams);
}

export function describeOrgPicture(orgId, orgPictureId) {
  return _GET(`/orgs/${orgId}/orgPictures/${orgPictureId}`);
}

export function updateOrgPicture(orgId, orgPictureId, payload) {
  return _PATCH(`/orgs/${orgId}/orgPictures/${orgPictureId}`, payload);
}

export function listPalettes(orgId, queryParams) {
  return _GET(`/orgs/${orgId}/palettes`, queryParams);
}

export function describePalette(orgId, paletteId) {
  return _GET(`/orgs/${orgId}/palettes/${paletteId}`);
}

export function listUserMfaCredentials(orgId, userId, queryParams) {
  return _GET(`/orgs/${orgId}/users/${userId}/mfaCredentials`, queryParams);
}

export function deleteUserMfaCredential(orgId, userId, mfaCredentialId) {
  return _DELETE(
    `/orgs/${orgId}/users/${userId}/mfaCredentials/${mfaCredentialId}`,
  );
}

export function getSummaryStats(queryParams) {
  return _GET('/reports/summaryStats', queryParams);
}

/*
 * Roles
 */

export function createIdentityRole(payload) {
  return _POST('/roles', payload);
}

export function listIdentityRoles(queryParams) {
  return _GET('/roles', queryParams);
}

export function describeIdentityRole(roleId) {
  return _GET(`/roles/${roleId}`);
}

export function deleteIdentityRole(roleId) {
  return _DELETE(`/roles/${roleId}`);
}

export function updateIdentityRole(roleId, payload) {
  return _PATCH(`/roles/${roleId}`, payload);
}

export function listIdentityRoleScopeIds(roleId, queryParams) {
  return _GET(`/roles/${roleId}/scopeIds`, queryParams);
}

export function setIdentityRoleScopeIds(roleId, payload) {
  return _PUT(`/roles/${roleId}/scopeIds`, payload);
}

export function listIdentityRoleIdentities(roleId, queryParams) {
  return _GET(`/roles/${roleId}/identities`, queryParams);
}

export function setIdentityToIdentityRole(roleId, identityId) {
  return _PUT(`/roles/${roleId}/identities/${identityId}`);
}

export function removeIdentityFromIdentityRole(roleId, identityId) {
  return _DELETE(`/roles/${roleId}/identities/${identityId}`);
}

/*
 * Scope Resources
 */

export function listIdentityScopeResources(queryParams) {
  return _GET('/scopeResources', queryParams);
}

export function describeIdentityScopeResource(scopeResourceId) {
  return _GET(`/scopeResources/${scopeResourceId}`);
}

/*
 * Tokens
 */

export function validateResetPasswordToken(token, payload) {
  return _POST(`/tokens/resetPasswordTokens/${token}/validate`, payload);
}

export function useResetPasswordToken(token, payload) {
  return _POST(`/tokens/resetPasswordTokens/${token}/use`, payload);
}

export function validateVerifyEmailToken(token, payload) {
  return _POST(`/tokens/verifyEmailTokens/${token}/validate`, payload);
}

export function useVerifyEmailToken(token, payload) {
  return _POST(`/tokens/verifyEmailTokens/${token}/use`, payload);
}

export function validateSetupMobileToken(token, payload) {
  return _POST(`/tokens/setupMobileTokens/${token}/validate`, payload);
}

export function useSetupMobileToken(token, payload, queryParams) {
  return _POST(`/tokens/setupMobileTokens/${token}/use`, payload, queryParams);
}

export function validateSetupAcuToken(token, payload) {
  return _POST(`/tokens/setupAcuTokens/${token}/validate`, payload);
}

export function useSetupAcuToken(token, payload) {
  return _POST(`/tokens/setupAcuTokens/${token}/use`, payload);
}

export function useCloudKeyUnlockToken(token) {
  return _POST(`/tokens/cloudKeyUnlockTokens/${token}/use`);
}

/*
 * Titanium Versions
 */
export function listTitaniumVersions(queryParams) {
  return _GET('/titaniumVersions', queryParams);
}

export function describeTitaniumVersion(titaniumVersionId) {
  return _GET(`/titaniumVersions/${titaniumVersionId}`);
}

export function updateTitaniumVersion(titaniumVersionId, payload) {
  return _PATCH(`/titaniumVersions/${titaniumVersionId}`, payload);
}

/*
 * Health
 */

export function getHealth() {
  return _GET('/health');
}

/*
 * Credentials
 */

export function createCardOpenpathDesfireEv1(payload) {
  return _POST('/physicalCredentials/cardOpenpathDesfireEv1', payload);
}

/*
 * Stripe
 */

export function handleStripeEvent(payload) {
  return _POST('/stripe/event', payload);
}

/*
 * Packages
 */

export function createPackage(payload) {
  return _POST('/packages', payload);
}

export function listPackages(queryParams) {
  return _GET('/packages', queryParams);
}

export function describePackage(packageId) {
  return _GET(`/packages/${packageId}`);
}

export function deletePackage(packageId) {
  return _DELETE(`/packages/${packageId}`);
}

export function updatePackage(packageId, payload) {
  return _PATCH(`/packages/${packageId}`, payload);
}

export function createPackagePackageFeature(packageId, payload) {
  return _POST(`/packages/${packageId}/packageFeatures`, payload);
}

export function listPackagePackageFeatures(packageId, queryParams) {
  return _GET(`/packages/${packageId}/packageFeatures`, queryParams);
}

export function describePackagePackageFeature(packageId, packageFeatureId) {
  return _GET(`/packages/${packageId}/packageFeatures/${packageFeatureId}`);
}

export function deletePackagePackageFeature(packageId, packageFeatureId) {
  return _DELETE(`/packages/${packageId}/packageFeatures/${packageFeatureId}`);
}

export function updatePackagePackageFeature(
  packageId,
  packageFeatureId,
  payload,
) {
  return _PATCH(
    `/packages/${packageId}/packageFeatures/${packageFeatureId}`,
    payload,
  );
}

/*
 * Features
 */

export function createFeature(payload) {
  return _POST('/features', payload);
}

export function listFeatures(queryParams) {
  return _GET('/features', queryParams);
}

export function describeFeature(featureId) {
  return _GET(`/features/${featureId}`);
}

export function deleteFeature(featureId) {
  return _DELETE(`/features/${featureId}`);
}

export function updateFeature(featureId, payload) {
  return _PATCH(`/features/${featureId}`, payload);
}

export function listFeatureOrgs(featureId, queryParams) {
  return _GET(`/features/${featureId}/orgs`, queryParams);
}

/*
 * Package Plans
 */

export function createPackagePlan(payload) {
  return _POST('/packagePlans', payload);
}

export function listPackagePlans(queryParams) {
  return _GET('/packagePlans', queryParams);
}

export function describePackagePlan(packagePlanId) {
  return _GET(`/packagePlans/${packagePlanId}`);
}

export function deletePackagePlan(packagePlanId) {
  return _DELETE(`/packagePlans/${packagePlanId}`);
}

export function updatePackagePlan(packagePlanId, payload) {
  return _PATCH(`/packagePlans/${packagePlanId}`, payload);
}

/*
 * Helium Coders
 */

export function createHeliumCoder(payload) {
  return _POST('/heliumCoders', payload);
}

export function listHeliumCoders(queryParams) {
  return _GET('/heliumCoders', queryParams);
}

export function describeHeliumCoder(heliumCoderId) {
  return _GET(`/heliumCoders/${heliumCoderId}`);
}

export function deleteHeliumCoder(heliumCoderId) {
  return _DELETE(`/heliumCoders/${heliumCoderId}`);
}

export function updateHeliumCoder(heliumCoderId, payload) {
  return _PATCH(`/heliumCoders/${heliumCoderId}`, payload);
}

export function logHeliumCoderRubiksSolve(heliumCoderId, payload) {
  return _POST(`/heliumCoders/${heliumCoderId}/logRubiksSolve`, payload);
}

export function logHeliumCoderHeight(heliumCoderId, payload) {
  return _POST(`/heliumCoders/${heliumCoderId}/logHeight`, payload);
}

export function checkHeliumCoderFavoriteAnimal(heliumCoderId) {
  return _GET(`/heliumCoders/${heliumCoderId}/checkFavoriteAnimal`);
}
