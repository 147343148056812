import React, {
  memo,
  Suspense,
  useCallback,
  useMemo,
  lazy,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useInjectReducers } from 'utils/injectReducer';
import { useInjectSagas } from 'utils/injectSaga';
import { useDispatch, useSelector } from 'react-redux';
import { fireScopeCheck } from 'routes/AuthenticatedContainer/actions';
import { selectIsLoginComplete } from 'routes/AppContainer/selectors';
import isEqual from 'lodash/isEqual';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom-v5-compat';
import { useParams } from 'react-router-dom';
import { Loader } from 'components/Loader';
import { RESTART_ON_REMOUNT } from './constants';

// set this to true if you want to see ALL routing debug info in console
const SHOW_ALL_ROUTE_DEBUG_INFO = false;

export const WrappedRoute = memo(
  ({
    debug = SHOW_ALL_ROUTE_DEBUG_INFO, // BOOL - set this to true to print additional info, helpful for debugging new routes
    noAuth = false, // BOOL - set this to true to allow the route to mount without a login check
    RouteComponent = () => null, // FUNCTION - this is the react component we want to render for the route
    RouteImportPath, // ???
    route, // ???
    reducers = [], // ARRAY of objects with keys [key, reducer] - the reducers to inject when this route is mounted
    sagas = [], // ARRAY of objects with keys [key, saga] - the sagas to inject when this route is mounted
    scope = [], // ARRAY of strings that map to permissions required to view this route
    path, // STRING - the path of the route, i.e. 'users' to match
    name, // usually the PAGE variable for this route
    extraData = {}, // ??? additional data
  }) => {
    // Selectors
    const isLoginComplete = useSelector((state) =>
      selectIsLoginComplete()(state),
    );

    const location = useLocation();
    const params = useParams();

    const originalProps = {
      RouteComponent,
      reducers,
      sagas,
      path,
      name,
      params,
      location: {
        ...location,
        query: parse(location.search),
      },
    };

    // ??? For some reason, slideouts don't get a route (but standard routes do)
    // so we rebuild it. Seems Curt wrote this line so check with him
    if (!route) {
      // eslint-disable-next-line no-param-reassign
      route = {
        route: { RouteComponent, reducers, sagas, scope, path, name },
        name,
      };
    }

    // helper functon so we only have to disable one line for eslint to allow console
    // you can use `debugLog` internally to print stuff and it'll be gated by the `debug` prop
    const debugLog = useCallback(
      (log) => {
        if (!debug) return;
        console.debug(`[createRoute] (${route.name}) - ${log}`); // eslint-disable-line no-console
      },
      [debug, route.name],
    );

    // State
    const [areReducersInjected, setReducersInjected] = useState(false);
    const [areSagasInjected, setSagasInjected] = useState(false);
    debugLog(
      `*** SETTING INITIAL STATE? areReducersInjected: ${areReducersInjected} areSagasInjected: ${areSagasInjected}`,
    );

    debugLog(`!!! route.name: ${route.name} (Wrapped Route)`);

    // Testing useDispatch to fire action to verify our scopes are valid
    const dispatch = useDispatch();
    useEffect(() => {
      if (
        route &&
        route.name &&
        ['authenticated', 'app', 'orgContainer', 'masterContainer'].includes(
          route.name,
        )
      ) {
        return;
      }

      dispatch(fireScopeCheck(scope));
    }, [dispatch, route, scope]);

    // inject our reducers dynamically on route mount
    // callback helps with knowing when we're done injecting
    const injectorReadyCallback = useCallback(() => {
      debugLog(`!!! injected reducers: done ${Date.now()}`);
      setReducersInjected(true);
    }, [debugLog]);
    useInjectReducers(reducers, injectorReadyCallback);

    // inject the sagas dynamically on route mount
    // callback helps with knowing when we're done injecting
    const newSagas = sagas.map((s) => ({
      ...s,
      mode: s.mode || RESTART_ON_REMOUNT,
    }));
    const sagaReadyCallback = useCallback(() => {
      debugLog(`!!! injected sagas: done ${Date.now()}`);
      setSagasInjected(true);
    }, [debugLog]);
    useInjectSagas(newSagas, sagaReadyCallback, route.name);

    useEffect(() => {
      debugLog('**** COMPONENT DID MOUNT ' + route.name);
      return () => {
        debugLog('**** COMPONENT WILL UNMOUNT ' + route.name);
        setReducersInjected(false);
        setSagasInjected(false);
      };
    }, [debugLog, route.name]);

    // This allows for memoization of the route component so that it does not unmount when the parent rerenders (due to being wrapped in Suspense)
    const ImportedRouteComponent = useMemo(() => {
      switch (RouteImportPath) {
        case 'AuthenticatedContainer':
          return lazy(() => import('routes/AuthenticatedContainer/index'));
        case 'OrgContainer':
          return lazy(() => import('routes/OrgContainer/index'));
        case 'EntrySchedules/EntrySchedulesCreateEditPage':
          return lazy(
            () =>
              import(
                'routes/EntrySchedules/EntrySchedulesCreateEditPage/index'
              ),
          );
        default:
          return null;
      }
    }, [RouteImportPath]);
    let ChosenRoute = ImportedRouteComponent || RouteComponent;

    // Make sure injectors have finished and our login restoration is complete
    // (prevents null orgId from existing in later sagas)
    const loginCheck = noAuth || isLoginComplete;
    debugLog(
      `*** loginCheck: ${loginCheck} :: areSagasInjected: ${areSagasInjected} :: areReducersInjected: ${areReducersInjected} `,
    );
    if (!(areReducersInjected && areSagasInjected && loginCheck)) {
      debugLog(
        `[Delay Loading Route (waiting for injections)...] ${route.name} injected reducers?: ${areReducersInjected} - injected sagas?: ${areSagasInjected} - login complete? ${loginCheck}`,
      );
      return 'Loading...';
    }

    debugLog('We are past the loading check!');

    // NOTE - the array version of RouteComponent will get weird when trying to memoize which we need to do to prevent unmounting of the ChosenRoute within Suspense when the parent rerenders
    // Note: Having an array of RouteComponents breaks with our new drawer implementation
    if (Array.isArray(RouteComponent)) {
      // we might be A/B Testing a new route, so we pass in an array with 0th being the default
      ChosenRoute = RouteComponent[0];
      if (location.search) {
        const parsedValues = parse(location.search);
        // If we have a beta route use that to determine which route we're selecting
        if (parsedValues.beta) {
          // Ensure we stay within the array bounds
          if (parsedValues.beta > RouteComponent.length - 1) {
            parsedValues.beta = RouteComponent.length - 1;
          }
          ChosenRoute = RouteComponent[parsedValues.beta];
        }
      }
    }

    // wrap everything in suspence (and each component is wrapped in lazy() so that we can allow downloads of bundles dynamically for faster load time)
    return (
      <Suspense
        key={route.name}
        fallback={
          <div style={{ marginTop: '55px' }}>
            <Loader loaderContentOnly />
          </div>
        }
      >
        <ChosenRoute {...originalProps} route={route} extraData={extraData} />
      </Suspense>
    );
  },
  (prev, curr) => isEqual(prev, curr),
);

WrappedRoute.propTypes = {
  RouteComponent: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.shape()),
  ]),
  RouteImportPath: PropTypes.string,
  originalProps: PropTypes.shape(),
  route: PropTypes.shape({
    route: PropTypes.object,
    name: PropTypes.string.isRequired,
  }),
  reducers: PropTypes.array,
  sagas: PropTypes.array,
  scope: PropTypes.array,
  debug: PropTypes.bool,
  noAuth: PropTypes.bool,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  name: PropTypes.string,
  extraData: PropTypes.object,
};

WrappedRoute.displayName = 'WrappedRoute';
