import React from 'react';

import { Route, Switch } from 'react-router-dom';
import invariant from 'invariant';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import conformsTo from 'lodash/conformsTo';

// THIS IS THE MAGIC COMPONENT
import { WrappedRoute } from './WrappedRoute';

// //////////////////////////////////////////
// this is the actual exported function that
// creates a <WrappedRoute />
// used in react router to dynamically create routes
// and handle injection
// //////////////////////////////////////////

export function createRoute(routeConfig, noAuth = false) {
  const shape = {
    RouteComponent: (value) => isObject(value) || isArray(value),
    scope: isArray,
    reducers: isArray,
    sagas: isArray,
    path: (value) => isString(value) || isArray(value),
  };

  const optionalDefaults = {
    RouteComponent: () => null,
    exact: true,
    reducers: [],
    sagas: [],
    scope: [],
  };

  // Some helpful feedback if someone creates a route without valid params
  const judge = { ...optionalDefaults, ...routeConfig };
  invariant(
    conformsTo(judge, shape),
    `(utils/createRoute.js) createRoute: Expected a valid routeConfig object ${
      routeConfig ? routeConfig.name : 'MISSING NAME'
    } - ${JSON.stringify(Object.keys(routeConfig))}`,
  );

  return (
    <Route
      key={`route-${routeConfig.name}`}
      path={
        routeConfig.exact
          ? routeConfig.path
          : isArray(routeConfig.path)
            ? routeConfig.path.map((str) => `*${str}`)
            : `*${routeConfig.path}`
      }
      exact={routeConfig.exact}
    >
      <>
        <WrappedRoute noAuth={noAuth} {...routeConfig} />

        {/* some routes have nested children */}
        {!!routeConfig.routes?.length && (
          <Switch>{routeConfig.routes.map((r) => createRoute(r))}</Switch>
        )}
      </>
    </Route>
  );
}
