import React from 'react';
import { resetPasswordRoute as route } from 'routes/constants';
import { PAGE } from './constants';

const RouteComponent = React.lazy(() => import('.'));

export default {
  RouteComponent,
  path: route,
  name: PAGE,
  reducers: [
    {
      key: PAGE,
      reducer: require('./reducer').default,
    },
  ],
};
