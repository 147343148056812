import {
  takeEvery,
  spawn,
  all,
  put,
  select,
  call,
  take,
  fork,
  delay,
  takeLatest,
} from 'redux-saga/effects';

import { checkScope } from 'utils/redirects';
import {
  listUserPictures,
  describeOrg,
  listIdentityProviders,
} from 'heliumApi';

import { LOGIN_SUCCESS } from 'routes/AppContainer/constants';
import { ROOT_CONTAINER_MOUNTED } from 'routes/AuthenticatedContainer/constants';
import { integrationsIdentityProviderConfigRouteTemplate } from 'routes/constants';
import { addBannerData } from 'global/banner/actions';
import {
  setCurrentOrg,
  setCurrentOrgName,
  setOrgUserProfileImage,
  setAlert,
} from 'routes/AppContainer/actions';
import { selectIsLoginComplete } from 'routes/AppContainer/selectors';
import {
  selectCurrentOrgId,
  selectActiveScopes,
  selectCurrentUserOpal,
} from 'global/accessToken/selectors';
import { requestGetBillingInformation } from 'global/billing/sagas';
import { requestListLockdownPlans } from 'global/lockdownPlans/actions';
import { requestListCloudKeyCredentials } from 'global/cloudKey/actions';
import { requestOrgPackagePlans } from 'global/orgPackagePlans/sagas';
import { requestAndSet } from 'utils/helpers';
import {
  setVideoProviderTypes,
  setVideoProviders,
} from 'routes/VideoProviderPage/actions';
import {
  saveTableState,
  setOrgContainerReducer,
} from 'routes/OrgContainer/actions';
import HeliumDictionary from 'heliumDictionary';
import { REQUEST_CHANNELTIVITY_LOGIN } from 'routes/MasterContainer/constants'; // TODO move that constant+action into OrgContainer after we fully migrate resellers from Master Mode into the Partner Center area of OrgContainer
import { requestUpdateTable } from 'containers/Table/actions';
import { deleteSlideOut, addSlideOut } from 'global/slideOuts/actions';
import { currencyOptions } from 'utils/locales';
import { t } from 'i18next';
import { getWindowLocation } from 'utils/window';
import { selectOrgContainerReducerItem } from './selectors';
import {
  REQUEST_GET_CURRENT_ORG_DATA,
  REQUEST_PAGE_DATA_GENERIC,
  REQUEST_HELIUM_DATA_GENERIC,
  RESET_HLS_URL,
  RESET_POSTER_URL,
  SET_CAMERA_STALE_SNAPSHOT,
  REQUEST_AND_SET_GENERAL_WRAPPER,
  TABLE_STATE_REDUCER,
  SLIDE_OUT_SUCCESSFUL,
  UPDATE_OPTIONS,
} from './constants';

/* Complex sagas */
function* requestRefreshCurrentUserInfo() {
  const currentOpal = yield select(selectCurrentUserOpal());
  let orgId = null;
  if (currentOpal) {
    const parts = currentOpal.split(':');
    orgId = parts[4];
    const userId = parts[6];

    const listUserPicturesResponse = yield call(
      listUserPictures,
      orgId,
      userId,
    );
    if (listUserPicturesResponse.err) {
      console.log(listUserPicturesResponse.err.message); // eslint-disable-line no-console
    } else {
      yield put(
        setOrgUserProfileImage(
          listUserPicturesResponse.data.data.length
            ? listUserPicturesResponse.data.data[0]
            : null,
        ),
      );
    }
  }

  let isLoginComplete = yield select(selectIsLoginComplete());
  while (!isLoginComplete) {
    // console.warn('requestRefreshCurrentUserInfo is waiting for LOGIN_SUCCESS!')
    yield take(LOGIN_SUCCESS);
    isLoginComplete = yield select(selectIsLoginComplete());
  }
  const windowLocation = getWindowLocation();
  // @HACK - why does Anthony have no identity here?
  // this catches the case if the user somehow gets null into `/o/{orgid}`
  // as their orgId...
  if (windowLocation.pathname.split('/')[2] === 'null') {
    // eslint-disable-next-line no-alert
    window.alert(
      t(`Something went wrong with your login credentials! Page will refresh.`),
    );
    windowLocation.href = '/';
  }
}

function* refreshLoginOrChangeOrg() {
  const currentOrgId = yield select(selectCurrentOrgId());
  const currentScopes = yield select(selectActiveScopes());
  if (currentOrgId) {
    // bootstrapping any data we need for the orgcontainer, currently three:
    // - billing banner data
    // - IDP Sync errors (for another banner)
    // - videoProvider types (currently used across multiple routes, but only needs to be set one)

    // FIXME OPAC-2606 this scope check doesn't actually link to
    // anything that happens in the requestGetBillingInformation saga,
    // which just calls describeOrg (which allows almost any master
    // mode or org-level scope) and describeLatestTermsVersion (which
    // requires no scope at all)
    const hasAccessToBilling = checkScope(currentOrgId, currentScopes, [
      'o{orgId}-admin:w',
      'o{orgId}-admin:r',
      'o:w',
      'o:r',
      's-o:w',
      's-o:r',
    ]);

    if (hasAccessToBilling) {
      yield call(requestGetBillingInformation);
    }

    const hasAccessToIdentityProviders = checkScope(
      currentOrgId,
      currentScopes,
      [
        'o{params.orgId}-user:w',
        'o{params.orgId}-user:r',
        'o{params.orgId}-dash:w',
        'o{params.orgId}-dash:r',
        'o{params.orgId}-dashActivity:w',
        'o{params.orgId}-dashActivity:r',
        'o{params.orgId}-integrations:w',
        'o{params.orgId}-integrations:r',
        'o{params.orgId}-credential:w',
        'o{params.orgId}-credential:r',
        'o{params.orgId}-site:w',
        'o{params.orgId}-site:r',
        'o{params.orgId}-siteGeneral:w',
        'o{params.orgId}-siteGeneral:r',
        'o{params.orgId}-hw:w',
        'o{params.orgId}-hw:r',
        'o{params.orgId}-entryState:w',
        'o{params.orgId}-entryState:r',
        'o{params.orgId}-outboundWebhooks:w',
        'o{params.orgId}-outboundWebhooks:r',
        'o{params.orgId}-configurations:w',
        'o{params.orgId}-configurations:r',
        'o{params.orgId}-admin:w',
        'o{params.orgId}-admin:r',
        'o{params.orgId}-account:w',
        'o{params.orgId}-account:r',
        'o{params.orgId}-mobileAppSettings:w',
        'o{params.orgId}-mobileAppSettings:r',
        'o{params.orgId}-alertSettings:w',
        'o{params.orgId}-alertSettings:r',
        'o{params.orgId}-role:w',
        'o{params.orgId}-role:r',
        'o{params.orgId}-quickStart:w',
        'o{params.orgId}-quickStart:r',
        'o{params.orgId}-ldp:w',
        'o{params.orgId}-ldp:r',
        'o:w',
        'o:r',
        's-o:w',
        's-o:r',
      ],
    );

    if (hasAccessToIdentityProviders) {
      const response = yield call(listIdentityProviders, currentOrgId);
      if (!response.err) {
        // lastSyncAttemptStatus
        // Identity provider user syncing failed on (lastSyncFailureAt): (lastSyncFailureMessage). Please re-authenticate and try syncing again. If issue still persists, please contact support.
        for (let i = 0; i < response.data.data.length; ++i) {
          const idp = response.data.data[i];
          const ctaRoute = integrationsIdentityProviderConfigRouteTemplate
            .replace(/_idptCode_/g, idp.identityProviderType.code)
            .replace(/_idptId_/g, idp.identityProviderType.id);
          if (idp.lastSyncAttemptStatus === 'F') {
            const x = {
              key: `idpError-id-${idp.id}`,
              type: 'error',
              ctaRoute,
              text: {
                status: t(`Identity Provider Sync Failed!`),
                cta: t(`Please re-authenticate and try syncing again.`),
                text: '',
              },
            };
            yield put(addBannerData(x));
          }
        }
      }
    }

    const hasAccessToVideoProviders = checkScope(currentOrgId, currentScopes, [
      'o{params.orgId}-videoProviderLive:w',
      'o{params.orgId}-videoProviderPlayback:w',
      'o{params.orgId}-opvideoDevice:r',
      'o{params.orgId}-opvideoDevice:w',
      'o{params.orgId}-hw:w',
      'o{params.orgId}-hw:r',
      'o{params.orgId}-dash:w', // for dashboards
      'o{params.orgId}-dash:r', // for dashboards
      'o{params.orgId}-dashEntry:w',
      'o{params.orgId}-dashEntry:r',
      'o{params.orgId}-dashActivity:w',
      'o{params.orgId}-dashActivity:r',
      'o:w',
      'o:r',
      's-o:w',
      's-o:r',
    ]);

    if (hasAccessToVideoProviders) {
      yield call(requestAndSet, 'listVideoProviders', [currentOrgId], {
        createSetterAction: ({ data }) => setVideoProviders(currentOrgId, data),
      });
    }

    const hasAccessToVideoProviderTypes = checkScope(
      currentOrgId,
      currentScopes,
      [
        'o{params.orgId}-integrations:w',
        'o{params.orgId}-integrations:r',
        'o{params.orgId}-hw:w',
        'o{params.orgId}-hw:r',
        'o{params.orgId}-dash:w', // for dashboards
        'o{params.orgId}-dash:r', // for dashboards
        'o{params.orgId}-dashEntry:w',
        'o{params.orgId}-dashEntry:r',
        'o{params.orgId}-dashActivity:w',
        'o{params.orgId}-dashActivity:r',
        'o:w',
        'o:r',
        's-o:w',
        's-o:r',
      ],
    );

    if (hasAccessToVideoProviderTypes) {
      yield call(requestAndSet, 'listVideoProviderTypes', [currentOrgId], {
        createSetterAction: ({ data }) =>
          setVideoProviderTypes(currentOrgId, data),
      });
    }
  }

  // TODO why are these not also gated under the if (currentOrgId) above?
  yield put(requestListLockdownPlans());
  yield call(requestGetCurrentOrgDataSaga);
  yield call(requestRefreshCurrentUserInfo); // Retrieves identity info
  yield put(requestListCloudKeyCredentials());
  yield call(requestOrgPackagePlans);
}

// this saga is called when orgcontainer mounts to ensure we have the name/id of the org we're looking at!
// (mostly used when we're in master mode and don't have regular permissions to get it from the accessToken)
function* requestGetCurrentOrgDataSaga() {
  const currentOrgId = yield select(selectCurrentOrgId());

  if (!currentOrgId) return;

  const response = yield call(describeOrg, currentOrgId);
  if (response.err) {
    yield put(
      setAlert(
        'error',
        t(`You don't have permission to access this org ({{currentOrgId}})`, {
          currentOrgId,
        }),
      ),
    );
    return;
  }
  yield put(setCurrentOrg(response.data.data.id));
  yield put(setCurrentOrgName(response.data.data.id, response.data.data.name));
}

function* watchRootContainerMounted() {
  while (true) {
    const action = yield take(ROOT_CONTAINER_MOUNTED);
    yield spawn(refreshLoginOrChangeOrg, action);
  }
}

// make new saga for one request that is used below
// used in useHeliumData() hook on the Dashboard
function* requestPageDataGeneric({
  page,
  injects = [],
  data: { options = {} } = {},
}) {
  // turn on the data loader
  yield put(setOrgContainerReducer('TOGGLE_DATA_LOADER', page, true));

  // get all the data we need
  yield all(
    injects.reduce((acc, inject) => {
      acc.push(_requestHeliumDataHelper(inject, page, options));
      return acc;
    }, []),
  );

  // turn off the loader
  yield put(setOrgContainerReducer('TOGGLE_DATA_LOADER', page, false));
}

// used in useHeliumData() hook on the Dashboard
function* _requestHeliumDataHelper(inject, page, options = {}) {
  if (Object.keys(options).length) {
    throw new Error(t(`Using deprecated options param`));
  }
  const currentOrgId = yield select(selectCurrentOrgId());
  let effectiveKey = inject;
  let effectiveName = null;
  let effectiveRequisites = [currentOrgId];
  const effectiveOptions = {};
  let effectiveErrorHandler = null;

  if (typeof effectiveKey === 'object') {
    const {
      key: objKey,
      name = null,
      requisites: apiReqs = [currentOrgId],
      filter = null,
      initOnly = false,
      onError = null,
      suppressErrorMessage = false,
      defaultQueryParams = {},
      loopToGetAll,
    } = effectiveKey;

    if (!objKey) {
      throw new Error(
        t(`If passing object to useHeliumData, must include key param!`),
      );
    }
    effectiveKey = objKey;
    effectiveName = name;
    effectiveRequisites = apiReqs;
    effectiveErrorHandler = onError;

    if (filter) {
      effectiveOptions.filter = filter;
    }

    effectiveOptions.suppressErrorMessage = suppressErrorMessage;
    effectiveOptions.defaultQueryParams = defaultQueryParams;
    effectiveOptions.loopToGetAll = loopToGetAll;

    if (initOnly) {
      return;
    }
  }

  // first make sure we have a dictionary definition
  const lookup = HeliumDictionary[effectiveKey];
  if (!lookup) {
    throw new Error(
      t(
        `Missing from HeliumDictionary {{effectiveKey}}. You may need to update heliumDictionary.js`,
        { effectiveKey },
      ),
    );
  }

  // next make sure our options are valid!
  // const VALID_OPTIONS = ['q', 'filter', 'subFilterId', 'tag', 'initOnly', 'loader', 'suppressErrorMessage', 'onError', 'defaultQueryParams']
  // console.debug(`[effectiveOptions ${effectiveKey}] - ${JSON.stringify(effectiveOptions, null, 2)}`)
  // if (!Object.keys(effectiveOptions).every(el => VALID_OPTIONS.includes(el))) {
  //   throw new Error(`Invalid options passed to requestHeliumDataHelper. Must be one of ${JSON.stringify(VALID_OPTIONS)}. Received ${JSON.stringify(Object.keys(options))}`)
  // }

  // finally validate the lookup data is complete
  const { api, action: heliumAction } = lookup;
  if (!api || !heliumAction) {
    throw new Error(
      t(
        `Error, something is wrong with {{effectiveKey}} entry in heliumDictionary! Data is missing!`,
        { effectiveKey },
      ),
    );
  } // @TODO improve this error, triggered by missing constant in dictionary

  const {
    filter,
    subFilterId = effectiveName,
    q,
    suppressErrorMessage = false,
    defaultQueryParams = {},
    loopToGetAll = false,
  } = effectiveOptions;

  const queryStringParams = { limit: 1000, ...defaultQueryParams };

  // @HACK TEMP until helium is updated
  if (effectiveKey === 'opvideoDeviceListClips') {
    delete queryStringParams.limit;
  }

  // setup options (can we clean this up?)
  if (filter) {
    queryStringParams.filter = filter;
  }
  if (q) {
    queryStringParams.q = `${encodeURIComponent(q)}`;
  }

  const _requisites = [...effectiveRequisites];
  // make the call
  const { errorMessage } = yield call(requestAndSet, api, _requisites, {
    loopToGetAll,
    queryStringParams,
    suppressErrorMessage,
    createSetterAction: ({ data }) =>
      setOrgContainerReducer(heliumAction, page, data, subFilterId),
  });

  if (errorMessage && effectiveErrorHandler) {
    effectiveErrorHandler(errorMessage);
  }
}

// used in useHeliumData() hook on the Dashboard
function* requestHeliumDataGeneric(action) {
  const { key, page } = action;
  yield call(_requestHeliumDataHelper, key, page);
}

function* channeltivityLogin() {
  const response = yield call(requestAndSet, 'getChanneltivityLogin');

  try {
    const f = document.createElement('form');
    f.setAttribute('method', 'post');
    f.setAttribute('action', response.data.acsUrl);
    f.setAttribute('target', '_blank');

    const i1 = document.createElement('input');
    i1.setAttribute('type', 'hidden');
    i1.setAttribute('name', 'SAMLResponse');
    i1.setAttribute('value', response.data.samlResponse);

    const i2 = document.createElement('input');
    i2.setAttribute('type', 'hidden');
    i2.setAttribute('name', 'RelayState');
    i2.setAttribute('value', response.data.relayState);

    f.appendChild(i1);
    f.appendChild(i2);

    document.getElementsByTagName('body')[0].appendChild(f);
    f.submit();
    // NOTE: this timeout probably isn't necessary but I wanted
    // to err on the side of caution
    setTimeout(() => f.parentNode.removeChild(f), 100);
  } catch (err) {
    console.error(err);
    yield put(setAlert('error', t(`Partner portal login failed.`)));
  }
}

// This is here rather than with the OpVideoPlayers becuase when a route was changed
// and the new route didn't have the OpvideoPlayers saga, the saga to reset the HLS
// URL would not run. When it is at the org level it will always run
function* resetHlsUrl({ page, hlsSetterActionType, subFilter }) {
  yield put(setOrgContainerReducer(hlsSetterActionType, page, {}, subFilter));
}

function* resetPosterUrl({ page, subFilter }) {
  yield put(
    setOrgContainerReducer(SET_CAMERA_STALE_SNAPSHOT, page, {}, subFilter),
  );
}

export function* requestAndSetGeneralWrapper({
  heliumApi,
  queryStringParams = {},
  setterActionType,
  additionalEndpointRequisites = [],
  page,
}) {
  if (!heliumApi) {
    throw new Error(
      t(
        `requestAndSetGeneralWrapper:: must pass heliumApi (received {{heliumApi}})`,
        { heliumApi },
      ),
    );
  }

  if (!page) {
    throw new Error(
      t(`requestAndSetGeneralWrapper:: must pass page (received {{page}})`, {
        page,
      }),
    );
  }

  const orgId = yield select(selectCurrentOrgId());

  const results = yield call(
    requestAndSet,
    heliumApi,
    [orgId, ...additionalEndpointRequisites],
    {
      queryStringParams: { limit: 999, ...queryStringParams }, // default limit of 999 unless passed
      ...(setterActionType && {
        createSetterAction: ({ data }) =>
          setOrgContainerReducer(setterActionType, page, data),
      }),
    },
  );

  return results;
}

// NEW - TESTING
// this saga will always be running and does the boilerplate for after creating/editing something in a slideout
// After changing the data we need to
// - get the stored table state (should be stored when slideout is opened!)
// - update the table (so the changed data is injected)
// - clear the saved table state for next time
// - close the slide out
// - alert the user
// TODO - error if we didn't get saved table state data here
function* successfulSlideOut({ data }) {
  const {
    page,
    table,
    endpointName,
    setterActionType,
    apiEndpointQueryStringParams,
    message,
    dataFilter,
    options = {},
  } = data;
  const tableState = yield select(
    selectOrgContainerReducerItem(TABLE_STATE_REDUCER, null, null, true)(),
    { route: { name: page } },
  );

  const orgId = yield select(selectCurrentOrgId());

  yield put(
    requestUpdateTable({
      tableId: table,
      page,
      state: tableState,
      endpointName,
      additionEndpointRequisites: [orgId],
      endpointQueryStringParams: apiEndpointQueryStringParams, // TODO - Curt seems to have named this differently in different places. Fix?
      setterActionType,
      dataFilter,
    }),
  );

  // alert the user
  if (message) yield put(setAlert('success', message));

  // clear the old table state
  yield put(saveTableState(null));

  if (options.replaceSlideOut) {
    // DO NOTHING FOR NOW BECAUSE
    // replaceSlideOut doesn't work but we'll open it below
    // yield put(replaceSlideOut(options.replaceSlideOut))
  }

  if (!options.keepSlideOutOpen) {
    // Close the slide out
    yield put(deleteSlideOut());
  }

  // @HACK
  if (options.replaceSlideOut) {
    yield delay(500);
    yield put(addSlideOut(options.replaceSlideOut));
  }
}

function* requestAndUpdateOptions({
  endpointName,
  setterActionType,
  page,
  filterQuery,
}) {
  const orgId = yield select(selectCurrentOrgId());

  return yield call(requestAndSet, endpointName, [orgId], {
    queryStringParams: filterQuery
      ? { limit: 100, q: filterQuery }
      : { limit: 50 },
    createSetterAction: ({ data }) =>
      setOrgContainerReducer(setterActionType, page, data),
  });
}

export function* getAllPackagePlans({
  orgId,
  additionalQueryParams = {},
  forceAll = false,
}) {
  const currencies = currencyOptions().map((option) => option.value);

  let packagePlans = [];
  for (let i = 0; i < currencies.length; i++) {
    const res = yield call(requestAndSet, 'netsuitePlans', [orgId], {
      queryStringParams: {
        filter: `currencyCode:(${currencies[i]})`,
        limit: 1000,
        ...additionalQueryParams,
      },
    });

    const { data, statusCode } = res;
    if (statusCode !== 200) {
      yield put(
        setAlert(
          'error',
          t(`There was an error retrieving the package plans.`),
        ),
      );
      return;
    }
    packagePlans = [...packagePlans, ...data];

    if (!forceAll && data.find((pp) => pp.isSelected)) break;
  }
  return packagePlans;
}

function* rootSaga() {
  yield all([
    fork(watchRootContainerMounted),
    takeEvery(REQUEST_GET_CURRENT_ORG_DATA, requestGetCurrentOrgDataSaga),
    takeEvery(REQUEST_PAGE_DATA_GENERIC, requestPageDataGeneric),
    takeEvery(REQUEST_HELIUM_DATA_GENERIC, requestHeliumDataGeneric),
    takeEvery(REQUEST_CHANNELTIVITY_LOGIN, channeltivityLogin),
    takeEvery(RESET_HLS_URL, resetHlsUrl),
    takeEvery(RESET_POSTER_URL, resetPosterUrl),
    takeEvery(REQUEST_AND_SET_GENERAL_WRAPPER, requestAndSetGeneralWrapper),
    takeEvery(SLIDE_OUT_SUCCESSFUL, successfulSlideOut),
    takeLatest(UPDATE_OPTIONS, requestAndUpdateOptions),
  ]);
}

export default rootSaga;
